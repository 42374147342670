/*eslint-disable no-useless-escape*/

import {
  Box,
  Button,
  Circle,
  Grid,
  GridItem,
  HStack,
  Icon,
  InputLeftElement,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  CheckIcon,
  HomeIcon,
  KeyIcon,
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {
  FormInputControl,
  FormPasswordControl,
  FormSingleCheckbox,
} from '../../../components/forms';
import { REGEX_PHONE } from '../../../constants';
import { useTheme } from '../../../providers/ThemeProvider';
import { IPropsUserSignupSubmit } from '../components/AuthSignUp';

interface IFormProps {
  organizationId: string;
  organizationName: string;
  firstName: string;
  lastName: string;
  zip: string;
  phone: string;
  email: string;
  password: string;
  agreeTerms: boolean;
  agreePolicy: boolean;
}

interface IProps {
  organizationId: string | null;
  handleFormSubmit: (val: IPropsUserSignupSubmit) => Promise<void>;
}

const AuthSignUpForm = ({ handleFormSubmit, organizationId }: IProps) => {
  const theme = useTheme();

  const AuthSignUpFormSchema = Yup.object().shape({
    firstName: Yup.string().max(100).required('Required'),
    lastName: Yup.string().max(100).required('Required'),
    organizationId: Yup.string().nullable(),
    organizationName: Yup.string()
      .nullable()
      .max(100)
      .when('organizationId', {
        is: val => !val,
        then: Yup.string().required('What is your organization name?'),
      }),
    zip: Yup.string()
      .min(5, '5 digits please')
      .max(5, '5 digit zip please')
      .required('Required'),
    phone: Yup.string()
      .matches(REGEX_PHONE, 'Use 10 digit phone')
      .required('Required'),
    email: Yup.string().email('Invalid Email').required('Required'),
    password: Yup.string()
      .min(8, 'Please use at least 8 characters.')
      .required('Required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^\$\*\.\[\]\{\}\(\)\?"!@#%&,\>\<':;|_~])[A-Za-z\d\^\$\*\.\[\]\{\}\(\)\?"!@#%&,\>\<':;|_~]{8,}$/,
        'Password is missing some elements to make it strong.'
      ),
    agreeTerms: Yup.bool().oneOf(
      [true],
      'You need to accept our Terms of Service to create a CurrentClient account'
    ),
    agreePolicy: Yup.bool().oneOf(
      [true],
      'You need to accept our Text Messaging Policy to create a CurrentClient account'
    ),
  });

  const handleAuthSignUpFormSubmit = async (
    values,
    actions: FormikHelpers<IFormProps>
  ) => {
    console.log('values', values);
    try {
      // Get the values from the form
      const {
        organizationId,
        organizationName,
        firstName,
        lastName,
        phone,
        zip,
        password,
        email,
      } = values;

      // Pass up logic
      const username = email;

      await handleFormSubmit({
        username,
        firstName,
        lastName,
        password,
        organizationId,
        organizationName,
        phone,
        zip,
      });
    } catch (error: any) {
      console.log('Error creating account:', error);

      if (error.key) {
        actions.setFieldError(error.key, error.status);
      } else {
        actions.setStatus(error.status);
      }
    }

    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        organizationId: organizationId || '',
        organizationName: '',
        firstName: '',
        lastName: '',
        zip: '',
        phone: '',
        email: '',
        password: '',
        agreeTerms: false,
        agreePolicy: false,
      }}
      validationSchema={AuthSignUpFormSchema}
      onSubmit={(values, actions) => {
        handleAuthSignUpFormSubmit(values, actions);
      }}
    >
      {({ isSubmitting, errors, status, values }) => {
        const isPasswordUppercase = /[A-Z]/.test(values.password);
        const isPasswordLowercase = /[a-z]/.test(values.password);
        const isPasswordNumber = /\d/.test(values.password);
        const isPasswordSymbol =
          /[\^\$\*\.\[\]\{\}\(\)\?"!@#%&,\>\<':;|_~]/.test(values.password);

        return (
          <Form>
            <Stack spacing={6}>
              <Grid templateColumns="repeat(6, 1fr)" gap={{ base: 4, lg: 6 }}>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <FormInputControl
                    name="firstName"
                    label="First Name"
                    autoComplete="given-name"
                  />
                </GridItem>
                <GridItem colSpan={{ base: 6, lg: 3 }}>
                  <FormInputControl
                    name="lastName"
                    label="Last Name"
                    autoComplete="family-name"
                  />
                </GridItem>
                <GridItem colSpan={{ base: 5, lg: 3 }}>
                  <FormInputControl
                    name="phone"
                    label="Phone"
                    inputLeftElement={
                      <InputLeftElement
                        pointerEvents="none"
                        children={<Icon color="gray.400" as={PhoneIcon} />}
                      />
                    }
                  ></FormInputControl>
                </GridItem>
                <GridItem colSpan={3}>
                  <FormInputControl
                    name="zip"
                    label="Zip"
                    autoComplete="zip"
                    inputLeftElement={
                      <InputLeftElement
                        pointerEvents="none"
                        children={
                          <Icon color="gray.400" as={LocationMarkerIcon} />
                        }
                      />
                    }
                  />
                </GridItem>

                {!values.organizationId && (
                  <GridItem colSpan={6}>
                    <FormInputControl
                      name="organizationName"
                      label="Company Name"
                      autoComplete="organization"
                      inputLeftElement={
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Icon color="gray.400" as={HomeIcon} />}
                        />
                      }
                    />
                  </GridItem>
                )}

                <GridItem colSpan={6}>
                  <FormInputControl
                    name="email"
                    label="Email"
                    type="email"
                    inputMode="email"
                    autoComplete="email"
                    inputLeftElement={
                      <InputLeftElement
                        pointerEvents="none"
                        children={<Icon color="gray.400" as={MailIcon} />}
                      />
                    }
                  />
                </GridItem>
                <GridItem colSpan={6}>
                  <FormPasswordControl
                    name="password"
                    label="Password"
                    inputMode="text"
                    autoComplete="new-password"
                    inputLeftElement={
                      <InputLeftElement
                        pointerEvents="none"
                        children={<Icon color="gray.400" as={KeyIcon} />}
                      />
                    }
                  />
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>
                    <Stack fontSize="sm" color="gray.400">
                      <Text>
                        Lets make sure we have a good password, please include:
                      </Text>

                      <Stack spacing={1}>
                        <HStack>
                          <Circle
                            bg={
                              isPasswordUppercase ? 'green.500' : theme.bg.sec
                            }
                            color={isPasswordUppercase ? 'white' : 'gray.300'}
                            p={0.5}
                          >
                            {isPasswordUppercase ? (
                              <Icon as={CheckIcon}></Icon>
                            ) : (
                              <Icon as={XIcon}></Icon>
                            )}
                          </Circle>
                          <Text>1 Uppercase</Text>
                        </HStack>
                        <HStack>
                          <Circle
                            bg={
                              isPasswordLowercase ? 'green.500' : theme.bg.sec
                            }
                            color={isPasswordLowercase ? 'white' : 'gray.300'}
                            p={0.5}
                          >
                            {isPasswordLowercase ? (
                              <Icon as={CheckIcon}></Icon>
                            ) : (
                              <Icon as={XIcon}></Icon>
                            )}
                          </Circle>
                          <Text>1 Lowercase</Text>
                        </HStack>
                        <HStack>
                          <Circle
                            bg={isPasswordSymbol ? 'green.500' : theme.bg.sec}
                            color={isPasswordSymbol ? 'white' : 'gray.300'}
                            p={0.5}
                          >
                            {isPasswordSymbol ? (
                              <Icon as={CheckIcon}></Icon>
                            ) : (
                              <Icon as={XIcon}></Icon>
                            )}
                          </Circle>
                          <Text>1 Symbol</Text>
                        </HStack>
                        <HStack>
                          <Circle
                            bg={isPasswordNumber ? 'green.500' : theme.bg.sec}
                            color={isPasswordNumber ? 'white' : 'gray.300'}
                            p={0.5}
                          >
                            {isPasswordNumber ? (
                              <Icon as={CheckIcon}></Icon>
                            ) : (
                              <Icon as={XIcon}></Icon>
                            )}
                          </Circle>
                          <Text>1 Number</Text>
                        </HStack>
                      </Stack>
                    </Stack>
                  </Box>
                </GridItem>
              </Grid>

              <Stack>
                <FormSingleCheckbox name="agreeTerms">
                  I agree to CurrentClient's&nbsp;
                  <Link
                    textDecoration="underline"
                    href="https://www.currentclient.com/terms-of-use"
                    isExternal
                  >
                    Terms of Use
                  </Link>
                </FormSingleCheckbox>

                <FormSingleCheckbox name="agreePolicy">
                  I agree to CurrentClient's&nbsp;
                  <Link
                    textDecoration="underline"
                    href="https://www.currentclient.com/privacy-policy"
                    isExternal
                  >
                    Privacy Policy
                  </Link>
                </FormSingleCheckbox>
              </Stack>

              <Button
                colorScheme={'primary'}
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                isFullWidth
              >
                Create Account
              </Button>

              {/* Display error */}
              {errors && status ? (
                <Text
                  textStyle="indicator"
                  mt={2}
                  color={theme.color.danger}
                  id="password-error"
                >
                  {status}
                </Text>
              ) : null}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AuthSignUpForm;
