import { Flex, FlexProps } from '@chakra-ui/react';
import * as React from 'react';
import { useTheme } from '../providers/ThemeProvider';

export const PageSidebar = (props: FlexProps) => {
  const { children, ...rest } = props;

  const theme = useTheme();

  return (
    <Flex
      as="aside"
      h="full"
      bg={theme.bg.base}
      direction="column"
      width="64"
      minW={64}
      px={2}
      {...rest}
    >
      <Flex
        direction="column"
        flex="1"
        py={theme.py.container}
        overflowY="auto"
        px="4"
      >
        {children}
      </Flex>
    </Flex>
  );
};
