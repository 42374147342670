import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { IOrganizationPublic } from '../../../../routes/organization/models/Organization';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (
  organizationId: string
): Promise<IOrganizationPublic> => {
  const qParams = {};

  return API.get(
    'usersPublic',
    `/api/v1/public/organizations/${organizationId}`,
    qParams
  );
};

export const useReadPublicOrganization = (organizationId: string | null) => {
  return useQuery<IOrganizationPublic>(
    [QueryKeys.organizations, organizationId],
    () => makeApiRequest(organizationId as string),
    {
      retry: 2,
      enabled: !!organizationId,
    }
  );
};
