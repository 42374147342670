import { Badge, Box, Flex, Heading, Image } from '@chakra-ui/react';
import React from 'react';
import { Logo } from '../components/shared/Logo';
import { useTheme } from '../providers/ThemeProvider';

// Auth Layout
// Handles the top level view of the auth routes

const AuthLayout = ({ children }) => {
  const theme = useTheme();

  return (
    <Flex
      h="100vh"
      bg={theme.bg.base}
      color={theme.color.base}
      overflowY={{ base: 'scroll', lg: 'inherit' }}
      direction={{ base: 'column', lg: 'row-reverse' }}
    >
      {/* Image */}
      <Box
        flex={{ base: '1', xl: '2' }}
        bg={theme.bg.base}
        position="relative"
        maxH={{ base: '100px', lg: 'inherit' }}
      >
        <Flex
          direction="column"
          align="center"
          justify="center"
          h="full"
          w="full"
        >
          <Image
            inset={0}
            fit={'cover'}
            w="full"
            h="full"
            src="https://source.unsplash.com/collection/70053195"
            alt=""
          />
        </Flex>
      </Box>
      <Box
        flex={1}
        overflowY={{ lg: 'scroll' }}
        px={{ base: '16', lg: '10', xl: '28' }}
        py={{ base: '8', lg: '8' }}
      >
        <Box maxW="xl">
          <Box>
            <Box mb="12" h="8">
              <Logo />
            </Box>
            <Badge fontSize="lg" mb="2" colorScheme={'indigo'}>
              Governance Portal
            </Badge>
            <Heading color={theme.color.base} as="h1" size="xl">
              CurrentClient
            </Heading>
          </Box>

          <Box maxW={{ lg: '420px' }} pt={{ base: '8', lg: '16' }} pb="6">
            {children}
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default AuthLayout;
