import { Button, ButtonGroup, Icon, IconButton } from '@chakra-ui/react';
import { TrashIcon } from '@heroicons/react/outline';
import React from 'react';

interface IProps {
  onViewRoutingRule: () => void;
  onDeleteRoutingRule: () => void;
}

export const CellRoutingRuleAction = ({
  onViewRoutingRule,
  onDeleteRoutingRule,
}: IProps) => {
  return (
    <ButtonGroup spacing="4" size="sm" alignItems={'center'} h="full">
      <Button onClick={onViewRoutingRule}>View</Button>
      <IconButton
        aria-label="delete routingrule"
        onClick={onDeleteRoutingRule}
        icon={<Icon as={TrashIcon} />}
      ></IconButton>
    </ButtonGroup>
  );
};
