import { Image } from '@chakra-ui/react';
import * as React from 'react';
import ccv2_dark from '../../assets/ccv2_dark.png';
// import CurrentClientLogo from '../assets/CurrentClientLogo.png';
// import CurrentClientLogoWhite from '../assets/CurrentClientLogoWhite.png';
// import CCLogoW from '../../assets/CCLogoW.png';
// import CCLogoB from '../../assets/CCLogoB.png';
// import CurrentClientLogoBlue from '../../assets/CurrentClientLogoBlue.png';
import ccv2_light from '../../assets/ccv2_light.png';
// import CurrentClientLogoMulti from '../../assets/CurrentClientLogoMulti.png';
// import CurrentClientLogoPurple from '../../assets/CurrentClientLogoPurple.png';
// import CurrentClientLogoHue from '../../assets/CurrentClientLogoHue.png';
// import CurrentClientLogoRainbow from '../../assets/CurrentClientLogoRainbow.png';
import { useTheme } from '../../providers/ThemeProvider';

export const Logo = () => {
  const theme = useTheme();

  const logoPng = theme.useColorModeValue(ccv2_light, ccv2_dark);

  return <Image h="full" src={logoPng} alt="CC" />;
};
