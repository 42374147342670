import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { IGovernorSignup } from '../../../../models/GovernorProfile';

const makeApiRequest = (
  userSignup: IGovernorSignup
): Promise<IGovernorSignup> => {
  const postData = {
    body: {
      ...userSignup,
    },
  };

  return API.post('usersPublic', '/api/v1/public/governors/signup', postData);
};

const useCreateSignup = () => {
  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation<IGovernorSignup, any, any, unknown>(
    userSignup => makeApiRequest(userSignup),
    {
      // useErrorBoundary: true,
      retry: 2,
    }
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

export default useCreateSignup;
