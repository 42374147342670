import { Box, useToast } from '@chakra-ui/react';
import React from 'react';
import { IGovernorProfileUpdate } from '../../../models/GovernorProfile';
import useUpdateGovernorProfile from '../../../services/api/governorProfiles/mutations/useUpdateGovernorProfile';
import useReadGovernorProfile from '../../../services/api/governorProfiles/queries/useReadGovernorProfile';
import { GovernorProfileSettingsForm } from '../forms/governorProfile/GovernorProfileSettingsForm';

export const GovernorProfileSettings = () => {
  const toast = useToast();
  const toastIdRef = React.useRef<any>();

  const { data: dataGovernorProfile, isLoading: isLoadingGovernorProfile } =
    useReadGovernorProfile();

  const {
    mutateAsync: mutateAsyncGovernorProfile,
    isLoading: isLoadingMutateGovernorProfile,
  } = useUpdateGovernorProfile();

  const handleOnSubmit = async (values: IGovernorProfileUpdate) => {
    if (!isLoadingMutateGovernorProfile) {
      toastIdRef.current = toast({
        title: 'Saving...',
        description: 'Should be quick to get this update in.',
        status: 'info',
        variant: 'subtle',
        isClosable: true,
        duration: null,
      });

      try {
        await mutateAsyncGovernorProfile({ updateData: values });

        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }

        toast({
          title: 'Saved',
          description: 'Update is good and ready.',
          status: 'success',
          duration: 2000,
          variant: 'subtle',
          isClosable: true,
        });
      } catch (e) {
        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }

        throw e;
      }
    }
  };

  return (
    <Box>
      <GovernorProfileSettingsForm
        governorProfile={dataGovernorProfile}
        onSubmit={handleOnSubmit}
      ></GovernorProfileSettingsForm>
    </Box>
  );
};
