import {
  FormHelperText,
  InputGroup,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { Field } from 'formik';
import React, { ReactNode } from 'react';
import { BaseProps } from '../baseProps';
import { FormControl } from '../FormControl/FormControl';

export type TextareaControlProps = BaseProps &
  TextareaProps & {
    helperTextElement?: ReactNode;
    isIncludeDescription?: boolean;
    description?: string;
    inputRef?: any;
  };

export const TextareaControl = (props: TextareaControlProps) => {
  const {
    label,
    isIncludeDescription,
    description,
    formControlProps,
    inputRef,
    helperTextElement,
    ...inputProps
  } = props;

  const { isRequired, error, touched, ...formControlPropsRest } =
    formControlProps || {};

  return (
    <FormControl
      isIncludeDescription={isIncludeDescription}
      description={description}
      label={label}
      isRequired={isRequired}
      error={error}
      touched={touched}
      {...formControlPropsRest}
    >
      <InputGroup>
        <Textarea
          {...inputProps}
          ref={inputRef}
          onFocus={event => event.target.select()}
        />
      </InputGroup>

      {helperTextElement && (
        <FormHelperText>{helperTextElement}</FormHelperText>
      )}
    </FormControl>
  );
};

export type FormTextareaControlProps = TextareaControlProps & {
  name: string;
};

export const FormTextareaControl = (props: FormTextareaControlProps) => {
  const { name, formControlProps, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <TextareaControl
          {...rest}
          formControlProps={{
            ...meta,
            ...formControlProps,
          }}
          {...field}
        />
      )}
    </Field>
  );
};
