import { IOrganization } from '../../organization/models/Organization';

export enum MessageTypeEnum {
  MULTI_SELECT = 'MULTI_SELECT',
  TEXT_LONG = 'TEXT_LONG',
  TEXT_SHORT = 'TEXT_SHORT',
}

export interface ITemplateMessageGovernance {
  IsApproved?: boolean;
  OrganizationProfile?: IOrganization;
}

export interface IMessageUpdate {
  MessageTitle: string;
  MessageType: MessageTypeEnum;
  MessageBody: string;
  IsIncludeMessageBody: boolean;
  IsActive: boolean;
}

export interface IMessage extends IMessageUpdate {
  Version?: number;
  TemplateMessageId: string;
  OrganizationId: string;
  CreatedByUserId: string;
  UpdatedAt?: string;
  CreatedTime: string;
  MessageId: string;
  Governance?: { [organizationId: string]: ITemplateMessageGovernance };
  IsProcessing?: boolean;
  IsDeleting?: boolean;
  IsLinked?: boolean;
  IsManaged?: boolean;
}

// In the form, we change it to allow mode
export interface IMessageWorkingForm extends Partial<IMessageUpdate> {}

export const initialMessageForm: IMessageWorkingForm = {
  MessageTitle: '',
  MessageType: MessageTypeEnum.TEXT_SHORT,
  MessageBody: '',
  // IsActive: false,
  IsIncludeMessageBody: false,
};

export interface ITemplateMessageApprovalWorkingForm {
  IsApproved: boolean;
}

export interface ITemplateMessageLinkWorkingForm {
  LinkOrganizationId: string;
}
