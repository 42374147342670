import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import {
  IMessage,
  IMessageUpdate,
} from '../../../../routes/guardrails/models/Message';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (messageUpdate: IMessageUpdate) => {
  const postData = {
    body: messageUpdate,
  };

  return API.post('users', '/api/v1/templatemessages/', postData);
};

const useCreateTemplateMessage = () => {
  const queryClient = useQueryClient();

  return useMutation<IMessage, any, IMessageUpdate>(
    dropsForm => makeApiRequest(dropsForm),
    {
      // useErrorBoundary: true,
      retry: 0,
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.templateMessages], {
          exact: false,
        });
      },
    }
  );
};

export default useCreateTemplateMessage;
