import {
  Badge,
  Box,
  HStack,
  Icon,
  Link,
  LinkProps,
  Text,
} from '@chakra-ui/react';
import { Link as ReachLink } from '@reach/router';
import * as React from 'react';
import { useTheme } from '../providers/ThemeProvider';

interface SubNavLinkProps extends LinkProps {
  isActiveOnPartial?: boolean;
  isNewBadge?: boolean;
  isSoonBadge?: boolean;
  to: string;
  isNotification?: boolean;
  label: string;
  icon?: React.ElementType;
}

export const SubNavLink = (props: SubNavLinkProps) => {
  const {
    icon,
    isActiveOnPartial,
    label,
    isNewBadge,
    isSoonBadge,
    isNotification = false,
    to,
    ...rest
  } = props;

  const theme = useTheme();

  return (
    <Link
      display="block"
      position="relative"
      py="2"
      px="2"
      whiteSpace="nowrap"
      roundedTopRight={'sm'}
      roundedBottomRight={'sm'}
      transition="all 0.3s"
      fontWeight="medium"
      borderLeftWidth={2}
      borderLeftColor={theme.bg.base1}
      userSelect="none"
      as={ReachLink}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        const isActive = (isActiveOnPartial && isPartiallyCurrent) || isCurrent;
        return {
          'aria-current': isActive ? 'page' : undefined,
        };
      }}
      color={theme.color.base2}
      _hover={{
        bg: theme.bg.base1,
        borderColor: theme.bg.base3,
      }}
      _activeLink={{
        borderLeftColor: theme.color.accent,
        color: theme.color.accent,
      }}
      _focus={{ outline: 0 }}
      to={to}
      {...rest}
    >
      <HStack spacing="4" position="relative">
        {icon && <Icon as={icon} fontSize="lg" opacity={0.64} />}
        <HStack>
          <Text textStyle={'navLink'}>{label}</Text>
          {isNewBadge && <Badge colorScheme="green">New</Badge>}
          {isSoonBadge && <Badge colorScheme="accent">Soon</Badge>}
          {isNotification && (
            <Box
              position="absolute"
              bg="red.500"
              h={3}
              w={3}
              top={0}
              left={1}
              rounded="full"
              borderColor="white"
              borderWidth={2}
              borderStyle="solid"
            ></Box>
          )}
        </HStack>
      </HStack>
    </Link>
  );
};
