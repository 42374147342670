import { Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { IUserProfile } from '../../../../models/UserProfile';

export const CellOrgCompany = ({ row }: CellProps<IUserProfile>) => {
  return (
    <Stack direction="row" spacing="4" align="center">
      <Box>
        <Box fontSize="sm" fontWeight="medium">
          {row.original.CompanyName}
        </Box>
        <Box fontSize="sm" color="gray.500">
          {row.original.City} {row.original.State}
        </Box>
      </Box>
    </Stack>
  );
};
