export interface IGuardrailsUpdate {
  IsForbidRVM: boolean;
  IsForbidBroadcast: boolean;
  IsForbidBroadcastCustomMessage: boolean;
  IsForbidCampaign: boolean;
  IsForbidCampaignCustomDiscovery: boolean;
  IsForbidCampaignCustomMessage: boolean;
  IsEnforceMFA: boolean;
}

export interface IGuardrails extends IGuardrailsUpdate {
  Version?: number;
  OrganizationId: string;
  CreatedByUserId: string;
  UpdatedAt?: string;
  CreatedAt: string;
  GuardrailsId: string;
}

// In the form, we change it to allow mode
export interface IGuardrailsWorkingForm {
  isAllowRVM: boolean;
  isAllowBroadcast: boolean;
  isAllowBroadcastCustomMessage: boolean;
  isAllowCampaign: boolean;
  isAllowCampaignCustomDiscovery: boolean;
  isAllowCampaignCustomMessage: boolean;
  isEnforceMFA: boolean;
}

export const initialGuardrailSettingsForm: IGuardrailsWorkingForm = {
  isAllowRVM: false,
  isAllowBroadcast: false,
  isAllowBroadcastCustomMessage: false,
  isAllowCampaign: false,
  isAllowCampaignCustomDiscovery: false,
  isAllowCampaignCustomMessage: false,
  isEnforceMFA: false,
};

export const convertGuardrailsToAllowForm = (
  forbidIn: IGuardrailsUpdate
): IGuardrailsWorkingForm => {
  const formOut: IGuardrailsWorkingForm = {
    isAllowRVM: !forbidIn.IsForbidRVM,
    isAllowBroadcast: !forbidIn.IsForbidBroadcast,
    isAllowBroadcastCustomMessage: !forbidIn.IsForbidBroadcastCustomMessage,
    isAllowCampaign: !forbidIn.IsForbidCampaign,
    isAllowCampaignCustomDiscovery: !forbidIn.IsForbidCampaignCustomDiscovery,
    isAllowCampaignCustomMessage: !forbidIn.IsForbidCampaignCustomMessage,
    // No value change
    isEnforceMFA: forbidIn.IsEnforceMFA,
  };

  return formOut;
};

export const convertGuardrailsToForbidForm = (
  allowIn: IGuardrailsWorkingForm
): IGuardrailsUpdate => {
  const formOut: IGuardrailsUpdate = {
    IsForbidRVM: !allowIn.isAllowRVM,
    IsForbidBroadcast: !allowIn.isAllowBroadcast,
    IsForbidBroadcastCustomMessage: !allowIn.isAllowBroadcastCustomMessage,
    IsForbidCampaign: !allowIn.isAllowCampaign,
    IsForbidCampaignCustomDiscovery: !allowIn.isAllowCampaignCustomDiscovery,
    IsForbidCampaignCustomMessage: !allowIn.isAllowCampaignCustomMessage,
    // No value change
    IsEnforceMFA: allowIn.isEnforceMFA,
  };

  return formOut;
};
