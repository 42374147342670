import { Center, CenterProps, Image } from '@chakra-ui/react';
import React from 'react';
import { useTheme } from '../../../../providers/ThemeProvider';
import {
  IConnectorConfig,
  RoutingRuleConnectorTypeEnum,
  ROUTING_RULE_EVENT_CONNECTOR_CONFIG,
} from '../../models/RoutingRule';

interface IProps extends CenterProps {
  connectorType: RoutingRuleConnectorTypeEnum;
}

export const RoutingRuleConnectorTypeSmudge = ({
  connectorType,
  ...props
}: IProps) => {
  const [configLookup, set_configLookup] =
    React.useState<null | IConnectorConfig>(null);

  const theme = useTheme();

  React.useEffect(() => {
    if (connectorType && ROUTING_RULE_EVENT_CONNECTOR_CONFIG[connectorType]) {
      set_configLookup(ROUTING_RULE_EVENT_CONNECTOR_CONFIG[connectorType]);
    }
  }, [connectorType]);

  return (
    <Center
      h={8}
      minW={8}
      bg={configLookup?.logoUrl ? theme.bg.light : configLookup?.bgColor}
      rounded="sm"
      overflow={'hidden'}
      p={1}
      color={theme.bg.dark}
      {...props}
    >
      {configLookup?.logoUrl ? (
        <Image
          h="full"
          objectFit={'contain'}
          src={configLookup?.logoUrl}
        ></Image>
      ) : (
        configLookup?.icon
      )}
    </Center>
  );
};
