import {
  Button,
  Grid,
  GridItem,
  Icon,
  InputLeftElement,
  Stack,
} from '@chakra-ui/react';
import { KeyIcon, MailIcon } from '@heroicons/react/outline';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {
  FormInputControl,
  FormPasswordControl,
  FormPinInputControl,
} from '../../../components/forms';

interface IProps {
  username: string;
  handleFormSubmit: (
    code: string,
    username: string,
    password: string
  ) => Promise<void>;
}

const AuthResetPasswordConfirmForm = ({
  username,
  handleFormSubmit,
}: IProps) => {
  const AuthResetPasswordConfirmFormSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is required'),
    code: Yup.string()
      .matches(/^[0-9]+$/, {
        message: 'Only numbers',
        excludeEmptyString: true,
      })
      .length(6, 'Code is 6 characters')
      .required('Required'),
    password: Yup.string().min(8).required(),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });

  const handleAuthResetPasswordConfirmFormSubmit = async (
    values,
    actions: FormikHelpers<{
      email: string;
      code: string;
      password: string;
      passwordConfirmation: string;
    }>
  ) => {
    try {
      // Get the values from the form
      const code = values.code;
      const username = values.email;
      const password = values.password;

      // Pass up logic
      await handleFormSubmit(code, username, password);
    } catch (error: any) {
      console.log('Error resetting password:', error);

      if (
        (error.key && error.key === 'email') ||
        error.key === 'password' ||
        error.key === 'code'
      ) {
        actions.setFieldError(error.key, error.status);
      } else {
        actions.setStatus(error.status);
      }
    }

    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        email: username,
        code: '',
        password: '',
        passwordConfirmation: '',
      }}
      validationSchema={AuthResetPasswordConfirmFormSchema}
      onSubmit={(values, actions) => {
        handleAuthResetPasswordConfirmFormSubmit(values, actions);
      }}
    >
      {({ isSubmitting, errors, touched, status }) => (
        <Form>
          <Stack spacing={6}>
            <Grid templateColumns="repeat(6, 1fr)" gap={{ base: 4, lg: 6 }}>
              <GridItem colSpan={6}>
                <FormInputControl
                  name="email"
                  label="Email"
                  isDisabled
                  inputLeftElement={
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon color="gray.400" as={MailIcon} />}
                    />
                  }
                />
              </GridItem>

              <GridItem colSpan={6}>
                <FormPinInputControl
                  name="code"
                  label="Code"
                  pinLength={6}
                  stackProps={{ justifyContent: 'left' }}
                />
              </GridItem>
              <GridItem colSpan={6}>
                <FormPasswordControl
                  name="password"
                  label="Password"
                  inputLeftElement={
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon color="gray.400" as={KeyIcon} />}
                    />
                  }
                />
              </GridItem>
              <GridItem colSpan={6}>
                <FormPasswordControl
                  name="passwordConfirmation"
                  label="Confirmation Password"
                  inputLeftElement={
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={KeyIcon} />}
                    />
                  }
                />
              </GridItem>
            </Grid>

            <Button
              colorScheme="primary"
              variant="outline"
              isFullWidth
              isDisabled={isSubmitting}
              type="submit"
            >
              Reset Password
            </Button>

            {/* Display error */}
            {errors && status ? (
              <p className="mt-2 text-sm text-red-600" id="password-error">
                {status}
              </p>
            ) : null}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AuthResetPasswordConfirmForm;
