import { Box, Stack, Text } from '@chakra-ui/react';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';
import React from 'react';
import { MutedLink } from '../../../components/shared/MutedLink';
import { Routes } from '../../../constants';
import AuthResetPasswordForm from '../forms/AuthResetPasswordForm';

export interface ResetPasswordError {
  key?: string;
  status: string;
}

const AuthResetPassword = () => {
  const handleFormSubmit = async (username: string) => {
    try {
      await Auth.forgotPassword(username);

      navigate(Routes.AuthResetPasswordConfirmation, { state: { username } });
    } catch (error: any) {
      // Parse cognito responses
      let err: ResetPasswordError = {
        status: 'Reset failed',
      };
      if (error.code === 'LimitExceededException') {
        err = {
          status: 'Reached limit. Wait 15 minutes and try again.',
        };
      }

      throw err;
    }
  };

  return (
    <Box>
      {/* Form title */}
      <Text textStyle="headline">🤔 Reset password</Text>

      {/* Form */}
      <Stack spacing={6} mt={6}>
        {/* ResetPassword Form */}
        <AuthResetPasswordForm
          handleFormSubmit={handleFormSubmit}
        ></AuthResetPasswordForm>

        {/* Actions */}
        <Stack>
          {/* Back to login */}
          <MutedLink
            to={Routes.AuthSignIn}
            label="back to sign in"
            leftIcon={ArrowNarrowLeftIcon}
          ></MutedLink>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AuthResetPassword;
