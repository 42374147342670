import { Box, Text } from '@chakra-ui/react';
import React from 'react';

interface IProps {}

export const NoDataTableStub = ({}: IProps) => {
  return (
    <Box py={8}>
      <Text textAlign={'center'} textStyle={'strong'}>
        No records
      </Text>
    </Box>
  );
};
