import { Stack, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import useQuestionsTable from '../../hooks/useQuestionsTable';
import { OrganizationEventRecordHistoryModal } from '../OrganizationEventRecordHistoryModal';
import TemplateQuestionDialogDelete from './modals/TemplateQuestionDialogDelete';
import TemplateQuestionDialogLink from './modals/TemplateQuestionDialogLink';
import { TemplateQuestionModalEdit } from './modals/TemplateQuestionModalEdit';
import { QuestionsTableActions } from './QuestionsTableActions';
import { QuestionsTableContent } from './QuestionsTableContent';
import { QuestionsTablePagination } from './QuestionsTablePagination';

export const QuestionsTable = () => {
  const {
    isOpen: isOpenModal,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();

  const {
    isOpen: isOpenEventModal,
    onClose: onCloseEventModal,
    onOpen: onOpenEventModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteDialog,
    onOpen: onOpenDeleteDialog,
    onClose: onCloseDeleteDialog,
  } = useDisclosure();

  const {
    isOpen: isOpenLinkDialog,
    onOpen: onOpenLinkDialog,
    onClose: onCloseLinkDialog,
  } = useDisclosure();

  const table = useQuestionsTable();

  const handleOnClose = () => {
    table.setEditQuestion(null);
    onCloseModal();
  };

  const handleOnEventClose = () => {
    table.setViewHistoryQuestion(null);
    onCloseEventModal();
  };

  const handleOnCloseDelete = () => {
    table.setDeleteQuestion(null);
    onCloseDeleteDialog();
  };

  const handleOnCloseLink = () => {
    table.setLinkQuestion(null);
    onCloseLinkDialog();
  };

  React.useEffect(() => {
    if (table.editQuestion && !isOpenModal) {
      onOpenModal();
    }

    if (table.viewHistoryQuestion && !isOpenEventModal) {
      onOpenEventModal();
    }

    if (table.deleteQuestion && !isOpenDeleteDialog) {
      onOpenDeleteDialog();
    }

    if (table.linkQuestion && !isOpenLinkDialog) {
      onOpenLinkDialog();
    }
  }, [
    table,
    onOpenModal,
    onOpenEventModal,
    onOpenDeleteDialog,
    onOpenLinkDialog,
    isOpenModal,
    isOpenEventModal,
    isOpenDeleteDialog,
    isOpenLinkDialog,
  ]);

  return (
    <>
      <Stack as="section" minW={'5xl'} spacing={6}>
        <QuestionsTableActions table={table} />
        <QuestionsTableContent table={table} />
        <QuestionsTablePagination table={table} />
      </Stack>

      <TemplateQuestionModalEdit
        isOpen={isOpenModal}
        onClose={handleOnClose}
        question={table.editQuestion}
      ></TemplateQuestionModalEdit>

      <TemplateQuestionDialogDelete
        question={table.deleteQuestion}
        isOpen={isOpenDeleteDialog}
        onClose={handleOnCloseDelete}
      ></TemplateQuestionDialogDelete>

      <OrganizationEventRecordHistoryModal
        isOpen={isOpenEventModal}
        onClose={handleOnEventClose}
        eventRecordId={table.viewHistoryQuestion?.TemplateQuestionId}
      ></OrganizationEventRecordHistoryModal>

      <TemplateQuestionDialogLink
        templateQuestion={table.linkQuestion?.question}
        organizationLink={table.linkQuestion?.organizationLink}
        isOpen={isOpenLinkDialog}
        onClose={handleOnCloseLink}
      ></TemplateQuestionDialogLink>
    </>
  );
};
