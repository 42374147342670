import { Circle, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTheme } from '../../../providers/ThemeProvider';

interface StatProps {
  icon: React.ReactElement;
  accentColor: string;
  data: {
    unit: string;
    label: string;
    value?: string | number;
  };
}

export const StatCard = (props: StatProps) => {
  const { accentColor, icon, data } = props;
  const { label, value, unit } = data;

  const theme = useTheme();

  return (
    <Stack mx="auto" spacing="3">
      <Text fontWeight="medium" textStyle={'title'}>
        {label}
      </Text>
      <HStack spacing="3">
        <Circle
          flexShrink={0}
          size="12"
          bg={accentColor}
          color="white"
          fontSize={'24'}
        >
          {icon}
        </Circle>
        <Heading as="h1" size="xl" fontWeight="bold">
          {value}
        </Heading>
      </HStack>
      <Text color={theme.color.base3}>{unit}</Text>
    </Stack>
  );
};
