import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { IGovernorProfile } from '../../../../models/GovernorProfile';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (): Promise<IGovernorProfile> => {
  const qParams = {};

  return API.get('users', '/api/v1/governors', qParams);
};

export default function useReadGovernorProfile() {
  return useQuery([QueryKeys.governorProfiles], () => makeApiRequest(), {
    retry: 0,
    staleTime: 30000,
  });
}
