import { Box } from '@chakra-ui/react';
import React from 'react';
import { PageHeader } from '../../../layouts/PageHeader';
import { OrganizationEventsTable } from './organizationEvents/OrganizationEventsTable';

const OrganizationEvents = () => {
  return (
    <>
      <PageHeader
        title="Events"
        description="Actions performed in your organization goverance are captured and recorded here."
      ></PageHeader>
      <Box py={8}>
        <OrganizationEventsTable></OrganizationEventsTable>
      </Box>
    </>
  );
};

export default OrganizationEvents;
