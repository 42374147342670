import { Stack } from '@chakra-ui/react';
import { FormikProps } from 'formik';
import React from 'react';
import { FormTextareaControl } from '../../../../components/forms';
import { PropertyStack } from '../../../../components/forms/PropertyStack';
import { IEmailWorkingForm } from '../../../../models/EmailInvite';

interface IProps {
  form: FormikProps<IEmailWorkingForm>;
}

export const EmailFormGroup = ({ form }: IProps) => {
  return (
    <>
      <Stack spacing={4}>
        <Stack spacing="6">
          <PropertyStack
            label="Emails"
            description="Add all your emails here separated by commas"
          >
            <FormTextareaControl
              name="emails"
              minH="16rem"
              isDisabled={form.isSubmitting}
              formControlProps={{
                w: '32rem',
              }}
            />
          </PropertyStack>

          <PropertyStack
            label="Custom note"
            description="We will explain what the invite is for, but do you want to say anything special?"
          >
            <FormTextareaControl
              name="note"
              isDisabled={form.isSubmitting}
              formControlProps={{
                w: '32rem',
              }}
            />
          </PropertyStack>
        </Stack>
      </Stack>
    </>
  );
};
