import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import MultiSelectPNG from '../../../../assets/qtype_multi_select.png';

interface IProps {}

export const RoutingRuleTypeMultiSelectSmudge = ({}: IProps) => {
  return (
    <Box h={8} bg={'cyan.200'} rounded="sm" overflow={'hidden'} p={1}>
      <Image h="full" objectFit={'contain'} src={MultiSelectPNG}></Image>
    </Box>
  );
};
