import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import ShortTextPNG from '../../../../assets/qtype_text_short.png';

interface IProps {}

export const RoutingRuleTypeTextShortSmudge = ({}: IProps) => {
  return (
    <Box h={8} bg={'fuchsia.200'} rounded="sm" overflow={'hidden'} p={1}>
      <Image h="full" objectFit={'contain'} src={ShortTextPNG}></Image>
    </Box>
  );
};
