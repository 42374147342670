import { Stack } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import TextMessageBubble from '../../../../../components/shared/TextMessageBubble';
import { IMessage } from '../../../models/Message';

export const CellMessageBody = ({ row }: CellProps<IMessage>) => {
  return (
    <Stack spacing={0}>
      <TextMessageBubble
        p={4}
        whiteSpace={'pre-wrap'}
        textProps={{
          noOfLines: 4,
        }}
        message={row.original.MessageBody}
      ></TextMessageBubble>
    </Stack>
  );
};
