import { Box, Stack, StackProps, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTheme } from '../../providers/ThemeProvider';

interface FieldGroupProps extends StackProps {
  title?: string;
  description?: string;
}

export const FieldGroup = (props: FieldGroupProps) => {
  const { title, description, children, ...flexProps } = props;
  const theme = useTheme();
  return (
    <Stack
      spacing="6"
      borderWidth={'1px'}
      overflow="hidden"
      rounded="sm"
      {...flexProps}
    >
      {/* header */}
      <Stack py={6} px={4} bg={theme.bg.base1} borderBottomWidth={'1px'}>
        {title && <Text textStyle="title">{title}</Text>}
        {description && (
          <Text textStyle="description" color={theme.color.base3}>
            {description}
          </Text>
        )}
      </Stack>
      <Box pb={6} px={8}>
        {children}
      </Box>
    </Stack>
  );
};
