import { Icon, Stack, Text } from '@chakra-ui/react';
import { FlagIcon } from '@heroicons/react/outline';
import React from 'react';
import { CellProps } from 'react-table';
import { SingleSwitch } from '../../../../components/forms';
import { LibFullWidthSpinner } from '../../../../components/lib';
import { IUserProfile } from '../../../../models/UserProfile';
import { useAuth } from '../../../../providers/AuthProvider';
import { useTheme } from '../../../../providers/ThemeProvider';
import useUpdateOrganizationUserApproval from '../../../../services/api/organization/mutations/useUpdateOrganizationUserApproval';
import { IOrganizationUserApprovalWorkingForm } from '../../models/Organization';

export const CellOrgApprove = ({ row }: CellProps<IUserProfile>) => {
  const { userProfile } = useAuth();

  const [valStatus, set_valStatus] = React.useState<boolean>(false);

  const { mutateAsync, isLoading, isError } =
    useUpdateOrganizationUserApproval();

  const theme = useTheme();

  const handleOnChange = async evt => {
    const valStatusUpdate = evt.target.checked;

    const updateData: IOrganizationUserApprovalWorkingForm = {
      IsApproved: valStatusUpdate,
    };

    try {
      await mutateAsync({
        userId: row.original.UserId,
        updateData,
      });

      set_valStatus(valStatusUpdate);
    } catch (err) {}
  };

  React.useEffect(() => {
    if (userProfile.OrganizationId && row?.original.Governance) {
      const goveranceObj = row.original.Governance[userProfile.OrganizationId];
      set_valStatus(goveranceObj.IsApproved || false);
    }
  }, [userProfile, row]);

  return (
    <Stack direction="row" spacing="4" alignItems={'center'} h="full">
      <SingleSwitch
        name={`${row.original.UserId}-IsApproved`}
        onChange={handleOnChange}
        isDisabled={isLoading}
        isChecked={valStatus}
      >
        {isError && <Icon color={theme.color.danger} as={FlagIcon} />}
        {isLoading ? (
          <LibFullWidthSpinner h={4} w={4} thickness={'2px'} />
        ) : (
          <Text textStyle="switchLabel">Approved</Text>
        )}
      </SingleSwitch>
    </Stack>
  );
};
