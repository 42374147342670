import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { useTheme } from '../../../../../providers/ThemeProvider';
import {
  IRoutingRule,
  ROUTING_RULE_EVENT_CONNECTOR_CONFIG,
} from '../../../models/RoutingRule';
import { RoutingRuleConnectorTypeSmudge } from '../RoutingRuleConnectorTypeSmudge';

export const CellRoutingRuleConnector = ({ row }: CellProps<IRoutingRule>) => {
  const theme = useTheme();

  return (
    <HStack
      spacing="2"
      // borderColor={theme.bg.navBorder}
      borderColor={
        ROUTING_RULE_EVENT_CONNECTOR_CONFIG[row.original.ConnectorType].bgColor
      }
      bg={theme.bg.base1}
      borderWidth={1}
      rounded="sm"
      h="full"
      overflow={'hidden'}
    >
      <RoutingRuleConnectorTypeSmudge
        connectorType={row.original.ConnectorType}
        rounded="0"
        h="full"
      />

      <Text
        pr={2}
        textStyle={'description'}
        whiteSpace={'normal'}
        wordBreak={'normal'}
      >
        {ROUTING_RULE_EVENT_CONNECTOR_CONFIG[row.original.ConnectorType].label}
      </Text>
    </HStack>
  );
};
