/*
TextMessagePeview

Component provides preview of the text message, wrapped inside a phone simulation
*/
import {
  Avatar as ChakraAvatar,
  Box,
  Flex,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useTheme } from '../../providers/ThemeProvider';
import TextMessageBubble from './TextMessageBubble';

const TextMessagePeview = ({
  textBody,
  fromName,
  fromAvatarUrl,
}: {
  textBody: string;
  fromName: string;
  fromAvatarUrl: string;
}) => {
  const theme = useTheme();
  const defaultPreview = ['Preview of a text message...'];
  const [phoneTime, set_phoneTime] = React.useState('4:23 AM');
  const [messages, set_messages] = React.useState(defaultPreview);

  const makeMessages = str => {
    // Convert string to array of 160 length strings
    return Array.from({ length: Math.ceil(str.length / 160) }, (_, index) =>
      str.slice(index * 160, (index + 1) * 160)
    );
  };

  React.useEffect(() => {
    let splitBody = defaultPreview;

    if (textBody) {
      // Split the body to 160 characters
      splitBody = makeMessages(textBody);
    }

    set_messages(splitBody);
  }, [textBody]);

  React.useEffect(() => {
    const setTheTime = () => {
      // 9:45:09 PM
      let fullTime = new Date().toLocaleTimeString().padStart(11, '0');

      const newTime = fullTime.substr(0, 5);
      const newMeridian = fullTime.substr(-2, 2);

      set_phoneTime(`${newTime} ${newMeridian}`);
    };

    setTheTime();

    const interval = setInterval(setTheTime, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box w="full">
      {/* Phone */}
      <Box
        borderRadius={'2.5rem'}
        w="21rem"
        px={4}
        py={2}
        overflow={'hidden'}
        mx="auto"
        bg={'black'}
      >
        {/* Top w/ speaker */}
        <Flex height="4.5rem" items="center" justify={'center'} pb={6}>
          <Box className="w-16 h-1 bg-gray-800 rounded speaker"></Box>
        </Flex>
        {/* Screen */}
        <Box bg={theme.useColorModeValue('white', 'gray.900')}>
          <Box
            position={'relative'}
            px={2}
            pt="-0.5"
            pb="-1.5"
            bg="red.100"
            borderBottomWidth="1px"
            bgGradient={theme.useColorModeValue(
              'linear(to-b, gray.100, blue.100)',
              'linear(to-br, gray.800, indigo.900)'
            )}
          >
            <VStack spacing={0} py={0.5}>
              <Text
                fontSize="0.65rem"
                fontWeight={'bold'}
                textTransform={'uppercase'}
                mb={1}
              >
                {phoneTime}
              </Text>
              <Box position="relative" mb={-0.5} w="full" textAlign="center">
                <ChakraAvatar
                  bg="gray.200"
                  src={fromAvatarUrl}
                  name={fromName}
                  size="md"
                ></ChakraAvatar>
                <Box pos="absolute" color="blue.500" left={0} top={3}>
                  <FiChevronLeft size="32"></FiChevronLeft>
                </Box>
              </Box>
              <Text fontSize="xs">{fromName}</Text>
            </VStack>
            <Text
              fontSize="0.65rem"
              pos="absolute"
              left={2}
              top={0}
              fontWeight={'bold'}
            >
              CurrentClient
            </Text>
          </Box>
          <Box px={2} p={2} overflowY={'auto'} h={96}>
            <Stack justifyContent="end" minH="full" w={64} maxW={64}>
              {messages.map((message, index) => {
                return (
                  <TextMessageBubble
                    key={index}
                    message={message}
                  ></TextMessageBubble>
                );
              })}
            </Stack>
          </Box>
          <Box
            p={2}
            borderTopWidth="1px"
            bg={theme.useColorModeValue('gray.50', 'gray.900')}
          >
            <Box
              p={2}
              fontSize={'sm'}
              color={theme.useColorModeValue('gray.400', 'gray.300')}
              bg={theme.useColorModeValue('white', 'gray.900')}
              borderWidth="1px"
              rounded="md"
            >
              Send Message
            </Box>
          </Box>
        </Box>
        {/* Home button */}
        <Flex alignItems={'center'} pt={2} pb={2} justifyContent={'center'}>
          <Box w={12} h={12} bg={'gray.900'} rounded="full"></Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default TextMessagePeview;
