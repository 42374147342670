import { Column } from 'react-table';
import { IUserProfile } from '../../../../models/UserProfile';
import { CellOrgApprove } from './CellOrgApprove';
import { CellOrgCompany } from './CellOrgCompany';
import { CellOrgGovernance } from './CellOrgGovernance';
import { CellOrgPhone } from './CellOrgPhone';
import { CellOrgPolicy } from './CellOrgPolicy';
import { CellOrgStatus } from './CellOrgStatus';
import { CellOrgUser } from './CellOrgUser';

interface IProps {}

export const COL_ID_BILLING_STATUS = 'COL_ID_BILLING_STATUS';
export const COL_ID_APPROVAL_STATUS = 'COL_ID_APPROVAL_STATUS';

export const OrganizationUsersColumns = ({}: IProps) =>
  [
    {
      Header: 'User',
      Cell: CellOrgUser,
      accessor: 'FirstName',
      sortType: 'basic',
    },
    {
      id: COL_ID_BILLING_STATUS,
      Header: 'Subscription',
      Cell: CellOrgStatus,
      accessor: 'Billing?.SubscriptionStatus',
      sortType: 'basic',
      filter: 'filterBillingStatus',
    },
    {
      Header: 'Company',
      accessor: 'OrganizationName',
      Cell: CellOrgCompany,
      sortType: 'basic',
    },
    {
      Header: 'Registered Phone',
      Cell: CellOrgPhone,
    },
    {
      Header: 'Governance',
      Cell: CellOrgGovernance,
    },
    {
      Header: 'Policy',
      Cell: CellOrgPolicy,
    },
    {
      id: COL_ID_APPROVAL_STATUS,
      Header: 'Approved',
      Cell: CellOrgApprove,
      filter: 'filterApprovalStatus',
    },
    // {
    //   Header: "Reported Status",
    //   minWidth: 70,
    //   width: 70,
    // },
  ] as Column<IUserProfile>[];
