import { Avatar, Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { IUserProfile } from '../../../../models/UserProfile';

export const CellOrgUser = ({ row }: CellProps<IUserProfile>) => {
  return (
    <Stack direction="row" spacing="4" align="center">
      <Box flexShrink={0} h="10" w="10">
        <Avatar
          w="10"
          h="10"
          borderRadius={4}
          overflow={'hidden'}
          src={row.original.BrandImageUrl}
          name={`${row.original.FirstName} ${row.original.LastName}`}
          alt=""
        />
      </Box>
      <Box>
        <Box fontSize="sm" fontWeight="medium">
          {row.original.FirstName} {row.original.LastName}
        </Box>
        <Box fontSize="sm" color="gray.500">
          {row.original.Email}
        </Box>
      </Box>
    </Stack>
  );
};
