import {
  Box,
  Button,
  ButtonGroup,
  Image,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { navigate } from '@reach/router';
import React from 'react';
import { FieldGroup } from '../../../components/forms/FieldGroup';
import { PropertyGroup } from '../../../components/forms/PropertyGroup';
import AlertCard from '../../../components/shared/AlertCard';
import { PageHeader } from '../../../layouts/PageHeader';
import {
  AppTrackEventsEnum,
  useAppTrack,
} from '../../../providers/AppTrackProvider';
import useCreateOrganizationLink from '../../../services/api/organization/mutations/useCreateOrganizationLink';
import { useReadPublicOrganization } from '../../../services/api/organization/queries/useReadPublicOrganization';
import { IOrganizationLinkCreate } from '../partnerships/models/OrganizationLink';

const SettingsNewPartner = props => {
  const organizationId = props.organizationId;

  const { data: dataOrganization } = useReadPublicOrganization(organizationId);

  const {
    mutateAsync: mutateAsyncLoading,
    isLoading: isLoadingPartnership,
    isError: isErrorPartnerships,
  } = useCreateOrganizationLink();

  const { logEvent: appTrackLogEvent } = useAppTrack();

  const handleOnIgnore = () => {
    navigate('/app/settings/partnerships');
  };

  const handleOnConfirm = async () => {
    // Post data to api
    // Create partnership
    const createData: IOrganizationLinkCreate = {
      WithOrganizationId: organizationId,
    };

    try {
      await mutateAsyncLoading(createData, {});
      appTrackLogEvent(AppTrackEventsEnum.ORGPARTNERSHIP_CREATED);
      navigate('/app/settings/partnerships');
    } catch (err) {
      console.log(err);
      // Throw error for downstream form to handle
      throw err;
    }
  };

  return (
    <>
      <PageHeader title="Add partnership"></PageHeader>
      <Box py={8}>
        <Stack spacing={8}>
          <FieldGroup
            title="Organization details"
            description="Do these details look correct?"
          >
            <Stack spacing="6" divider={<StackDivider />}>
              <PropertyGroup label="Brand">
                <Box h={24} rounded="sm" overflow={'hidden'} p={1}>
                  <Image
                    h="full"
                    objectFit={'contain'}
                    src={dataOrganization?.BrandImageUrl}
                  />
                </Box>
              </PropertyGroup>
              <PropertyGroup label="Name">
                <Text>{dataOrganization?.OrganizationName}</Text>
              </PropertyGroup>
              <PropertyGroup label="Contact">
                <Text>{dataOrganization?.Email}</Text>
              </PropertyGroup>
            </Stack>
          </FieldGroup>
          <Stack spacing={4}>
            <ButtonGroup>
              <Button
                type="submit"
                colorScheme="blue"
                onClick={handleOnConfirm}
                isLoading={isLoadingPartnership}
              >
                Connect with {dataOrganization?.OrganizationName}
              </Button>
              <Button variant="outline" onClick={handleOnIgnore}>
                Ignore
              </Button>
            </ButtonGroup>
            {/* Status of form (shows API errors) */}
            {isErrorPartnerships && (
              <Box>
                <AlertCard
                  title="Failed to partner"
                  type="error"
                  variant="fill"
                  //   action="Contact support"
                  //   actionTo="/app/support"
                  icon={ExclamationCircleIcon}
                >
                  <span>
                    Not working as it should! If you keep seeing this, please
                    message support.
                  </span>
                </AlertCard>
              </Box>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default SettingsNewPartner;
