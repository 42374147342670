import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  GridItem,
  SimpleGrid,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import React from 'react';
import AlertCard from '../../../../components/shared/AlertCard';
import {
  EmailFormsSchema,
  EmailStringTestSchema,
  IEmailValidatedForm,
  IEmailWorkingForm,
  initialEmailForm,
} from '../../../../models/EmailInvite';
import { useTheme } from '../../../../providers/ThemeProvider';
import { EmailFormGroup } from './EmailFormGroup';

interface IProps {
  onSubmit: (data: IEmailValidatedForm) => Promise<void>;
  onCancel: () => void;
}

export const EmailForm = ({ onSubmit, onCancel }: IProps) => {
  const [workingFormValues] =
    React.useState<IEmailWorkingForm>(initialEmailForm);

  const theme = useTheme();

  const testEmail = (emailIn: string) => {
    let isValid = true;

    try {
      EmailStringTestSchema.validateSync(emailIn);
    } catch (error) {
      isValid = false;
    }

    return isValid;
  };

  const parseEmailList = (emailsIn: string): string[] => {
    const emailsList = `${emailsIn}`
      .replace(/\s/g, '')
      .split(',')
      .filter(el => {
        return el !== '';
      });

    return emailsList;
  };

  const validateEmails = (emailsIn: string): string[] => {
    const emailsList = parseEmailList(emailsIn);

    const validEmails = emailsList.filter(email => {
      return testEmail(email);
    });

    return validEmails;
  };

  const handleOnSubmit = async (
    values,
    actions: FormikHelpers<IEmailWorkingForm>
  ) => {
    actions.setStatus();
    actions.setSubmitting(true);

    // get validated emails

    const validEmails = validateEmails(values.emails);

    const emailsOut: IEmailValidatedForm = {
      validEmails,
      note: values.note,
    };

    try {
      // Pass up logic
      await onSubmit(emailsOut);
    } catch (error) {
      // Error submitting form, set status for dispaly
      actions.setStatus('Failed to update question. Please try again.');
      actions.setSubmitting(false);
    }

    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={workingFormValues}
      validationSchema={EmailFormsSchema}
      onSubmit={(values, actions) => {
        handleOnSubmit(values, actions);
      }}
      enableReinitialize={true}
    >
      {props => {
        const emailsList = parseEmailList(props.values.emails);

        return (
          <FormikForm>
            <Stack spacing="8">
              <SimpleGrid columns={{ base: 1, lg: 2 }} gap={12}>
                <GridItem>
                  <EmailFormGroup form={props} />
                </GridItem>
                <GridItem>
                  <Stack>
                    <Text>Email list preview</Text>
                    {emailsList.length === 0 && (
                      <Text color={theme.color.base4}>
                        Validated emails will be displayed here
                      </Text>
                    )}
                    <Wrap spacing={4}>
                      {emailsList.map((email, idx) => {
                        const isValid = testEmail(email);

                        return (
                          <WrapItem key={`${email}-${idx}`}>
                            <Badge
                              whiteSpace={'normal'}
                              fontSize={'sm'}
                              px={2}
                              color={isValid ? 'gray' : 'red'}
                              textTransform={'none'}
                            >
                              {email}
                            </Badge>
                          </WrapItem>
                        );
                      })}
                    </Wrap>
                  </Stack>
                </GridItem>
              </SimpleGrid>

              <Stack spacing={4}>
                <ButtonGroup>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    isDisabled={!props.dirty}
                    isLoading={props.isSubmitting}
                  >
                    Send invite
                  </Button>
                  <Button
                    variant="outline"
                    onClick={onCancel}
                    isDisabled={props.isSubmitting}
                  >
                    Close
                  </Button>
                </ButtonGroup>
                {/* Status of form (shows API errors) */}
                {props.status && (
                  <Box>
                    <AlertCard
                      title="Failed to save"
                      type="error"
                      variant="fill"
                      //   action="Contact support"
                      //   actionTo="/app/support"
                      icon={ExclamationCircleIcon}
                    >
                      <span>
                        Not working as it should! If you keep seeing this,
                        please message support.
                      </span>
                    </AlertCard>
                  </Box>
                )}
              </Stack>
            </Stack>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
