/*eslint-disable no-unused-vars*/

import { Auth } from 'aws-amplify';
import React from 'react';
import {
  QueryObserverResult,
  RefetchOptions,
  useQueryClient,
} from 'react-query';
import {
  LibFullPageErrorFallback,
  LibFullPageSpinner,
} from '../components/lib';
import { IGovernorProfile } from '../models/GovernorProfile';
import useReadGovernorProfile from '../services/api/governorProfiles/queries/useReadGovernorProfile';
import { useAsync } from '../utils/useAsync';
import { AppTrackEventsEnum, useAppTrack } from './AppTrackProvider';

const getCurrentUser = async () => {
  // let data: any = null;
  let user: any = null;

  try {
    await Auth.currentSession();
    user = await Auth.currentUserInfo();
    user = { ...user.attributes, username: user.username };
  } catch (err) {
    // pass through error, no user will be set
  }

  return user;
};

const AuthContext = React.createContext('');

AuthContext.displayName = 'AuthContext';

const AuthProvider = (props: any) => {
  const {
    data: user,
    // status,
    error,
    isLoading,
    isIdle,
    // isError,
    isSuccess,
    run,
    setData,
  } = useAsync();

  const {
    data: userProfile,
    refetch: refetchUserProfile,
    isLoading: isLoadingProfile,
    // isFetching: isFetchingProfile,
  } = useReadGovernorProfile();

  const {
    logEvent: appTrackLogEvent,
    setUserId: appTrackSetUserId,
    clearUserId: appTrackClearUserId,
  } = useAppTrack();

  const queryClient = useQueryClient();

  React.useEffect(() => {
    const currentUserPromise = getCurrentUser();
    run(currentUserPromise);
  }, [run]);

  const login = React.useCallback(
    async (username, password) => {
      await Auth.signIn(username, password);

      appTrackLogEvent(AppTrackEventsEnum.GOVERNOR_SIGNEDIN);
      const user = await Auth.currentAuthenticatedUser();

      refetchUserProfile();
      appTrackSetUserId(user.username, username);

      // Set user values
      setData({ ...user.attributes, username: user.username });
    },
    [appTrackLogEvent, appTrackSetUserId, refetchUserProfile, setData]
  );

  const register = React.useCallback(
    async form => {
      await Auth.signUp(form);
      appTrackLogEvent(AppTrackEventsEnum.GOVERNOR_SIGNEDUP);
      // setData(user);
    },
    [appTrackLogEvent]
  );

  const logout = React.useCallback(async () => {
    await Auth.signOut();
    queryClient.clear();
    appTrackLogEvent(AppTrackEventsEnum.GOVERNOR_SIGNEDOUT);
    appTrackClearUserId();
    setData(null);
  }, [setData, appTrackClearUserId, appTrackLogEvent, queryClient]);

  const value = React.useMemo(
    () => ({
      user,
      userProfile,
      login,
      logout,
      register,
      refetchUserProfile,
      isLoadingProfile,
    }),
    [
      login,
      logout,
      register,
      user,
      userProfile,
      refetchUserProfile,
      isLoadingProfile,
    ]
  );

  if (isLoading || isIdle) {
    return <LibFullPageSpinner />;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  return <LibFullPageErrorFallback error={error} />;
};

const useAuth = (): {
  user: any;
  userProfile: IGovernorProfile;
  logout: any;
  login: any;
  refetchUserProfile: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<IGovernorProfile, unknown>>;
  register: any;
  isLoadingProfile: boolean;
} => {
  const context: any = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
