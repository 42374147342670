import { Badge, Box, Grid } from '@chakra-ui/react';
import React from 'react';
import { DescriptionLabel } from '../../../components/shared/Description';
import { runFormatDateWithAt } from '../../../hooks/useFormatDate';
import { PageHeader } from '../../../layouts/PageHeader';
import { useReadGuardrails } from '../../../services/api/guardrails/queries/useReadGuardrails';
import { GuardrailSettings } from './GuardrailSettings';

const GuardrailsPolicies = () => {
  const { data: dataGuardrails } = useReadGuardrails();

  return (
    <>
      <PageHeader
        title="Policies"
        description="Setup governing policies to control what your organization can do."
      >
        <Grid
          templateColumns={{
            base: '1fr',
            sm: 'repeat(2, 1fr)',
            lg: 'repeat(2, fit-content(320px))',
          }}
          columnGap="20"
          rowGap={{ base: '8', lg: '14' }}
        >
          <DescriptionLabel title="Version">
            <Badge colorScheme={'indigo'}>{dataGuardrails?.Version}</Badge>
          </DescriptionLabel>
          <DescriptionLabel title="Last Updated">
            {runFormatDateWithAt(dataGuardrails?.UpdatedAt)}
          </DescriptionLabel>
        </Grid>
      </PageHeader>
      <Box py={8}>
        <GuardrailSettings></GuardrailSettings>
      </Box>
    </>
  );
};

export default GuardrailsPolicies;
