import * as React from 'react';
// Added polyfill jun29 to solive issue with ChakraProvider not loading when running on android device
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import AppProviders from './providers/AppProviders';
import reportWebVitals from './reportWebVitals';
import './styles/algolia.css';
import './styles/hacks.css';

function ErrorFallback({ error }) {
  return (
    <div
      role="alert"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        paddingTop: '5rem',
      }}
    >
      <img
        style={{
          height: '16rem',
          border: '1px solid #ccc',
          width: '16rem',
          borderRadius: '1rem',
          padding: '1rem',
        }}
        src="https://cc-west-prd-bucket-users.s3.us-west-2.amazonaws.com/public/media/cc-error-comon.png"
        alt=""
      />
      <p
        style={{
          maxWidth: '30rem',
          marginTop: '1rem',
          textAlign: 'center',
          color: '#475569',
          fontFamily: 'Inter, sans-serif',
        }}
      >
        If you are seeing this, we are sorry! Let us know and we will pull out
        the duck tape and fix things up.
      </p>
      <div style={{ marginTop: '1rem' }}>
        <a
          href={`mailto:admin@currentclient.com?subject=App Broken&body=Hey, CurrentClient! I found this error:%0A%0A${error?.message}%0A%0ALet me know when you have it fixed. Thanks!%0A`}
        >
          Click Here to Email Us
        </a>
      </div>
      {error && error.message && (
        <div
          style={{
            width: '30rem',
            overflow: 'auto',
            maxWidth: '30rem',
            padding: '4px',
            textAlign: 'center',
            background: '#f1f5f9',
            marginTop: '1rem',
            borderRadius: '4px',
            fontFamily: 'Inter, sans-serif',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              color: '#475569',
            }}
          >
            Error
          </p>
          <pre
            style={{
              marginTop: '2px',
              color: '#dc2626',
            }}
          >
            {error.message}
          </pre>
        </div>
      )}
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    {/* <ErrorFallback error={{ message: 'faile' }}></ErrorFallback> */}
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AppProviders>
        <App />
      </AppProviders>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
