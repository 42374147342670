import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { OrganizationPartnerInviteIn } from '../../../../routes/organization/models/Organization';

const makeApiRequest = (
  inviteIn: OrganizationPartnerInviteIn
): Promise<OrganizationPartnerInviteIn> => {
  const postData = {
    body: inviteIn,
  };

  return API.post(
    'users',
    '/api/v1/organizations/partnership/invite',
    postData
  );
};

const useSendInvitePartners = () => {
  return useMutation<OrganizationPartnerInviteIn, any, any, unknown>(
    userSignup => makeApiRequest(userSignup),
    {
      retry: 0,
    }
  );
};

export default useSendInvitePartners;
