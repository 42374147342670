import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import appConfig from '../../../../app-config';
import { IEmailValidatedForm } from '../../../../models/EmailInvite';
import { useTheme } from '../../../../providers/ThemeProvider';
import useSendInviteUsers from '../../../../services/api/organization/mutations/useSendInviteUsers';
import { useReadOrganization } from '../../../../services/api/organization/queries/useReadOrganization';
import { EmailForm } from '../../../settings/partnerships/forms/EmailForm';
import { OrganizationPartnerInviteIn } from '../../models/Organization';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const InviteOrgUserModal = ({ isOpen, onClose }: IProps) => {
  const [userShareLink, set_userShareLink] = React.useState('');

  const theme = useTheme();
  const toast = useToast();
  const toastIdRef = React.useRef<any>();

  const { mutateAsync: mutateAsyncCreate, isLoading: isLoadingEmails } =
    useSendInviteUsers();

  const { data: dataOrganization } = useReadOrganization();

  const handleSuccess = () => {
    handleFormClose();
  };

  const handleFormSubmit = async (data: IEmailValidatedForm) => {
    // Post data to api

    if (!isLoadingEmails) {
      const inviteIn: OrganizationPartnerInviteIn = {
        InviteLink: userShareLink,
        EmailsTo: data.validEmails,
        Note: data.note,
      };

      toastIdRef.current = toast({
        title: 'Sending...',
        description: 'Give us a few seconds to send this invite.',
        status: 'info',
        variant: 'subtle',
        isClosable: true,
        duration: null,
      });

      try {
        await mutateAsyncCreate(inviteIn);

        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }

        toast({
          title: 'Sent',
          description: 'Invites are signed, sealed and delivered.',
          status: 'success',
          duration: 2000,
          variant: 'subtle',
          isClosable: true,
        });

        handleSuccess();
      } catch (e) {
        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }
        throw e;
      }
    }
  };

  React.useEffect(() => {
    if (dataOrganization?.OrganizationId) {
      set_userShareLink(
        `${appConfig.Organizations.baseInvite}${dataOrganization?.OrganizationId}`
      );
    }
  }, [dataOrganization]);

  const handleFormClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      size="6xl"
      colorScheme="primary"
    >
      <ModalOverlay />
      <ModalContent position={'relative'}>
        <ModalHeader>
          <Stack spacing={0}>
            <Text>Invite users</Text>
            <Text textStyle="description" color={theme.color.base3}>
              There are three ways to ultimate success: The first way is to be
              kind. The second way is to be kind. The third way is to be kind. -
              Fred Rogers
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={8}>
          <EmailForm
            onSubmit={handleFormSubmit}
            onCancel={handleFormClose}
          ></EmailForm>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
