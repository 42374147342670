import { Stack } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { GovernanceSmudge } from '../../../../components/shared/GovernanceSmudge';
import { IUserGovernance, IUserProfile } from '../../../../models/UserProfile';
import { useAuth } from '../../../../providers/AuthProvider';

export const CellOrgGovernance = ({ row }: CellProps<IUserProfile>) => {
  const { userProfile } = useAuth();

  const governorsList = React.useMemo(() => {
    let governorsList: IUserGovernance[] = [];

    if (row?.original?.Governance) {
      governorsList = Object.entries(row.original?.Governance)
        .sort((orgIdA, orgIdB) => {
          if (orgIdA[0] === userProfile.OrganizationId) {
            return -1;
          } else if (orgIdB[0] === userProfile.OrganizationId) {
            return 1;
          }
          return 0;
        })
        .map(orgId => orgId[1]);
    }

    return governorsList;
  }, [userProfile, row]);

  return (
    <Stack direction="row" spacing={4}>
      {governorsList &&
        governorsList.map((goveranceObj, idx) => {
          return (
            <GovernanceSmudge
              key={`idx-${idx}-${row.original.UserId}-${goveranceObj.OrganizationProfile?.OrganizationId}`}
              brandImageUrl={goveranceObj.OrganizationProfile?.BrandImageUrl}
              isApproved={goveranceObj.IsApproved}
              name={goveranceObj.OrganizationProfile?.OrganizationName}
            ></GovernanceSmudge>
          );
        })}
    </Stack>
  );
};
