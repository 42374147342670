import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {
  AppTrackEventsEnum,
  useAppTrack,
} from '../../../../../providers/AppTrackProvider';
import { useTheme } from '../../../../../providers/ThemeProvider';
import { RoutingRuleForm } from '../../../forms/routingRules/RoutingRulesForm';
import {
  IRoutingRule,
  IRoutingRuleMold,
  marshallRoutingRuleToMold,
} from '../../../models/RoutingRule';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  routingRule: IRoutingRule | null;
}

export const TemplateRoutingRuleModalView = ({
  isOpen,
  onClose,
  routingRule,
}: IProps) => {
  const theme = useTheme();
  const [routingRuleMold, set_routingRuleMold] =
    React.useState<IRoutingRuleMold | null>(null);

  const { logEvent: appTrackLogEvent } = useAppTrack();

  const handleSuccess = (dataIn: IRoutingRule) => {
    // Close modal when mutate is successful
    appTrackLogEvent(AppTrackEventsEnum.ROUTINGRULE_CREATED, {
      routingrule_id: dataIn.TemplateRoutingRuleId,
    });
    handleFormClose();
  };

  const handleFormClose = () => {
    onClose();
  };

  React.useEffect(() => {
    if (routingRule) {
      const mold = marshallRoutingRuleToMold(routingRule);
      set_routingRuleMold(mold);
    }
  }, [routingRule]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={true}
      motionPreset="slideInBottom"
      size="4xl"
      colorScheme="primary"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack spacing={0}>
            <Text>View routingrule</Text>
            <Text textStyle="description" color={theme.color.base3}>
              If you dont want this routing rule to be active, you can delete it
              in the table view.
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={8}>
          <RoutingRuleForm
            routingRuleMold={routingRuleMold}
            onCancel={handleFormClose}
            isReadOnly={true}
          ></RoutingRuleForm>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
