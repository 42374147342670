import React, { useMemo } from 'react';
import {
  Row,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { PAGE_SIZE_OPTIONS } from '../../../constants';
import useListTemplateRoutingRules from '../../../services/api/templateRoutingRules/queries/useListTemplateRoutingRules';
import { RoutingRulesColumns } from '../components/routingRules/RoutingRulesColumns';
import {
  IRoutingRule,
  ROUTING_RULE_EVENT_CONNECTOR_CONFIG,
  ROUTING_RULE_EVENT_NAME_CONFIG,
  ROUTING_RULE_FREQUENCY_CONFIG,
} from '../models/RoutingRule';

export const useRoutingRulesTable = () => {
  const [viewRoutingRule, set_viewRoutingRule] =
    React.useState<IRoutingRule | null>(null);
  const [deleteRoutingRule, set_deleteRoutingRule] =
    React.useState<IRoutingRule | null>(null);

  const filterTypes = React.useMemo(
    () => ({
      globalFilter: (
        rows: Row<IRoutingRule>[],
        columnIds: string[],
        filterValue: string
      ) => {
        return rows.filter(row => {
          let rowValue = `${row.original.EventName} ${row.original.Frequency}`;

          try {
            rowValue +=
              ROUTING_RULE_EVENT_CONNECTOR_CONFIG[row.original.ConnectorType]
                .label;
          } catch {}

          try {
            rowValue +=
              ROUTING_RULE_EVENT_NAME_CONFIG[row.original.EventName].label;
          } catch {}

          try {
            rowValue +=
              ROUTING_RULE_FREQUENCY_CONFIG[row.original.Frequency].label;
          } catch {}

          return rowValue
            .toLowerCase()
            .includes(String(filterValue).toLowerCase());
        });
      },
      statusEnabledFilter: (
        rows: Row<IRoutingRule>[],
        columnIds: string[],
        filterValue: boolean
      ) => {
        return rows.filter(row => {
          let isRowEnabled = row.original.IsEnabled;

          return filterValue !== null ? isRowEnabled === filterValue : true;
        });
      },
    }),
    []
  );

  const {
    data: tableData,
    isError: isErrorData,
    isLoading: isLoadingData,
  } = useListTemplateRoutingRules();

  let data = useMemo(() => tableData ?? [], [tableData]);

  const handleOnDeleteRoutingRule = (RoutingRule: IRoutingRule) => {
    set_deleteRoutingRule(RoutingRule);
  };
  const handleOnViewRoutingRule = (RoutingRule: IRoutingRule) => {
    set_viewRoutingRule(RoutingRule);
  };

  const columns = React.useMemo(
    () =>
      RoutingRulesColumns({
        onDeleteRoutingRule: (row: Row<IRoutingRule>) => {
          handleOnDeleteRoutingRule(row.original);
        },
        onViewRoutingRule: (row: Row<IRoutingRule>) => {
          handleOnViewRoutingRule(row.original);
        },
      }),
    []
  );

  // const handleRowClick = (row: Row<IRoutingRule>) => {
  //   row.toggleRowSelected();
  // };

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:

      width: 40, // width is used for both the flex-basis and flex-grow
    }),
    []
  );

  const table = useTable<IRoutingRule>(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: PAGE_SIZE_OPTIONS[0] },
      globalFilter: 'globalFilter',
      filterTypes: filterTypes,
    },
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination

    // useRowSelect,
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  return {
    ...table,
    isLoadingData,
    isErrorData,
    deleteRoutingRule,
    setDeleteRoutingRule: set_deleteRoutingRule,
    viewRoutingRule,
    setViewRoutingRule: set_viewRoutingRule,
  };
};

export default useRoutingRulesTable;
