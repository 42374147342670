import { Box, BoxProps } from '@chakra-ui/react';
import * as React from 'react';
import { useTheme } from '../providers/ThemeProvider';

export const PageContent = (props: BoxProps) => {
  const theme = useTheme();

  return (
    <Box as="main" h="full" w="full" overflow={'auto'}>
      <Box py={theme.py.container} px={theme.px.container} {...props}></Box>
    </Box>
  );
};
