import { HStack, Icon, Link, Text } from '@chakra-ui/react';
import { Link as ReachLink, Match } from '@reach/router';
import * as React from 'react';
import { useTheme } from '../providers/ThemeProvider';

interface NavItemProps {
  to?: string;
  label: string;
}

interface DesktopNavItemProps extends NavItemProps {
  icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
}

const DesktopNavItem = (props: DesktopNavItemProps) => {
  const theme = useTheme();
  const { icon, label, to = '#' } = props;
  return (
    <Match path={`${to}/*`}>
      {({ match, location }) => {
        const isActive = !!match;

        return (
          <Link
            display="block"
            position="relative"
            role="group"
            userSelect="none"
            as={ReachLink}
            to={isActive && location?.pathname ? location.pathname : to}
            align="center"
            justify="center"
            aria-current={isActive ? 'page' : undefined}
            spacing="2"
            px="3"
            py="2"
            rounded="sm"
            fontSize={'sm'}
            fontWeight={'500'}
            transition="all 0.2s"
            color={theme.color.base}
            _hover={{ bg: theme.bg.base3 }}
            _activeLink={{
              bg: theme.color.accent,
              color: theme.color.contrast,
            }}
          >
            <HStack>
              {icon && <Icon as={icon}></Icon>}
              <Text>{label}</Text>
            </HStack>
          </Link>
        );
      }}
    </Match>
  );
};

interface MobileNavItemProps extends NavItemProps {
  toggle: () => void;
}

const MobileNavItem = (props: MobileNavItemProps) => {
  const theme = useTheme();
  const { label, to = '#' } = props;

  return (
    <Match path={`${to}/*`}>
      {({ match, location }) => {
        const isActive = !!match;

        return (
          <Link
            display="block"
            position="relative"
            role="group"
            userSelect="none"
            as={ReachLink}
            onClick={props.toggle}
            to={isActive && location?.pathname ? location.pathname : to}
            align="center"
            justify="center"
            aria-current={isActive ? 'page' : undefined}
            spacing="2"
            px="3"
            py="2"
            rounded="sm"
            fontSize={'lg'}
            fontWeight={'500'}
            transition="all 0.2s"
            color={theme.color.base}
            _hover={{ bg: theme.bg.base3 }}
            _activeLink={{
              bg: theme.bg.contrast,
              color: theme.color.contrast,
            }}
          >
            <Text>{label}</Text>
          </Link>
        );
      }}
    </Match>
  );
};

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
};
