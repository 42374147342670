import { Divider, FlexProps, Stack } from '@chakra-ui/react';
import {
  DeviceMobileIcon,
  GlobeIcon,
  OfficeBuildingIcon,
  UserIcon,
} from '@heroicons/react/outline';
import * as React from 'react';
import { SubNavLink } from '../../../layouts/SubNavLink';
import { SupportNavLink } from '../../../layouts/SupportNavLink';

export const SettingsSidebar = (props: FlexProps) => {
  return (
    <Stack
      position={'relative'}
      h="full"
      spacing="6"
      as="nav"
      aria-label="Settings Navigation"
    >
      <Stack spacing="0">
        <SubNavLink
          label="Organization"
          to="/app/settings/organization"
          icon={OfficeBuildingIcon}
        />
        <SubNavLink
          label="Partnerships"
          to="/app/settings/partnerships"
          icon={GlobeIcon}
        />
        <SubNavLink
          label="Profile"
          to="/app/settings/profile"
          icon={UserIcon}
        />
        <SubNavLink
          label="MFA"
          to="/app/settings/mfa"
          icon={DeviceMobileIcon}
          isSoonBadge
        />
      </Stack>

      <Divider />

      <SupportNavLink></SupportNavLink>
    </Stack>
  );
};
