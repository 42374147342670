import { Box, Divider, Stack, StackProps, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTheme } from '../../providers/ThemeProvider';

interface PropertyStackProps extends StackProps {
  label?: string;
  level?: number;
  description?: string;
}

export const PropertyStack = (props: PropertyStackProps) => {
  const theme = useTheme();
  const { label, description, level = 0, children, ...flexProps } = props;
  return (
    <Stack
      w="full"
      spacing={{ base: '1', lg: '2' }}
      pt={level * 4}
      {...flexProps}
    >
      <Stack pl={level * 16} w="full" spacing={0} position={'relative'}>
        {level > 0 && (
          <Divider
            position={'absolute'}
            left={0}
            top="50%"
            w={level * 16 - 4}
          ></Divider>
        )}
        {label && (
          <Text textStyle="formLabel" flexShrink={0}>
            {label}
          </Text>
        )}
        {description && (
          <Text color={theme.color.base3} textStyle="description">
            {description}
          </Text>
        )}
      </Stack>
      <Box pl={level * 16} flex={1}>
        {children}
      </Box>
    </Stack>
  );
};
