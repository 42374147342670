import { Box, Button, ButtonGroup, Stack } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import AlertCard from '../../../../components/shared/AlertCard';
import { REGEX_PHONE } from '../../../../constants';
import {
  convertGovernorProfileToWorkingForm,
  IGovernorProfile,
  IGovernorProfileUpdate,
  IGovernorProfileWorkingForm,
  initialGovernorProfileSettingsForm,
} from '../../../../models/GovernorProfile';
import { GovProfileAddressSettingsFormGroup } from './GovProfileAddressSettingsFormGroup';
import { GovProfileContactSettingsFormGroup } from './GovProfileContactSettingsFormGroup';

interface IProps {
  governorProfile?: IGovernorProfile;
  onSubmit: (data: IGovernorProfileUpdate) => Promise<void>;
}

export const GovernorProfileSettingsForm = ({
  governorProfile,
  onSubmit,
}: IProps) => {
  const [workingFormValues, set_workingFormValues] =
    React.useState<IGovernorProfileWorkingForm>(
      initialGovernorProfileSettingsForm
    );

  const GovernorProfileSettingsFormsSchema = Yup.object().shape({
    FirstName: Yup.string().required('Required'),
    LastName: Yup.string().required('Required'),
    Email: Yup.string()
      .email('Please use name@domain.com pattern')
      .required('Required'),
    Phone: Yup.string()
      .matches(REGEX_PHONE, 'Please use 10 digit number without symbols')
      .required('Required'),
    Address1: Yup.string(),
    Address2: Yup.string(),
    City: Yup.string(),
    State: Yup.string(),
    Zip: Yup.string().min(5, '5 digits please').max(5, '5 digit zip please'),
  });

  const handleOnSubmit = async (
    values,
    actions: FormikHelpers<IGovernorProfileWorkingForm>
  ) => {
    actions.setStatus();
    actions.setSubmitting(true);

    try {
      // Pass up logic
      await onSubmit(values);
      actions.resetForm();
    } catch (error) {
      // Error submitting form, set status for dispaly
      actions.setStatus('Failed to update feature settings. Please try again.');
    }

    actions.setSubmitting(false);
  };

  React.useEffect(() => {
    if (governorProfile) {
      const updateValues = convertGovernorProfileToWorkingForm(governorProfile);
      // Moving naming keys to match form
      set_workingFormValues(updateValues);
    }
  }, [governorProfile]);

  return (
    <Formik
      initialValues={workingFormValues}
      validationSchema={GovernorProfileSettingsFormsSchema}
      onSubmit={(values, actions) => {
        handleOnSubmit(values, actions);
      }}
      enableReinitialize={true}
    >
      {props => {
        return (
          <FormikForm>
            <Stack spacing="8">
              <GovProfileContactSettingsFormGroup form={props} />
              <GovProfileAddressSettingsFormGroup form={props} />

              <Stack spacing={4}>
                <ButtonGroup>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    isDisabled={!props.dirty}
                    isLoading={props.isSubmitting}
                  >
                    Save Changes
                  </Button>
                  <Button
                    variant="outline"
                    onClick={props.handleReset}
                    isDisabled={props.isSubmitting}
                  >
                    Reset
                  </Button>
                </ButtonGroup>
                {/* Status of form (shows API errors) */}
                {props.status && (
                  <Box>
                    <AlertCard
                      title="Failed to save"
                      type="error"
                      variant="fill"
                      //   action="Contact support"
                      //   actionTo="/app/support"
                      icon={ExclamationCircleIcon}
                    >
                      <span>
                        Not working as it should! If you keep seeing this,
                        please message support.
                      </span>
                    </AlertCard>
                  </Box>
                )}
              </Stack>
            </Stack>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
