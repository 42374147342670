import {
  ChakraProvider,
  ColorMode,
  ColorModeScript,
  ResponsiveValue,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import ChakraTheme from './theme/index';

interface IThemeConfig {
  themeMode: ColorMode;
  useColorModeValue: <TLight = unknown, TDark = unknown>(
    light: TLight,
    dark: TDark
  ) => TLight | TDark;
  toggleColorMode: () => void;
  bw: string;
  px: {
    container: ResponsiveValue<number>;
  };
  py: {
    container: ResponsiveValue<number>;
  };
  bg: {
    primary: string;
    primary1: string;
    light: string;
    dark: string;
    base: string;
    base1: string;
    base2: string;
    base3: string;
    contrast: string;
    accentSoft: string;
    accentLoud: string;
    sec: string;
    disabled: string;
    nav: string;
    navBorder: string;
    textBubble: string;
    danger: string;
    danger1: string;
    dangerGradient: string;
    noticeGradient: string;
    warningGradient: string;
    primaryGradient: string;
  };
  color: {
    base: string;
    base1: string;
    base2: string;
    base3: string;
    base4: string;
    contrast: string;
    accent: string;
    danger: string;
    success: string;
    link: string;
    linkHover: string;
  };
}

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <ColorModeScript initialColorMode={'system'} />
      <ChakraProvider theme={ChakraTheme}>{children}</ChakraProvider>
    </>
  );
};

const useTheme = (): IThemeConfig => {
  const { colorMode, toggleColorMode } = useColorMode();

  const modeValues = {
    bw: useColorModeValue('black', 'white'),
    px: {
      container: { base: 4, md: 8, lg: 12 },
    },
    py: {
      container: { base: 4, md: 8 },
    },
    bg: {
      light: useColorModeValue('slate.50', 'slate.200'),
      primary: useColorModeValue('primary.50', 'primary.900'),
      primary1: useColorModeValue('primary.200', 'primary.600'),
      dark: useColorModeValue('slate.900', 'slate.800'),
      base: useColorModeValue('white', 'slate.800'),
      base1: useColorModeValue('slate.50', 'slate.700'),
      base2: useColorModeValue('slate.100', 'slate.600'),
      base3: useColorModeValue('slate.200', 'slate.500'),
      contrast: useColorModeValue('slate.800', 'slate.200'),
      accentSoft: useColorModeValue('indigo.50', 'indigo.900'),
      accentLoud: useColorModeValue('indigo.500', 'indigo.400'),
      sec: useColorModeValue('slate.50', 'slate.700'),
      disabled: useColorModeValue('slate.200', 'slate.700'),
      nav: useColorModeValue('slate.50', 'slate.900'),
      navBorder: useColorModeValue('slate.200', 'slate.700'),
      textBubble: useColorModeValue('slate.200', 'slate.700'),
      danger: useColorModeValue('red.100', 'red.900'),
      danger1: useColorModeValue('red.300', 'red.700'),
      dangerGradient: useColorModeValue(
        'linear(to-br, red.300, red.600)',
        'linear(to-br, red.400, red.700)'
      ),
      noticeGradient: useColorModeValue(
        'linear(to-br, indigo.300, indigo.600)',
        'linear(to-r, indigo.400, indigo.700)'
      ),
      primaryGradient: useColorModeValue(
        'linear(to-br, primary.300, primary.600)',
        'linear(to-r, primary.400, primary.700)'
      ),
      warningGradient: useColorModeValue(
        'linear(to-br, orange.300, orange.600)',
        'linear(to-r, orange.400, orange.700)'
      ),
    },
    color: {
      base: useColorModeValue('slate.800', 'slate.100'),
      base1: useColorModeValue('slate.700', 'slate.200'),
      base2: useColorModeValue('slate.600', 'slate.300'),
      base3: useColorModeValue('slate.400', 'slate.400'),
      base4: useColorModeValue('slate.300', 'slate.500'),
      contrast: useColorModeValue('slate.100', 'primary.800'),
      accent: useColorModeValue('primary.700', 'primary.200'),
      danger: useColorModeValue('red.700', 'red.200'),
      success: useColorModeValue('green.700', 'green.200'),
      link: useColorModeValue('primary.700', 'primary.200'),
      linkHover: useColorModeValue('primary.400', 'primary.500'),
    },
  };

  return {
    themeMode: colorMode,
    useColorModeValue,
    toggleColorMode,
    ...modeValues,
  };
};

export { ThemeProvider, useTheme };
