import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  ArrowNarrowRightIcon,
  ClockIcon,
  DuplicateIcon,
  OfficeBuildingIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { navigate } from '@reach/router';
import React from 'react';
import { useTheme } from '../../../../../providers/ThemeProvider';
import { useListOrganizationLinks } from '../../../../../services/api/organization/queries/useListOrganizationLinks';
import { IOrganizationLink } from '../../../../settings/partnerships/models/OrganizationLink';
import { IMessage } from '../../../models/Message';

interface IProps {
  message: IMessage;
  onEditMessage: () => void;
  onDeleteMessage: () => void;
  onViewHistory: () => void;
  onCopyMessage: () => void;
  onLinkMessage: (organizationLink: IOrganizationLink) => void;
}

export const CellMessageAction = ({
  message,
  onEditMessage,
  onDeleteMessage,
  onCopyMessage,
  onViewHistory,
  onLinkMessage,
}: IProps) => {
  const theme = useTheme();

  const { data: dataOrganizationLinks } = useListOrganizationLinks();

  const handleLinkPartner = async (organizationLink: IOrganizationLink) => {
    onLinkMessage(organizationLink);
  };

  const handleNewPartnership = async values => {
    navigate('/app/settings/partnerships');
  };

  return (
    <ButtonGroup
      spacing="4"
      isDisabled={message.IsDeleting || message.IsProcessing}
      size="sm"
      alignItems={'center'}
      h="full"
      flexWrap="wrap"
    >
      <Wrap>
        <WrapItem>
          <Button onClick={onEditMessage}>Edit</Button>
        </WrapItem>
        <WrapItem>
          <Tooltip label="Delete message" fontSize="xs">
            <IconButton
              aria-label="delete message"
              onClick={onDeleteMessage}
              icon={<Icon as={TrashIcon} />}
            ></IconButton>
          </Tooltip>
        </WrapItem>
        <WrapItem>
          <Tooltip label="View history" fontSize="xs">
            <IconButton
              aria-label="view history"
              onClick={onViewHistory}
              icon={<Icon as={ClockIcon} />}
            ></IconButton>
          </Tooltip>
        </WrapItem>
        <WrapItem>
          <Tooltip label="Duplicate message" fontSize="xs">
            <IconButton
              aria-label="duplicate message"
              onClick={onCopyMessage}
              icon={<Icon as={DuplicateIcon} />}
            ></IconButton>
          </Tooltip>
        </WrapItem>
        <WrapItem>
          <Menu>
            <Tooltip
              label={
                message.IsManaged
                  ? "You can't link a managed template"
                  : message.IsLinked
                  ? 'Already linked'
                  : 'Link to another organization'
              }
              fontSize="xs"
              shouldWrapChildren
            >
              <MenuButton
                isDisabled={
                  message.IsManaged ||
                  message.IsDeleting ||
                  message.IsProcessing ||
                  message.IsLinked
                }
                as={IconButton}
                size="sm"
                icon={<Icon as={OfficeBuildingIcon} />}
                aria-label="Link organization"
              ></MenuButton>
            </Tooltip>
            <MenuList
              rounded="sm"
              shadow="lg"
              py="1"
              bg={theme.bg.base}
              color={theme.color.base}
              fontSize="sm"
            >
              {!dataOrganizationLinks?.length && (
                <MenuItem
                  onClick={handleNewPartnership}
                  textStyle={'navLink'}
                  color={theme.color.link}
                >
                  <HStack w="full" spacing="4" justifyContent={'space-between'}>
                    <Text textStyle={'navLink'} as="span">
                      Create new partnership
                    </Text>
                    <Icon
                      as={ArrowNarrowRightIcon}
                      fontSize="lg"
                      opacity={0.64}
                    />
                  </HStack>
                </MenuItem>
              )}

              {dataOrganizationLinks?.map(
                (organizationLink: IOrganizationLink) => {
                  return (
                    <MenuItem
                      key={organizationLink.OrganizationId}
                      textStyle={'navLink'}
                      onClick={() => handleLinkPartner(organizationLink)}
                    >
                      <HStack px="3" py="2">
                        <Box h={8} rounded={'sm'} overflow={'hidden'}>
                          <Image
                            objectFit={'contain'}
                            h="full"
                            src={organizationLink.BrandImageUrl}
                          ></Image>
                        </Box>
                        <Box lineHeight="1">
                          <Text>{organizationLink.OrganizationName}</Text>
                        </Box>
                      </HStack>
                    </MenuItem>
                  );
                }
              )}
            </MenuList>
          </Menu>
        </WrapItem>
      </Wrap>
    </ButtonGroup>
  );
};
