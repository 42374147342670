import { Button, Center, Icon, Stack, Text } from '@chakra-ui/react';
import { SearchCircleIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTheme } from '../../providers/ThemeProvider';

interface IProps {
  currentSearch: string;
  clearSearch: () => void;
}

export const EmptySearchTableStub = ({
  currentSearch,
  clearSearch,
}: IProps) => {
  const theme = useTheme();
  return (
    <Stack spacing={4} py={8}>
      <Text textAlign={'center'} textStyle={'strong'}>
        No records match "{currentSearch}"
      </Text>

      <Center>
        <Button
          aria-label="clear search"
          size="sm"
          onClick={clearSearch}
          leftIcon={<Icon as={SearchCircleIcon}></Icon>}
        >
          Clear search
        </Button>
      </Center>
    </Stack>
  );
};
