import { Badge, Box, Grid } from '@chakra-ui/react';
import React from 'react';
import { DescriptionLabel } from '../../../components/shared/Description';
import { runFormatDateWithAt } from '../../../hooks/useFormatDate';
import { PageHeader } from '../../../layouts/PageHeader';
import useReadGovernorProfile from '../../../services/api/governorProfiles/queries/useReadGovernorProfile';
import { GovernorProfileSettings } from './GovernorProfileSettings';

const SettingsProfile = () => {
  const { data: dataGovernorProfile } = useReadGovernorProfile();

  return (
    <>
      <PageHeader title="Profile">
        <Grid
          templateColumns={{
            base: '1fr',
            sm: 'repeat(2, 1fr)',
            lg: 'repeat(2, fit-content(320px))',
          }}
          columnGap="20"
          rowGap={{ base: '8', lg: '14' }}
        >
          <DescriptionLabel title="Role">
            <Badge colorScheme={'indigo'}>Governor</Badge>
          </DescriptionLabel>
          <DescriptionLabel title="Last Updated">
            {runFormatDateWithAt(dataGovernorProfile?.UpdatedAt)}
          </DescriptionLabel>
        </Grid>
      </PageHeader>
      <Box py={8}>
        <GovernorProfileSettings></GovernorProfileSettings>
      </Box>
    </>
  );
};

export default SettingsProfile;
