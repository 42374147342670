import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { IOrganizationUpdate } from '../../../../routes/organization/models/Organization';

const makeApiRequest = (formData: FormData) => {
  const postData = {
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return API.post('users', '/api/v1/organizations/uploadbranding', postData);
};

const useUploadOrganizationBranding = () => {
  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation<IOrganizationUpdate, any, { formData: FormData }>(
    ({ formData }) => makeApiRequest(formData),
    {
      retry: 0,
    }
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

export default useUploadOrganizationBranding;
