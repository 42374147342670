import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { IQuestion } from '../../../../routes/guardrails/models/Question';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = () => {
  const qParams = {
    queryStringParameters: {},
  };

  return API.get('users', '/api/v1/templatequestions/', qParams);
};

const useListTemplateQuestions = () => {
  const { data, isError, isLoading } = useQuery<IQuestion[], any, IQuestion[]>(
    [QueryKeys.templateQuestions],
    () => {
      return makeApiRequest();
    },
    {
      select: (data: IQuestion[]) => {
        data.sort((a, b) => {
          const aTime = a.CreatedTime;
          const bTime = b.CreatedTime;

          if (aTime < bTime) {
            return 1;
          }
          if (aTime > bTime) {
            return -1;
          }
          return 0;
        });

        return data;
      },
      keepPreviousData: true,
      cacheTime: 5000,
      retry: false,
    }
  );

  return {
    data,
    isError,
    isLoading,
  };
};

export default useListTemplateQuestions;
