import {
  Icon,
  InputLeftAddon,
  Stack,
  StackDivider,
  useTheme,
} from '@chakra-ui/react';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import { FormikProps } from 'formik';
import React from 'react';
import { FormInputControl } from '../../../../components/forms';
import { FieldGroup } from '../../../../components/forms/FieldGroup';
import { PropertyGroup } from '../../../../components/forms/PropertyGroup';
import { IOrganizationWorkingForm } from '../../../organization/models/Organization';
import { OrganizationProfileImageUpload } from '../../components/OrganizationProfileImageUpload';

const ValidateFormLogic = () => {
  // Grab values and submitForm from context
  // const { values, setFieldValue } =
  //   useFormikContext<IOrganizationWorkingForm>();

  return null;
};

interface IProps {
  form: FormikProps<IOrganizationWorkingForm>;
}

export const OrgContactSettingsFormGroup = ({ form }: IProps) => {
  const theme = useTheme();

  const onBrandingUrlChange = async updatedImageUrl => {
    form.setFieldValue('BrandImageUrl', updatedImageUrl);
  };

  return (
    <>
      <ValidateFormLogic />
      <Stack spacing={4}>
        <FieldGroup
          title="Organization Details"
          description="Core organization settings"
        >
          <Stack spacing="6" divider={<StackDivider />}>
            <PropertyGroup label="Organization Image">
              <OrganizationProfileImageUpload
                currentBrandUrl={form.values.BrandImageUrl}
                onChange={onBrandingUrlChange}
              ></OrganizationProfileImageUpload>
            </PropertyGroup>
            <PropertyGroup label="Organization Name">
              <FormInputControl
                name="OrganizationName"
                formControlProps={{
                  maxW: '24rem',
                }}
              />
            </PropertyGroup>
            <PropertyGroup
              label="Primary contact email"
              description="This will be displayed to users."
            >
              <FormInputControl
                name="Email"
                inputLeftAddon={
                  <InputLeftAddon
                    children={<Icon as={MailIcon} color={theme.colors.base3} />}
                  />
                }
                formControlProps={{
                  maxW: '26rem',
                }}
              />
            </PropertyGroup>
            <PropertyGroup label="Primary contact phone">
              <FormInputControl
                name="Phone"
                inputLeftAddon={
                  <InputLeftAddon
                    children={
                      <Icon as={PhoneIcon} color={theme.colors.base3} />
                    }
                  />
                }
                formControlProps={{
                  maxW: '16rem',
                }}
              />
            </PropertyGroup>
            <PropertyGroup
              label="Audit trail email"
              description="When an artifiact is approved that event will be emailed to this address."
            >
              <FormInputControl
                name="AuditTrailEmail"
                inputLeftAddon={
                  <InputLeftAddon
                    children={<Icon as={MailIcon} color={theme.colors.base3} />}
                  />
                }
                formControlProps={{
                  maxW: '26rem',
                }}
              />
            </PropertyGroup>
          </Stack>
        </FieldGroup>
      </Stack>
    </>
  );
};
