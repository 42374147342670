import { Box, BoxProps } from '@chakra-ui/react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { FunctionComponent } from 'react';
import Organization from '../routes/organization/components/Organization';
import OrganizationEvents from '../routes/organization/components/OrganizationEvents';
import { PageContainer } from './PageContainer';
import { PageContent } from './PageContent';

const OrganizationLayout = () => {
  return (
    <PageContainer>
      <PageContent>
        {/* Routes */}
        <Box as={Router} primary={false}>
          <Redirect default from="/" to="users" noThrow />
          <Route path="/users" component={Organization} />
          <Route path="/events" component={OrganizationEvents} />
        </Box>
      </PageContent>
    </PageContainer>
  );
};

export default OrganizationLayout;

const Route: FunctionComponent<
  {
    component: React.ComponentType<any>;
  } & RouteComponentProps &
    BoxProps
> = ({ component: Component, ...rest }) => {
  return <Component {...rest} />;
};
