import * as Yup from 'yup';

export interface IEmailWorkingForm {
  emails: string;
  note: string;
}

export interface IEmailValidatedForm {
  validEmails: string[];
  note: string;
}

export const initialEmailForm: IEmailWorkingForm = {
  emails: '',
  note: '',
};

export const EmailFormsSchema = Yup.object().shape({
  emails: Yup.string().required('Required'),
  note: Yup.string(),
});

export const EmailStringTestSchema = Yup.string().email('Invalid Email');
