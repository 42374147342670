import { Box, Icon, Stack, StackDivider } from '@chakra-ui/react';
import {
  ChatAltIcon,
  QuestionMarkCircleIcon,
  TruckIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';
import * as React from 'react';
import { useReadOrganizationUsers } from '../../../services/api/organization/queries/useReadOrganizationUsers';
import useListTemplateMessages from '../../../services/api/templateMessages/queries/useListTemplateMessages';
import useListTemplateQuestions from '../../../services/api/templateQuestions/queries/useListTemplateQuestions';
import useListTemplateRoutingRules from '../../../services/api/templateRoutingRules/queries/useListTemplateRoutingRules';
import { StatCard } from './StatCard';

export const DashboardStats = () => {
  const { data: dataMessages } = useListTemplateMessages();
  const { data: dataQuestions } = useListTemplateQuestions();
  const { data: dataUsers } = useReadOrganizationUsers();
  const { data: dataRules } = useListTemplateRoutingRules();

  return (
    <Box as="section" px={8} py="12">
      <Box>
        <Box>
          <Stack
            spacing="8"
            justify="space-between"
            direction={{ base: 'column', md: 'row' }}
            divider={<StackDivider />}
          >
            <StatCard
              accentColor="primary.900"
              icon={<Icon as={UserCircleIcon} />}
              data={{
                label: 'Organization',
                value: dataUsers?.meta.total,
                unit: 'Total users',
              }}
            />
            {/* <StatCard
              accentColor="red.500"
              icon={<Icon as={UserAddIcon} />}
              data={{
                label: 'Approval Requests',
                value: '4',
                unit: 'Total',
              }}
            /> */}
            <StatCard
              accentColor="primary.700"
              icon={<Icon as={QuestionMarkCircleIcon} />}
              data={{
                label: 'Question Bank',
                value: dataQuestions?.length,
                unit: 'Total questions',
              }}
            />
            <StatCard
              accentColor="primary.500"
              icon={<Icon as={ChatAltIcon} />}
              data={{
                label: 'Message templates',
                value: dataMessages?.length,
                unit: 'Total messages',
              }}
            />
            <StatCard
              accentColor="primary.300"
              icon={<Icon as={TruckIcon} />}
              data={{
                label: 'Routing rules',
                value: dataRules?.length,
                unit: 'Total rules',
              }}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
