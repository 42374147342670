import { Stack, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import useOrganizationUsersTable from '../../hooks/useOrganizationUsersTable';
import { InviteOrgUserModal } from './InviteOrgUserModal';
import { OrganizationUsersTableActions } from './OrganizationUsersTableActions';
import { OrganizationUsersTableContent } from './OrganizationUsersTableContent';
import { OrganizationUsersTablePagination } from './OrganizationUsersTablePagination';

export const OrganizationUsersTable = () => {
  const table = useOrganizationUsersTable();

  const {
    isOpen: isOpenModal,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();

  const handleOnInvite = () => {
    onOpenModal();
  };

  return (
    <>
      <Stack as="section" minW={'lg'} spacing={6}>
        <OrganizationUsersTableActions
          onInvite={handleOnInvite}
          table={table}
        />
        <OrganizationUsersTableContent table={table} />
        <OrganizationUsersTablePagination table={table} />
      </Stack>

      <InviteOrgUserModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
      ></InviteOrgUserModal>
    </>
  );
};
