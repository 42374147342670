import { Box } from '@chakra-ui/react';
import React from 'react';
import { PageHeader } from '../../../layouts/PageHeader';
import { RoutingRulesTable } from './routingRules/RoutingRulesTable';

const GuardrailsRouting = () => {
  return (
    <>
      <PageHeader
        title="Routing"
        description="Create routing policies that will enable you to enforce your archiving requirements"
      ></PageHeader>
      <Box py={8}>
        <RoutingRulesTable></RoutingRulesTable>
      </Box>
    </>
  );
};

export default GuardrailsRouting;
