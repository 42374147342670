import { Box, Button, ButtonGroup, Stack } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import AlertCard from '../../../components/shared/AlertCard';
import {
  convertGuardrailsToAllowForm,
  convertGuardrailsToForbidForm,
  IGuardrails,
  IGuardrailsUpdate,
  IGuardrailsWorkingForm,
  initialGuardrailSettingsForm,
} from '../models/Guardrails';
import { FeatureSettingsFormGroup } from './FeatureSettingsFormGroup';
import { ManagementSettingsFormGroup } from './ManagementSettingsFormGroup';

interface IProps {
  guardrails?: IGuardrails;
  onSubmit: (data: IGuardrailsUpdate) => Promise<void>;
}

export const GuardrailSettingsForm = ({ guardrails, onSubmit }: IProps) => {
  const [workingFormValues, set_workingFormValues] =
    React.useState<IGuardrailsWorkingForm>(initialGuardrailSettingsForm);

  const GuardrailSettingsFormsSchema = Yup.object().shape({
    isAllowRVM: Yup.bool().required('Required'),
    isAllowBroadcast: Yup.bool().required('Required'),
    isAllowBroadcastCustomMessage: Yup.bool().required('Required'),
    isAllowCampaign: Yup.bool().required('Required'),
    isAllowCampaignCustomDiscovery: Yup.bool().required('Required'),
    isAllowCampaignCustomMessage: Yup.bool().required('Required'),
    isEnforceMFA: Yup.bool().required('Required'),
  });

  const handleOnSubmit = async (
    values,
    actions: FormikHelpers<IGuardrailsWorkingForm>
  ) => {
    actions.setStatus();
    actions.setSubmitting(true);

    try {
      const dataSubmit = convertGuardrailsToForbidForm(values);
      // Pass up logic
      await onSubmit(dataSubmit);
      actions.resetForm();
    } catch (error) {
      // Error submitting form, set status for dispaly
      actions.setStatus('Failed to update feature settings. Please try again.');
    }

    actions.setSubmitting(false);
  };

  React.useEffect(() => {
    if (guardrails) {
      // Moving naming keys to match form
      const workingFormUpdate = convertGuardrailsToAllowForm(guardrails);
      set_workingFormValues(workingFormUpdate);
    }
  }, [guardrails]);

  return (
    <Formik
      initialValues={workingFormValues}
      validationSchema={GuardrailSettingsFormsSchema}
      onSubmit={(values, actions) => {
        handleOnSubmit(values, actions);
      }}
      enableReinitialize={true}
    >
      {props => {
        return (
          <FormikForm>
            <Stack spacing="8">
              <FeatureSettingsFormGroup form={props} />
              <ManagementSettingsFormGroup />

              <Stack spacing={4}>
                <ButtonGroup>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    isDisabled={!props.dirty}
                    isLoading={props.isSubmitting}
                  >
                    Save Changes
                  </Button>
                  <Button
                    variant="outline"
                    onClick={props.handleReset}
                    isDisabled={props.isSubmitting}
                  >
                    Reset
                  </Button>
                </ButtonGroup>
                {/* Status of form (shows API errors) */}
                {props.status && (
                  <Box>
                    <AlertCard
                      title="Failed to save"
                      type="error"
                      variant="fill"
                      //   action="Contact support"
                      //   actionTo="/app/support"
                      icon={ExclamationCircleIcon}
                    >
                      <span>
                        Not working as it should! If you keep seeing this,
                        please message support.
                      </span>
                    </AlertCard>
                  </Box>
                )}
              </Stack>
            </Stack>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
