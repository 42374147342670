import {
  Button,
  FormHelperText,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { Field } from 'formik';
import React, { ReactNode, useState } from 'react';
import { useTheme } from '../../../providers/ThemeProvider';
import { BaseProps } from '../baseProps';
import { FormControl } from '../FormControl/FormControl';
import { ChakraInputStatusIcon } from './InputStatusIcon';

export type InputControlProps = BaseProps &
  InputProps & {
    inputLeftAddon?: ReactNode;
    inputLeftElement?: ReactNode;
    inputRightElement?: ReactNode;
    helperTextElement?: ReactNode;
    isIncludeDescription?: boolean;
    description?: string;
  };

export const InputControl = (props: InputControlProps) => {
  const {
    type = 'text',
    label,
    isIncludeDescription,
    description,
    formControlProps,
    inputLeftAddon,
    inputLeftElement,
    inputRightElement,
    helperTextElement,
    ...inputProps
  } = props;

  const { isRequired, error, touched, ...formControlPropsRest } =
    formControlProps || {};

  const theme = useTheme();

  const isPassword = type === 'password';
  const [show, setShow] = useState<boolean>(!isPassword);

  return (
    <FormControl
      isIncludeDescription={isIncludeDescription}
      description={description}
      label={label}
      isRequired={isRequired}
      error={error}
      touched={touched}
      // bg="yellow.100"
      {...formControlPropsRest}
    >
      <InputGroup>
        {inputLeftAddon}
        {inputLeftElement}
        <Input
          onFocus={event => event.target.select()}
          bg={inputProps.isDisabled ? theme.bg.disabled : 'inherit'}
          type={isPassword ? (show ? 'text' : 'password') : type}
          {...inputProps}
        />
        {isPassword && (
          <InputRightElement width="6rem" height="100%">
            <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        )}
        {inputRightElement ? (
          inputRightElement
        ) : type === 'password' ? null : (
          <ChakraInputStatusIcon
            error={error}
            touched={touched}
            isRequired={isRequired}
          />
        )}
      </InputGroup>

      {helperTextElement && (
        <FormHelperText>{helperTextElement}</FormHelperText>
      )}
    </FormControl>
  );
};

export type FormInputControlProps = InputControlProps & {
  name: string;
};

export const FormInputControl = (props: FormInputControlProps) => {
  const { name, formControlProps, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <InputControl
            {...rest}
            formControlProps={{
              ...meta,
              ...formControlProps,
            }}
            {...field}
          />
        );
      }}
    </Field>
  );
};
