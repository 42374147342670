import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from "@chakra-ui/react";
import React from "react";

export type FormControlProps = ChakraFormControlProps & {
  name?: string;
  touched?: boolean;
  error?: string;
  formLabelProps?: FormLabelProps;
  isIncludeDescription?: boolean;
  description?: string;
};

export const FormControl = (props: FormControlProps) => {
  const {
    name,
    label,
    isIncludeDescription,
    description,
    touched,
    error,
    children,
    formLabelProps,
    // Pulling off some formik values to keep errors out of console
    // @ts-ignore
    initialError,
    // @ts-ignore
    initialValue,
    // @ts-ignore
    initialTouched,
    // @ts-ignore
    isChecked,
    ...rest
  } = props;

  return (
    <ChakraFormControl isInvalid={touched && !!error} {...rest}>
      {label && (
        <FormLabel htmlFor={name} {...formLabelProps}>
          {label}
        </FormLabel>
      )}
      {isIncludeDescription && description && (
        <p className="mb-2 -mt-2 text-caption color-subtle">{description}</p>
      )}
      {children}
      <FormErrorMessage>{error}</FormErrorMessage>
    </ChakraFormControl>
  );
};
