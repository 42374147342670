import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { LockClosedIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline';
import {
  Field,
  FieldArray,
  FormikProps,
  getIn,
  useFormikContext,
} from 'formik';
import React from 'react';
import {
  ChakraInputStatusIcon,
  FormInputControl,
  FormSingleSwitch,
  FormTextareaControl,
} from '../../../../components/forms';
import { PropertyStack } from '../../../../components/forms/PropertyStack';
import { FormRadioItemTextList } from '../../../../components/forms/Radio/common/RadioItemTextList';
import { QuestionTypeMultiSelectSmudge } from '../../components/questions/QuestionTypeMultiSelectSmudge';
import { QuestionTypeTextLongSmudge } from '../../components/questions/QuestionTypeTextLongSmudge';
import { QuestionTypeTextShortSmudge } from '../../components/questions/QuestionTypeTextShortSmudge';
import { IQuestionWorkingForm, QuestionTypeEnum } from '../../models/Question';

const ValidateFormLogic = () => {
  // Grab values and submitForm from context
  const { values, setFieldValue } = useFormikContext<IQuestionWorkingForm>();

  React.useEffect(() => {
    if (!values.IsIncludeQuestionDescription) {
      setFieldValue('IsLockDescription', false);
    }
    if (!values.IsAllowRemoveProbes) {
      values.Probes?.forEach((_, index) => {
        setFieldValue(`Probes.${index}.IsAllowRemoveProbe`, false);
      });
    }
  }, [values, setFieldValue]);

  return null;
};

interface IProps {
  form: FormikProps<IQuestionWorkingForm>;
}

const ErrorMessage = ({ name }) => (
  <Field name={name}>
    {({ field, form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? error : null;
    }}
  </Field>
);

export const QuestionFormGroup = ({ form }: IProps) => {
  return (
    <>
      <ValidateFormLogic />
      <Stack spacing={4}>
        <Stack spacing="6">
          <PropertyStack
            label="Question Type"
            description="Question type determines how an answer is presented to a client."
          >
            <FormRadioItemTextList
              name="QuestionType"
              choices={[
                {
                  item: <QuestionTypeTextShortSmudge />,
                  label: 'Short response',
                  value: QuestionTypeEnum.TEXT_SHORT,
                },
                {
                  item: <QuestionTypeTextLongSmudge />,
                  label: 'Long response',
                  value: QuestionTypeEnum.TEXT_LONG,
                },
                {
                  item: <QuestionTypeMultiSelectSmudge />,
                  label: 'Multiple Choice',
                  value: QuestionTypeEnum.MULTI_SELECT,
                },
              ]}
            />
          </PropertyStack>
          <PropertyStack label="Question" description="What is the question?">
            <Stack>
              <FormSingleSwitch name="IsLockTitle">
                <HStack>
                  <Icon as={LockClosedIcon}></Icon>
                  <Text textStyle="switchLabel">Lock question</Text>
                </HStack>
              </FormSingleSwitch>
              <FormInputControl
                name="QuestionTitle"
                formControlProps={{
                  maxW: '28rem',
                }}
              />
            </Stack>
          </PropertyStack>

          <PropertyStack
            label="Description"
            description="Choose to include a description in the question to clarify or enhance the question."
          >
            <Stack>
              <HStack spacing={8}>
                <FormSingleSwitch
                  formControlProps={{
                    w: 'min-content',
                    whiteSpace: 'nowrap',
                  }}
                  name="IsIncludeQuestionDescription"
                >
                  <Text textStyle="switchLabel">Include</Text>
                </FormSingleSwitch>
                <FormSingleSwitch
                  formControlProps={{
                    w: 'min-content',
                    whiteSpace: 'nowrap',
                  }}
                  isDisabled={!form.values.IsIncludeQuestionDescription}
                  name="IsLockDescription"
                >
                  <HStack>
                    <Icon as={LockClosedIcon}></Icon>
                    <Text textStyle="switchLabel">Lock description</Text>
                  </HStack>
                </FormSingleSwitch>
              </HStack>
              <FormTextareaControl
                isDisabled={!form.values.IsIncludeQuestionDescription}
                name="QuestionDescription"
                formControlProps={{
                  w: '32rem',
                }}
              />
            </Stack>
          </PropertyStack>

          {form.values.QuestionType === QuestionTypeEnum.MULTI_SELECT && (
            <PropertyStack
              label="Answer Options"
              description="Add mutli select options"
            >
              <Stack>
                <HStack spacing={8}>
                  <FormSingleSwitch
                    name="IsAllowRemoveProbes"
                    formControlProps={{
                      w: 'min-content',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <HStack>
                      <Icon as={TrashIcon}></Icon>
                      <Text textStyle="switchLabel">
                        Allow options to be removed
                      </Text>
                    </HStack>
                  </FormSingleSwitch>
                  <FormSingleSwitch
                    name="IsAllowAddProbes"
                    formControlProps={{
                      w: 'min-content',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <HStack>
                      <Icon as={PlusIcon}></Icon>
                      <Text textStyle="switchLabel">
                        Allow options to be added
                      </Text>
                    </HStack>
                  </FormSingleSwitch>
                </HStack>

                <FieldArray
                  name="Probes"
                  validateOnChange={true}
                  render={arrayHelpers => (
                    <Box pt={8}>
                      <FormControl>
                        <Stack spacing={4}>
                          <SimpleGrid
                            spacing={4}
                            templateColumns={'1fr 15% 15% 4rem'}
                            alignItems="center"
                          >
                            <Text textStyle={'badge'}>Option name</Text>
                            <HStack>
                              <Icon as={LockClosedIcon}></Icon>
                              <Text textStyle={'badge'}>Lock text</Text>
                            </HStack>
                            <HStack>
                              <Icon as={TrashIcon}></Icon>
                              <Text textStyle={'badge'}>Can remove</Text>
                            </HStack>
                            <Text></Text>
                          </SimpleGrid>

                          {form.values.Probes &&
                            form.values.Probes.length > 0 &&
                            form.values.Probes.map((probe, index) => {
                              return (
                                <SimpleGrid
                                  key={`${index}-probe-row`}
                                  spacing={4}
                                  templateColumns={'1fr 15% 15% 4rem'}
                                  alignItems="center"
                                  justifyItems={'center'}
                                >
                                  <Field
                                    key={`${index}-input`}
                                    name={`Probes.${index}.Name`}
                                  >
                                    {({ field }) => (
                                      <FormControl
                                        isRequired
                                        isInvalid={
                                          form.errors.Probes &&
                                          form.errors.Probes[index] &&
                                          Array.isArray(form.errors.Probes) &&
                                          form.touched.Probes &&
                                          form.touched.Probes[index]
                                        }
                                      >
                                        <InputGroup>
                                          <Input
                                            {...field}
                                            id={`Probes.${index}.Name`}
                                            placeholder=""
                                          />

                                          <ChakraInputStatusIcon
                                            isRequired
                                            error={
                                              form.errors.Probes &&
                                              Array.isArray(
                                                form.errors.Probes
                                              ) &&
                                              form.errors.Probes[index]
                                            }
                                            touched={
                                              form.touched.Probes &&
                                              form.touched.Probes[index]
                                            }
                                          />
                                        </InputGroup>

                                        <FormErrorMessage>
                                          <ErrorMessage
                                            name={`Probes.${index}.Name`}
                                          />
                                        </FormErrorMessage>
                                      </FormControl>
                                    )}
                                  </Field>

                                  <Field
                                    key={`${index}-lock`}
                                    name={`Probes.${index}.IsLockName`}
                                  >
                                    {({ field }) => (
                                      <FormSingleSwitch
                                        {...field}
                                        id={`Probes.${index}.IsLockName`}
                                      ></FormSingleSwitch>
                                    )}
                                  </Field>
                                  <Field
                                    key={`${index}-remove`}
                                    name={`Probes.${index}.IsAllowRemoveProbe`}
                                  >
                                    {({ field }) => (
                                      <FormSingleSwitch
                                        {...field}
                                        isDisabled={
                                          !form.values.IsAllowRemoveProbes
                                        }
                                        id={`Probes.${index}.IsAllowRemoveProbe`}
                                      ></FormSingleSwitch>
                                    )}
                                  </Field>
                                  <Box>
                                    <IconButton
                                      key={`${index}-delete`}
                                      variant="outline"
                                      size="md"
                                      aria-label="remove email"
                                      onClick={() => arrayHelpers.remove(index)}
                                      icon={<Icon as={TrashIcon}></Icon>}
                                    />
                                  </Box>
                                </SimpleGrid>
                              );
                            })}

                          <Box>
                            <Button
                              onClick={() =>
                                arrayHelpers.push({
                                  Name: '',
                                  IsLockName: false,
                                  IsAllowRemoveProbe: true,
                                })
                              }
                              variant="outline"
                              size="sm"
                              leftIcon={<Icon as={PlusIcon}></Icon>}
                            >
                              Add choice
                            </Button>
                          </Box>
                        </Stack>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          typeof form.errors.Probes === 'string' &&
                          form.touched.Probes
                        }
                      >
                        <FormErrorMessage>
                          {form.errors.Probes}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  )}
                ></FieldArray>
              </Stack>
            </PropertyStack>
          )}

          {/* <PropertyStack
            label="View status"
            description="Set this to publish for users to start using it, else it will be hidden from the user as draft."
          >
            <FormSingleSwitch name="IsActive">
              <Text textStyle="switchLabel">Publish</Text>
            </FormSingleSwitch>
          </PropertyStack> */}
        </Stack>
      </Stack>
    </>
  );
};
