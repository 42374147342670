import { Box, BoxProps, Image } from '@chakra-ui/react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { FunctionComponent } from 'react';
import LightLogoMark from '../assets/art/dark/logo_mark.png';
import { useTheme } from '../providers/ThemeProvider';
import GuardrailsMessagesBank from '../routes/guardrails/components/GuardrailsMessagesBank';
import GuardrailsPolicies from '../routes/guardrails/components/GuardrailsPolicies';
import GuardrailsQuestionsBank from '../routes/guardrails/components/GuardrailsQuestionsBank';
import GuardrailsRouting from '../routes/guardrails/components/GuardrailsRouting';
import { GuardrailsSidebar } from '../routes/guardrails/components/GuardrailsSidebar';
import { PageContainer } from './PageContainer';
import { PageContent } from './PageContent';
import { PageSidebar } from './PageSidebar';

const GuardrailsLayout = () => {
  const theme = useTheme();
  const opacity = theme.useColorModeValue(0.8, 0.1);

  return (
    <PageContainer>
      <PageSidebar overflow={'hidden'} position="relative">
        <GuardrailsSidebar />
        <Box
          pos="absolute"
          bottom={{ base: '1%', lg: '-20' }}
          left={{ base: '-20', lg: '-20' }}
          transform="rotate(7deg)"
          rounded={'sm'}
          className="group"
          overflow="hidden"
          opacity={opacity}
        >
          <Image
            w="48"
            objectFit="contain"
            src={LightLogoMark}
            transition="all 0.2s"
            _groupHover={{ transform: 'scale(1.01)' }}
          />
        </Box>
      </PageSidebar>
      <PageContent>
        {/* Routes */}
        <Box as={Router} primary={false}>
          <Redirect default from="/" to="policies" noThrow />
          <Route path="/policies" component={GuardrailsPolicies} />
          <Route path="/questions" component={GuardrailsQuestionsBank} />
          <Route path="/messages" component={GuardrailsMessagesBank} />
          <Route path="/routing" component={GuardrailsRouting} />
        </Box>
      </PageContent>
    </PageContainer>
  );
};

export default GuardrailsLayout;

const Route: FunctionComponent<
  {
    component: React.ComponentType<any>;
  } & RouteComponentProps &
    BoxProps
> = ({ component: Component, ...rest }) => {
  return <Component {...rest} />;
};
