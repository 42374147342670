import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import AppAuthenticated from './AppAuthenticated';
import AppUnauthenticated from './AppUnauthenticated';
import { LibFullPageSpinner } from './components/lib';
import { useAuth } from './providers/AuthProvider';

// const AuthenticatedApp = React.lazy(
//   () => import(/* webpackPrefetch: true */ "./authenticated-app")
// );

// TODO: some weird things going on with styles not loading because they were
// not loaded during the unauth page, and the refresh never happened
// to rigger the static files to come down again.
// const AuthenticatedApp = React.lazy(() => import("./AuthenticatedApp"));
// const UnauthenticatedApp = React.lazy(() => import("./UnauthenticatedApp"));

const App = (props: RouteComponentProps) => {
  const { user, isLoadingProfile } = useAuth();

  // Has user
  if (user && user.username) {
    if (isLoadingProfile) {
      return <LibFullPageSpinner />;
    } else {
      return (
        <div>
          <React.Suspense fallback={<LibFullPageSpinner />}>
            <AppAuthenticated></AppAuthenticated>
          </React.Suspense>
        </div>
      );
    }
  }

  // No user
  return (
    <div>
      <React.Suspense fallback={<LibFullPageSpinner />}>
        <AppUnauthenticated></AppUnauthenticated>
      </React.Suspense>
    </div>
  );
};

export default App;
