import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import AlertCard from '../../../../components/shared/AlertCard';
import TextMessagePeview from '../../../../components/shared/TextMessagePeview';
import { useAuth } from '../../../../providers/AuthProvider';
import { useTheme } from '../../../../providers/ThemeProvider';
import {
  IMessage,
  IMessageUpdate,
  IMessageWorkingForm,
  initialMessageForm,
} from '../../models/Message';
import { MessageFormGroup } from './MessageFormGroup';

interface IProps {
  message?: IMessage | null;
  actionText?: 'Add message' | 'Update message';
  onSubmit: (data: IMessageUpdate) => Promise<void>;
  onCancel: () => void;
}

export const MessageForm = ({
  message,
  onSubmit,
  onCancel,
  actionText,
}: IProps) => {
  const [workingFormValues, set_workingFormValues] =
    React.useState<IMessageWorkingForm>(message || initialMessageForm);

  const { userProfile } = useAuth();
  const theme = useTheme();

  const MessageFormsSchema = Yup.object().shape({
    MessageTitle: Yup.string().required('Required'),
    MessageBody: Yup.string()
      .max(300, 'Max of 300 characters please') // max is 300 to allow for some name expansion
      .required(),
  });

  const handleOnSubmit = async (
    values,
    actions: FormikHelpers<IMessageWorkingForm>
  ) => {
    actions.setStatus();
    actions.setSubmitting(true);

    try {
      // Pass up logic
      await onSubmit(values);
    } catch (error) {
      // Error submitting form, set status for dispaly
      actions.setStatus('Failed to update message. Please try again.');
    }

    actions.setSubmitting(false);
  };

  React.useEffect(() => {
    if (message) {
      // Moving naming keys to match form
      set_workingFormValues(message);
    }
  }, [message]);

  return (
    <Formik
      initialValues={workingFormValues}
      validationSchema={MessageFormsSchema}
      onSubmit={(values, actions) => {
        handleOnSubmit(values, actions);
      }}
      enableReinitialize={true}
    >
      {props => {
        return (
          <FormikForm>
            <Stack spacing="8">
              <HStack spacing={8} alignItems={'flex-start'}>
                <MessageFormGroup form={props} />
                <VStack spacing={6} justifyContent={'center'} flex={1}>
                  <Text color={theme.color.base2} textStyle="description">
                    Message Preview
                  </Text>
                  <Box>
                    <TextMessagePeview
                      textBody={props.values.MessageBody || ''}
                      fromAvatarUrl={`${userProfile?.BrandImageUrl}`}
                      fromName={`${userProfile.FirstName} ${userProfile.LastName}`}
                    ></TextMessagePeview>
                  </Box>
                </VStack>
              </HStack>

              <Stack spacing={4}>
                <ButtonGroup>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    isDisabled={!props.dirty}
                    isLoading={props.isSubmitting}
                  >
                    {actionText}
                  </Button>
                  <Button
                    variant="outline"
                    onClick={onCancel}
                    isDisabled={props.isSubmitting}
                  >
                    Close
                  </Button>
                </ButtonGroup>
                {/* Status of form (shows API errors) */}
                {props.status && (
                  <Box>
                    <AlertCard
                      title="Failed to save"
                      type="error"
                      variant="fill"
                      //   action="Contact support"
                      //   actionTo="/app/support"
                      icon={ExclamationCircleIcon}
                    >
                      <span>
                        Not working as it should! If you keep seeing this,
                        please message support.
                      </span>
                    </AlertCard>
                  </Box>
                )}
              </Stack>
            </Stack>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
