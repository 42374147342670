// global constants

export const REGEX_PHONE = /^\d{10}$/;
// Check out validating for real at: https://github.com/abhisekp/yup-phone

export const REGEX_HEXCOLOR = /^#([a-fA-F0-9]{6})$/;
export const REGEX_PHONE_SEARCH_PATTERN = /^([A-Z0-9*]{3,10})$/;
export const REGEX_AREA_CODE = /^([0-9]{3})$/;
export const REGEX_TWO_DIGIT_STATE_CODE = /^([A-Z]{2})$/;
export const COMPLIANCE_MESSAGE_TEXT = "Reply STOP to unsubscribe";
export const REGEX_COMPLIANCE_MESSAGE_TEXT = /Reply STOP to unsubscribe/;
export const PAGE_SIZE_OPTIONS = [10, 25, 100];

export const LIST_COLORS = [
  // // top Row
  // '#374151', '#B91C1C', '#047857', '#1D4ED8', '#4338CA',
  // // bottom row
  // '#6B7280', '#EF4444', '#10B981', '#3B82F6', '#6366F1'

  // "#F9FAFB",
  // "#FEF2F2",
  // "#FFFBEB",
  // "#ECFDF5",
  // "#EFF6FF",
  // "#EEF2FF",
  // "#F5F3FF",
  // "#FDF2F8",

  // "#F3F4F6",
  // "#FEE2E2",
  // "#FEF3C7",
  // "#D1FAE5",
  // "#DBEAFE",
  // "#E0E7FF",
  // "#EDE9FE",
  // "#FCE7F3",

  // "#E5E7EB",
  // "#FECACA",
  // "#FDE68A",
  // "#A7F3D0",
  // "#BFDBFE",
  // "#C7D2FE",
  // "#DDD6FE",
  // "#FBCFE8",

  // "#D1D5DB",
  // "#FCA5A5",
  // "#FCD34D",
  // "#6EE7B7",
  // "#93C5FD",
  // "#A5B4FC",
  // "#C4B5FD",
  // "#F9A8D4",

  // "#9CA3AF",
  // "#F87171",
  // "#FBBF24",
  // "#34D399",
  // "#60A5FA",
  // "#818CF8",
  // "#A78BFA",
  // "#F472B6",

  "#6B7280",
  "#EF4444",
  "#F59E0B",
  "#10B981",
  "#3B82F6",
  "#6366F1",
  "#8B5CF6",
  "#EC4899",

  "#4B5563",
  "#DC2626",
  "#D97706",
  "#059669",
  "#2563EB",
  "#4F46E5",
  "#7C3AED",
  "#DB2777",

  "#374151",
  "#B91C1C",
  "#B45309",
  "#047857",
  "#1D4ED8",
  "#4338CA",
  "#6D28D9",
  "#BE185D",

  "#1F2937",
  "#991B1B",
  "#92400E",
  "#065F46",
  "#1E40AF",
  "#3730A3",
  "#5B21B6",
  "#9D174D",

  "#111827",
  "#7F1D1D",
  "#78350F",
  "#064E3B",
  "#1E3A8A",
  "#312E81",
  "#4C1D95",
  "#831843",
];

export enum Routes {
  Dashboard = "/app/dashboard",
  AuthSignIn = "/auth/signin",
  AuthSignUp = "/auth/signup",
  AuthEmailConfirmation = "/auth/email-confirmation",
  AuthResendEmailConfirmation = "/auth/resend-email-confirmation",
  AuthResetPassword = "/auth/reset-password",
  AuthResetPasswordConfirmation = "/auth/reset-password-confirmation",
}
