import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Center,
  HStack,
  Icon,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { LinkIcon, OfficeBuildingIcon } from '@heroicons/react/outline';
import React from 'react';
import DialogContent from '../../../../../components/shared/DialogContent';
import { useTheme } from '../../../../../providers/ThemeProvider';
import useUpdateTemplateQuestionLink from '../../../../../services/api/templateQuestions/mutations/useUpdateTemplateQuestionLink';
import { IOrganizationLink } from '../../../../settings/partnerships/models/OrganizationLink';
import {
  IQuestion,
  ITemplateQuestionLinkWorkingForm,
} from '../../../models/Question';
import { QuestionTypeMultiSelectSmudge } from '../QuestionTypeMultiSelectSmudge';
import { QuestionTypeTextLongSmudge } from '../QuestionTypeTextLongSmudge';
import { QuestionTypeTextShortSmudge } from '../QuestionTypeTextShortSmudge';

const TemplateQuestionDialogLink = ({
  onClose,
  templateQuestion,
  organizationLink,
  isOpen = false,
}: {
  onClose: () => void;
  templateQuestion?: IQuestion | null;
  organizationLink?: IOrganizationLink | null;
  isOpen: boolean;
}) => {
  const cancelRef = React.useRef(null);
  const [smudgeElem, set_smudgeElem] = React.useState<React.ReactNode>();

  const toast = useToast();
  const theme = useTheme();

  const { mutateAsync: mutateAsyncLink, isLoading } =
    useUpdateTemplateQuestionLink();

  const smudgeLookup: Record<string, React.ReactNode> = React.useMemo(
    () => ({
      TEXT_LONG: <QuestionTypeTextLongSmudge />,
      TEXT_SHORT: <QuestionTypeTextShortSmudge />,
      MULTI_SELECT: <QuestionTypeMultiSelectSmudge />,
    }),
    []
  );

  const handleOnConfirm = async () => {
    if (organizationLink && templateQuestion) {
      const linkIn: ITemplateQuestionLinkWorkingForm = {
        LinkOrganizationId: organizationLink.OrganizationId,
      };

      try {
        await mutateAsyncLink({
          templateId: templateQuestion.TemplateQuestionId,
          updateData: linkIn,
        });

        toast({
          title: 'Message is linked',
          status: 'success',
          variant: 'subtle',
          duration: 2000,
          isClosable: true,
        });
        onClose();
      } catch (err) {
        toast({
          title: 'Failed to link',
          status: 'error',
          variant: 'subtle',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  React.useEffect(() => {
    if (templateQuestion?.QuestionType) {
      if (smudgeLookup[templateQuestion.QuestionType]) {
        set_smudgeElem(smudgeLookup[templateQuestion.QuestionType]);
      }
    }
  }, [templateQuestion]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size={'lg'}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody>
              <DialogContent
                title="Link Organization"
                description={`Please confirm you want to link this question with ${organizationLink?.OrganizationName}. Once you link the question, ${organizationLink?.OrganizationName} will need to approve it before it will be publishable to your organization.`}
                type="info"
                icon={LinkIcon}
              >
                <VStack>
                  <HStack>
                    <Center maxW={8}>
                      <Icon as={OfficeBuildingIcon}></Icon>
                    </Center>
                    <Text>{organizationLink?.OrganizationName}</Text>
                  </HStack>
                  <Text color={theme.color.base4} textStyle={'description'}>
                    linked to
                  </Text>
                  <HStack>
                    <Box maxW={8}>{smudgeElem}</Box>
                    <Text>{templateQuestion?.QuestionTitle}</Text>
                  </HStack>
                </VStack>
              </DialogContent>
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup size="sm" spacing={4}>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="primary"
                  // isLoading={false}
                  loadingText="Removing"
                  onClick={handleOnConfirm}
                >
                  Link
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default TemplateQuestionDialogLink;
