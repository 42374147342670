import { Badge, Stack } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { IUserProfile } from '../../../../models/UserProfile';
import { useAuth } from '../../../../providers/AuthProvider';
import { useReadGuardrails } from '../../../../services/api/guardrails/queries/useReadGuardrails';

export const CellOrgPolicy = ({ row }: CellProps<IUserProfile>) => {
  const [colorScheme, set_colorScheme] = React.useState('gray');

  const [valStatus, set_valStatus] = React.useState<string>('');

  const { userProfile } = useAuth();
  const { data: dataGuardrails } = useReadGuardrails();
  const myOrganizationId = userProfile.OrganizationId;

  React.useEffect(() => {
    if (
      userProfile.OrganizationId &&
      row?.original.Governance &&
      dataGuardrails
    ) {
      let colorSchemeUpdate = 'gray';

      const goveranceObj = row.original.Governance[myOrganizationId];

      set_valStatus(`${goveranceObj.OrganizationGuardrails?.Version || '?'}`);

      if (
        dataGuardrails.Version !== goveranceObj.OrganizationGuardrails?.Version
      ) {
        colorSchemeUpdate = 'red';
      }

      set_colorScheme(colorSchemeUpdate);
    }
  }, [userProfile, row, dataGuardrails]);

  return (
    <Stack direction="row" spacing="4" align="center">
      <Badge fontSize="xs" colorScheme={colorScheme}>
        v. {valStatus}
      </Badge>
    </Stack>
  );
};
