import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { produce } from 'immer';
import * as React from 'react';
import { BsSearch } from 'react-icons/bs';
import { useAsyncDebounce } from 'react-table';
import { useTheme } from '../../../../providers/ThemeProvider';
import { IMessagesTableResource } from '../../hooks/useMessagesTable';
import { IMessage } from '../../models/Message';
import { MESSAGE_PUBLISH_ID } from './MessagesColumns';
import { TemplateMessageModalAdd } from './modals/TemplateMessageModalAdd';

const GlobalFilter = ({ preFilterCount, globalFilter, setGlobalFilter }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  React.useEffect(() => {
    if (!globalFilter) {
      setValue('');
    }
  }, [globalFilter]);

  return (
    <FormControl minW={{ md: '320px' }} id="search">
      <InputGroup size="sm">
        <FormLabel srOnly>Filter records</FormLabel>
        <InputLeftElement pointerEvents="none" color={theme.color.base3}>
          <BsSearch />
        </InputLeftElement>
        <Input
          value={value || ''}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          type="search"
          placeholder={`Filter ${preFilterCount} records...`}
        />
      </InputGroup>
    </FormControl>
  );
};

interface IProps {
  table: IMessagesTableResource;
}
export const MessagesTableActions = ({ table }: IProps) => {
  const [addMessageInitial, set_addMessageInitial] =
    React.useState<IMessage | null>(null);
  const [filterPublishValue, set_filterPublishValue] =
    React.useState<string>('');

  const {
    isOpen: isOpenModal,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();

  const handleOnCloseModal = () => {
    table.setDuplicateMessage(null);
    set_addMessageInitial(null);
    onCloseModal();
  };

  React.useEffect(() => {
    if (!table.state.filters.length) {
      set_filterPublishValue('');
    }

    if (table.duplicateMessage && !isOpenModal) {
      const addMessageInitialUpdate = produce(table.duplicateMessage, draft => {
        draft.MessageTitle = `Copy of ${table?.duplicateMessage?.MessageTitle}`;
      });

      set_addMessageInitial(addMessageInitialUpdate);

      onOpenModal();
    }
  }, [
    table?.state?.filters,
    table?.duplicateMessage,
    isOpenModal,
    onOpenModal,
    set_addMessageInitial,
  ]);

  return (
    <>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <HStack>
          <GlobalFilter
            preFilterCount={table.preGlobalFilteredRows.length}
            globalFilter={table.state.globalFilter}
            setGlobalFilter={table.setGlobalFilter}
          />

          <Select
            // w={{ base: '400px', md: 'unset' }}
            rounded="base"
            size="sm"
            value={filterPublishValue}
            placeholder="All statuses"
            onChange={e => {
              const updatedFilter = e.target.value;
              set_filterPublishValue(updatedFilter);

              if (updatedFilter === 'isPublished') {
                table.setFilter(MESSAGE_PUBLISH_ID, true);
              } else if (updatedFilter === 'isDraft') {
                table.setFilter(MESSAGE_PUBLISH_ID, false);
              } else {
                table.setFilter(MESSAGE_PUBLISH_ID, null);
              }
            }}
          >
            <option value={'isDraft'}>Draft</option>
            <option value={'isPublished'}>Published</option>
          </Select>
        </HStack>

        <ButtonGroup size="sm" variant="outline">
          <Button
            onClick={onOpenModal}
            iconSpacing="1"
            leftIcon={<Icon as={PlusIcon} />}
          >
            New message
          </Button>
          {/* <Button
          iconSpacing="1"
          leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
        >
          Export CSV
        </Button> */}
        </ButtonGroup>
      </Stack>
      <TemplateMessageModalAdd
        isOpen={isOpenModal}
        onClose={handleOnCloseModal}
        initialValues={addMessageInitial}
      ></TemplateMessageModalAdd>
    </>
  );
};
