// import { ReactQueryConfigProvider } from "react-query";
import { Amplify } from 'aws-amplify';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import appConfig from '../app-config';
import { LibFullPageErrorFallback } from '../components/lib';
import {
  AppTrackEventsEnum,
  AppTrackProvider,
  useAppTrack,
} from './AppTrackProvider';
import { AuthProvider } from './AuthProvider';
import { ReactQueryProvider } from './ReactQueryProvider';
import { ThemeProvider } from './ThemeProvider';

Amplify.configure(appConfig);

const BaseProvidersErrorFallback = ({ error }: { error: Error }) => {
  const { logEvent: appTrackLogEvent } = useAppTrack();

  appTrackLogEvent(AppTrackEventsEnum.APP_ERROR, {
    location: 'AppProviders',
    component: 'BaseProvidersErrorFallback',
    message: error?.message,
  });

  return <LibFullPageErrorFallback error={error}></LibFullPageErrorFallback>;
};

const BaseProvidersContainer = ({ children }: { children: any }) => {
  return (
    <ErrorBoundary FallbackComponent={BaseProvidersErrorFallback}>
      <ReactQueryProvider>
        <ThemeProvider>
          <AuthProvider>{children}</AuthProvider>
        </ThemeProvider>
      </ReactQueryProvider>
    </ErrorBoundary>
  );
};

const AppProviders = ({ children }: { children: any }) => {
  return (
    <ErrorBoundary FallbackComponent={LibFullPageErrorFallback}>
      <AppTrackProvider>
        <BaseProvidersContainer>{children}</BaseProvidersContainer>
      </AppTrackProvider>
    </ErrorBoundary>
  );
};

export default AppProviders;
