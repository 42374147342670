import { Box, Flex, HStack } from '@chakra-ui/react';
import React from 'react';
import { ColorModeSwitcher } from '../components/shared/ColorModeSwitcher';
import { Logo } from '../components/shared/Logo';
// import SideNav from "../components/shell/SideNav";
import { LayoutStoreProvider } from '../hooks/layout/useLayoutStore';
import { useMobileMenuState } from '../hooks/layout/useMobileMenuState';
import { useTheme } from '../providers/ThemeProvider';
import { MobileHamburgerMenu } from './MobileHamburgerMenu';
import { NavMenu } from './NavMenu';
import { ProfileDropdown } from './ProfileDropdown';

export const AppLayout = ({ children }) => {
  const theme = useTheme();
  const { isMenuOpen, toggle } = useMobileMenuState();

  return (
    <LayoutStoreProvider>
      <Flex direction="column" bg={theme.bg.base} h="100vh">
        {/* Top Bar */}
        <Flex
          align="center"
          bg={theme.bg.nav}
          borderBottomWidth="1px"
          borderColor={theme.bg.navBorder}
          px={theme.px.container}
          minH="16"
          flex="none"
        >
          <Flex justify="space-between" align="center" w="full">
            <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
            <NavMenu.Mobile isOpen={isMenuOpen} toggle={toggle} />

            {/* Desktop Logo placement */}
            <Box
              display={{ base: 'none', md: 'block' }}
              flexShrink={0}
              h={8}
              marginEnd="10"
            >
              <Logo />
            </Box>

            {/* Desktop Navigation Menu */}
            <NavMenu.Desktop />

            {/* Mobile Logo placement */}
            <Box
              flex={{ base: '1', md: '0' }}
              display={{ md: 'none' }}
              flexShrink={0}
              h={8}
            >
              <Logo />
            </Box>

            <HStack spacing="3">
              <ColorModeSwitcher></ColorModeSwitcher>
              <ProfileDropdown />
            </HStack>
          </Flex>
        </Flex>

        {/* Route */}
        <Flex flex={1} overflow={'hidden'} direction={'column'}>
          {children}
        </Flex>
      </Flex>
    </LayoutStoreProvider>
  );
};

export default AppLayout;
