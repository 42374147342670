import { CellProps, Column, Row } from 'react-table';
import { IOrganizationLink } from '../../../settings/partnerships/models/OrganizationLink';
import { IMessage } from '../../models/Message';
import { CellMessageAction } from './cells/CellMessageAction';
import { CellMessageApprove } from './cells/CellMessageApprove';
import { CellMessageBody } from './cells/CellMessageBody';
import { CellMessageGovernance } from './cells/CellMessageGovernance';
import { CellMessageTime } from './cells/CellMessageTime';
import { CellMessageTitle } from './cells/CellMessageTitle';

export const MESSAGE_PUBLISH_ID = 'messageStatusColId';

interface IProps {
  onEditMessage: (row: Row<IMessage>) => void;
  onViewHistory: (row: Row<IMessage>) => void;
  onDeleteMessage: (row: Row<IMessage>) => void;
  onCopyMessage: (row: Row<IMessage>) => void;
  onLinkMessage: (
    row: Row<IMessage>,
    organizationLink: IOrganizationLink
  ) => void;
}

export const MessagesColumns = ({
  onEditMessage,
  onViewHistory,
  onDeleteMessage,
  onCopyMessage,
  onLinkMessage,
}: IProps) =>
  [
    {
      Header: 'Title',
      Cell: CellMessageTitle,
      accessor: 'MessageTitle',
      sortType: 'basic',
      width: 50,
    },
    {
      Header: 'Body',
      Cell: CellMessageBody,
      accessor: 'MessageBody',
      sortType: 'basic',
      width: 80,
    },
    // {
    //   Header: 'Description',
    //   accessor: 'MessageBody',
    //   sortType: 'basic',
    // },
    {
      Header: 'Created',
      accessor: 'CreatedTime',
      Cell: CellMessageTime,
      sortType: 'basic',
    },
    {
      Header: 'Governance',
      Cell: CellMessageGovernance,
    },
    {
      id: MESSAGE_PUBLISH_ID,
      Header: 'Approved',
      Cell: CellMessageApprove,
      filter: 'statusApprovedFilter',
    },
    {
      Header: 'Actions',
      width: 50,
      Cell: (props: CellProps<IMessage>) => {
        return (
          <CellMessageAction
            message={props.row.original}
            onEditMessage={() => onEditMessage(props.row)}
            onViewHistory={() => onViewHistory(props.row)}
            onCopyMessage={() => onCopyMessage(props.row)}
            onDeleteMessage={() => onDeleteMessage(props.row)}
            onLinkMessage={(organizationLink: IOrganizationLink) =>
              onLinkMessage(props.row, organizationLink)
            }
          />
        );
      },
    },
    // {
    //   Header: "Reported Status",
    //   minWidth: 70,
    //   width: 70,
    // },
  ] as Column<IMessage>[];
