/*
AlertCard
*/
import { Box } from '@chakra-ui/react';
import React from 'react';
import { useTheme } from '../../providers/ThemeProvider';
import { AlertContent, AlertContentProps } from './AlertContent';

export interface AlertCardProps extends AlertContentProps {
  variant?: 'outline' | 'fill';
}

export const AlertCard = (props: AlertCardProps) => {
  const [classStyles, set_classStyles] = React.useState<any>({
    bg: 'white',
  });

  const theme = useTheme();

  const stylesMapping = React.useMemo(() => {
    return {
      error: {
        fill: {
          classStyles: {
            bg: theme.bg.danger,
            color: theme.color.base,
            borderColor: theme.bg.danger1,
          },
        },
        outline: {
          classStyles: {
            color: theme.color.base,
            borderColor: theme.bg.danger,
          },
        },
      },
      info: {
        fill: {
          classStyles: {
            bg: theme.bg.primary,
            color: theme.color.base,
            borderColor: theme.bg.primary1,
          },
        },
        outline: {
          classStyles: {
            color: theme.color.base,
            borderColor: theme.bg.danger,
          },
        },
      },
      warning: {
        fill: {
          classStyles: {
            bg: 'orange.50',
            borderColor: 'orange.200',
          },
        },
        outline: {
          classStyles: {
            bg: 'white',
          },
        },
      },
      notice: {
        fill: {
          classStyles: {
            bg: 'indigo.50',
            borderColor: 'indigo.200',
          },
        },
        outline: {
          classStyles: {
            bg: 'white',
          },
        },
      },
    };
  }, [theme]);

  React.useEffect(() => {
    let classStylesUpdate: any = {
      bg: 'white',
    };

    const typeLookup = props.type ? props.type : 'info';
    const variantLookup = props.variant ? props.variant : 'outline';

    const targetStyle = stylesMapping[typeLookup][variantLookup];

    classStylesUpdate = targetStyle.classStyles;

    set_classStyles(classStylesUpdate);
  }, [props.type, props.variant]);

  return (
    <Box
      w="full"
      h="full"
      px={4}
      py={2}
      borderWidth="1px"
      rounded="sm"
      {...classStyles}
    >
      <AlertContent {...props}></AlertContent>
    </Box>
  );
};

export default AlertCard;
