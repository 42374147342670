import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import * as React from 'react';
import { BsSearch } from 'react-icons/bs';
import { TableInstance, useAsyncDebounce } from 'react-table';
import { useTheme } from '../../../../providers/ThemeProvider';
import { IRoutingRule } from '../../models/RoutingRule';
import { TemplateRoutingRuleModalAdd } from './modals/TemplateRoutingRuleModalAdd';
import { ROUTINGRULE_PUBLISH_ID } from './RoutingRulesColumns';

const GlobalFilter = ({ preFilterCount, globalFilter, setGlobalFilter }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  React.useEffect(() => {
    if (!globalFilter) {
      setValue('');
    }
  }, [globalFilter]);

  return (
    <FormControl minW={{ md: '320px' }} id="search">
      <InputGroup size="sm">
        <FormLabel srOnly>Filter records</FormLabel>
        <InputLeftElement pointerEvents="none" color={theme.color.base3}>
          <BsSearch />
        </InputLeftElement>
        <Input
          value={value || ''}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          type="search"
          placeholder={`Filter ${preFilterCount} records...`}
        />
      </InputGroup>
    </FormControl>
  );
};

interface IProps {
  table: TableInstance<IRoutingRule>;
}
export const RoutingRulesTableActions = ({ table }: IProps) => {
  const [filterPublishValue, set_filterPublishValue] =
    React.useState<string>('');

  const {
    isOpen: isOpenModal,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();

  React.useEffect(() => {
    if (!table.state.filters.length) {
      set_filterPublishValue('');
    }
  }, [table?.state?.filters]);

  return (
    <>
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <HStack>
          <GlobalFilter
            preFilterCount={table.preGlobalFilteredRows.length}
            globalFilter={table.state.globalFilter}
            setGlobalFilter={table.setGlobalFilter}
          />

          <Select
            // w={{ base: '400px', md: 'unset' }}
            rounded="base"
            size="sm"
            value={filterPublishValue}
            placeholder="All statuses"
            onChange={e => {
              const updatedFilter = e.target.value;
              set_filterPublishValue(updatedFilter);

              if (updatedFilter === 'isPublished') {
                table.setFilter(ROUTINGRULE_PUBLISH_ID, true);
              } else if (updatedFilter === 'isDraft') {
                table.setFilter(ROUTINGRULE_PUBLISH_ID, false);
              } else {
                table.setFilter(ROUTINGRULE_PUBLISH_ID, null);
              }
            }}
          >
            <option value={'isDraft'}>Draft</option>
            <option value={'isPublished'}>Published</option>
          </Select>
        </HStack>

        <ButtonGroup size="sm" variant="outline">
          <Button
            onClick={onOpenModal}
            iconSpacing="1"
            leftIcon={<Icon as={PlusIcon} />}
          >
            New routing rule
          </Button>
          {/* <Button
          iconSpacing="1"
          leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
        >
          Export CSV
        </Button> */}
        </ButtonGroup>
      </Stack>
      <TemplateRoutingRuleModalAdd
        isOpen={isOpenModal}
        onClose={onCloseModal}
      ></TemplateRoutingRuleModalAdd>
    </>
  );
};
