import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import {
  IMessage,
  IMessageWorkingForm,
} from '../../../../routes/guardrails/models/Message';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (
  templateId: string,
  updateData: IMessageWorkingForm
) => {
  const payload = {
    body: updateData,
  };

  return API.put('users', `/api/v1/templatemessages/${templateId}`, payload);
};

const useUpdateTemplateMessage = () => {
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation<
    IMessage,
    any,
    {
      templateId: string;
      updateData: IMessageWorkingForm;
    },
    {
      previousRecords: IMessage | any;
      updateData: IMessageWorkingForm;
    }
  >(
    ({ templateId, updateData }) => {
      return makeApiRequest(templateId, updateData);
    },
    {
      retry: 0,
      onMutate: async ({ templateId, updateData }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([QueryKeys.templateMessages]);

        // Snapshot the previous value
        const previousRecords = queryClient.getQueryData<IMessage[]>([
          QueryKeys.templateMessages,
        ]);

        const targetIdx = previousRecords?.findIndex(
          message => message.TemplateMessageId === templateId
        );

        if (targetIdx !== undefined && targetIdx > -1 && previousRecords) {
          previousRecords[targetIdx] = {
            ...previousRecords[targetIdx],
            ...updateData,
          };
        }

        const updatedRecords = previousRecords ? [...previousRecords] : [];

        // Optimistically update to the new value
        queryClient.setQueryData([QueryKeys.templateMessages], updatedRecords);

        // Return a context object with the snapshotted value
        return { previousRecords, updateData };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, newTodo, context) => {
        if (context) {
          queryClient.setQueryData(
            [QueryKeys.templateMessages],
            context.previousRecords
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.templateMessages], {
          exact: false,
        });
      },
    }
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

export default useUpdateTemplateMessage;
