import { Box, BoxProps } from '@chakra-ui/react';
import { RouteComponentProps, Router } from '@reach/router';
import React, { FunctionComponent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import appConfig from './app-config';
import { LibFullPageErrorFallback } from './components/lib';
import AppLayout from './layouts/AppLayout';
import GuardrailsLayout from './layouts/GuardrailsLayout';
import OrganizationLayout from './layouts/OrganizationLayout';
import SettingsLayout from './layouts/SettingsLayout';
import { useAuth } from './providers/AuthProvider';
import Dashboard from './routes/dashboard/components/Dashboard';

const AppAuthenticated = () => {
  return (
    <ErrorBoundary FallbackComponent={LibFullPageErrorFallback}>
      <div>
        <main>
          <AppRoutes />
        </main>
      </div>
    </ErrorBoundary>
  );
};

const AppRoutes = () => {
  const { userProfile } = useAuth();

  // TODO: Is this the right way to integrate?
  // if (window) {
  //   (window as any).Intercom("update");
  // }

  React.useEffect(() => {
    if (userProfile) {
      // Startup intercom
      (window as any).Intercom('boot', {
        app_id: appConfig.Intercom.AppId,
        name: `${userProfile.FirstName} ${userProfile.LastName}`,
        email: userProfile.Email,
        created_at: userProfile.CreatedAt,
        user_id: userProfile.UserId,
        phone: userProfile.Phone,
        avatar: {
          type: 'avatar',
          image_url: userProfile.BrandImageUrl,
        },
        company: {
          company_id: userProfile.OrganizationId,
          name: userProfile.Email,
        },
      });
    }
  }, [userProfile]);

  return (
    <AppLayout>
      <Box as={Router} basepath="/" className="ROUTER" h="full" w="full">
        <Route default path="app/dashboard" component={Dashboard} />
        <Route path="app/organization/*" component={OrganizationLayout} />
        <Route path="app/guardrails/*" component={GuardrailsLayout} />
        <Route path="app/settings/*" component={SettingsLayout} />
      </Box>
    </AppLayout>
  );
};

const Route: FunctionComponent<
  { component: React.ComponentType } & RouteComponentProps & BoxProps
> = ({ component: Component, ...rest }) => <Component {...rest} />;

export default AppAuthenticated;
