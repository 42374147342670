import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { ITemplateMessageLinkWorkingForm } from '../../../../routes/guardrails/models/Message';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (
  templateId: string,
  updateData: ITemplateMessageLinkWorkingForm
) => {
  const payload = {
    body: updateData,
  };

  return API.post(
    'users',
    `/api/v1/templatemessages/${templateId}/link`,
    payload
  );
};

const useUpdateTemplateMessageLink = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ITemplateMessageLinkWorkingForm,
    any,
    {
      templateId: string;
      updateData: ITemplateMessageLinkWorkingForm;
    }
  >(
    ({ templateId, updateData }) => {
      return makeApiRequest(templateId, updateData);
    },
    {
      retry: 0,
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.templateMessages], {
          exact: false,
        });
      },
    }
  );
};

export default useUpdateTemplateMessageLink;
