import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import LongTextPNG from '../../../../assets/qtype_text_long.png';

interface IProps {}

export const QuestionTypeTextLongSmudge = ({}: IProps) => {
  return (
    <Box h={8} bg={'amber.200'} rounded="sm" overflow={'hidden'} p={1}>
      <Image h="full" objectFit={'contain'} src={LongTextPNG}></Image>
    </Box>
  );
};
