import {
  Badge,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import { DuplicateIcon } from '@heroicons/react/outline';
import React from 'react';
import { CellProps } from 'react-table';
import { runFormatPhoneSimple } from '../../../../hooks/useFormatPhone';
import { IUserProfile } from '../../../../models/UserProfile';

export const CellOrgPhone = ({ row }: CellProps<IUserProfile>) => {
  const [userPhoneValue, set_userPhoneValue] = React.useState('');
  const toast = useToast();
  const { onCopy: onCopyNew } = useClipboard(userPhoneValue);

  const handleOnCopyNew = () => {
    onCopyNew();
    toast({
      title: 'Copied',
      description: userPhoneValue,
      status: 'success',
      variant: 'subtle',
      duration: 2000,
      isClosable: true,
    });
  };

  React.useEffect(() => {
    if (row?.original?.RegisteredNumber?.PhoneNumber) {
      set_userPhoneValue(row?.original?.RegisteredNumber?.PhoneNumber);
    }
  }, [row]);

  return (
    <Stack direction="row" spacing="4" align="center">
      {!userPhoneValue && <Badge colorScheme={'gray'}>No Phone</Badge>}
      {userPhoneValue && (
        <HStack>
          <IconButton
            size="xs"
            aria-label="copy phone number"
            onClick={handleOnCopyNew}
            icon={<Icon as={DuplicateIcon}></Icon>}
          ></IconButton>
          <Text fontSize="sm" fontWeight="medium">
            {runFormatPhoneSimple(row.original.RegisteredNumber?.PhoneNumber)}
          </Text>
        </HStack>
      )}
    </Stack>
  );
};
