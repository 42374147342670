import { HStack, Icon, Link, LinkProps, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import * as React from 'react';
import { useTheme } from '../providers/ThemeProvider';

interface SupportNavLinkProps extends LinkProps {
  isLayoutOpen?: boolean | null;
}

export const SupportNavLink = (props: SupportNavLinkProps) => {
  const theme = useTheme();

  const { isLayoutOpen } = props;
  return (
    <Link
      display="block"
      py="2"
      px="2"
      whiteSpace="nowrap"
      transition="all 0.3s"
      rounded="sm"
      isExternal
      userSelect="none"
      color={theme.color.base2}
      _hover={{
        bg: theme.bg.base1,
      }}
      href="https://www.youtube.com/channel/UCOCC_XWXvT-z3WZZ9RDigGg/playlists"
    >
      <HStack spacing="4">
        <Icon as={ExternalLinkIcon} fontSize="lg" opacity={0.64} />
        <Text textStyle={'navLink'} as="span">
          Support Center
        </Text>
      </HStack>
    </Link>
  );
};
