import { Center, CenterProps } from '@chakra-ui/react';
import React from 'react';
import { useTheme } from '../../../../providers/ThemeProvider';
import {
  IFrequencyConfig,
  RoutingRuleFrequencyEnum,
  ROUTING_RULE_FREQUENCY_CONFIG,
} from '../../models/RoutingRule';

interface IProps extends CenterProps {
  frequency: RoutingRuleFrequencyEnum;
}

export const RoutingRuleFrequencySmudge = ({ frequency, ...props }: IProps) => {
  const [configLookup, set_configLookup] =
    React.useState<null | IFrequencyConfig>(null);

  const theme = useTheme();

  React.useEffect(() => {
    if (frequency && ROUTING_RULE_FREQUENCY_CONFIG[frequency]) {
      set_configLookup(ROUTING_RULE_FREQUENCY_CONFIG[frequency]);
    }
  }, [frequency]);

  return (
    <Center
      h={8}
      w={8}
      bg={configLookup?.bgColor}
      rounded="sm"
      overflow={'hidden'}
      p={1}
      color={theme.bg.dark}
      {...props}
    >
      {configLookup?.icon}
    </Center>
  );
};
