import {
  Box,
  Button,
  Grid,
  GridItem,
  Icon,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { MailIcon } from '@heroicons/react/outline';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {
  FormInputControl,
  FormPinInputControl,
} from '../../../components/forms';
import { MutedLink } from '../../../components/shared/MutedLink';
import { Routes } from '../../../constants';
import { useTheme } from '../../../providers/ThemeProvider';

interface IProps {
  handleFormSubmit: (username: string, code: string) => Promise<void>;
  username: string;
}

const AuthEmailConfirmForm = ({ handleFormSubmit, username }: IProps) => {
  const theme = useTheme();
  const AuthEmailConfirmFormSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is required'),
    code: Yup.string()
      .matches(/^[0-9]+$/, {
        message: 'Only numbers',
        excludeEmptyString: true,
      })
      .length(6, 'Code is 6 characters')
      .required('Required'),
  });

  const handleAuthEmailConfirmFormSubmit = async (
    values,
    actions: FormikHelpers<{
      email: string;
      code: string;
    }>
  ) => {
    try {
      // Get the values from the form
      const username = values.email;
      const code = values.code;

      // Pass up logic
      await handleFormSubmit(username, code);
    } catch (error: any) {
      console.log('Error confirming email:', error);

      if ((error.key && error.key === 'email') || error.key === 'code') {
        actions.setFieldError(error.key, error.status);
      } else {
        actions.setStatus(error.status);
      }
    }

    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        email: username,
        code: '',
      }}
      validationSchema={AuthEmailConfirmFormSchema}
      onSubmit={(values, actions) => {
        handleAuthEmailConfirmFormSubmit(values, actions);
      }}
    >
      {({ isSubmitting, errors, touched, status }) => (
        <Form>
          <Stack spacing={6}>
            <Grid templateColumns="repeat(6, 1fr)" gap={{ base: 4, lg: 6 }}>
              <GridItem colSpan={6}>
                <FormInputControl
                  name="email"
                  label="Email"
                  inputLeftElement={
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon color="gray.400" as={MailIcon} />}
                    />
                  }
                />
              </GridItem>
              <GridItem colSpan={6}>
                <FormPinInputControl
                  name="code"
                  label="Code"
                  pinLength={6}
                  stackProps={{ justifyContent: 'left' }}
                />
                <Box mt={2} mb={8}>
                  <MutedLink
                    to={Routes.AuthResendEmailConfirmation}
                    label="Need a new confirmation code?"
                  ></MutedLink>
                </Box>
              </GridItem>
            </Grid>

            {/* Submit */}
            <Button
              colorScheme="primary"
              isFullWidth
              type="submit"
              isDisabled={isSubmitting}
            >
              Confirm Email
            </Button>

            {/* Display error */}
            {errors && status ? (
              <Text
                textStyle="indicator"
                mt={2}
                color={theme.color.danger}
                id="password-error"
              >
                {status}
              </Text>
            ) : null}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AuthEmailConfirmForm;
