import { Flex, FlexProps } from '@chakra-ui/react';
import * as React from 'react';
import { useTheme } from '../providers/ThemeProvider';

export const PageContainer = (props: FlexProps) => {
  const theme = useTheme();

  return (
    <Flex
      h="full"
      w="full"
      bgGradient={theme.useColorModeValue(
        'linear(to-b, white, white)',
        'linear(to-bl, slate.900, slate.800)'
      )}
      {...props}
    ></Flex>
  );
};
