import {
  Button,
  Grid,
  GridItem,
  Icon,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { KeyIcon, MailIcon } from '@heroicons/react/outline';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {
  FormInputControl,
  FormPasswordControl,
} from '../../../components/forms';
import { useTheme } from '../../../providers/ThemeProvider';

interface IProps {
  username: string;
  handleFormSubmit: (username: string, password: string) => Promise<void>;
}

const AuthSignInForm = ({ username, handleFormSubmit }: IProps) => {
  const theme = useTheme();

  const AuthSignInFormSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Required'),
    password: Yup.string().required('Required'),
  });

  const handleAuthSignInFormSubmit = async (
    values,
    actions: FormikHelpers<{
      email: string;
      password: string;
    }>
  ) => {
    try {
      // Get the values from the form
      const username = values.email;
      const password = values.password;

      // Pass up logic
      await handleFormSubmit(username, password);
    } catch (error: any) {
      console.log('Error signing in:', error);

      if ((error.key && error.key === 'email') || error.key === 'password') {
        actions.setFieldError(error.key, error.status);
      } else {
        actions.setStatus(error.status);
      }
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: username || '',
        password: '',
      }}
      validationSchema={AuthSignInFormSchema}
      onSubmit={(values, actions) => {
        handleAuthSignInFormSubmit(values, actions);
      }}
    >
      {({ isSubmitting, errors, status }) => (
        <Form>
          <Stack spacing={6}>
            <Grid templateColumns="repeat(6, 1fr)" gap={{ base: 4, lg: 6 }}>
              <GridItem colSpan={6}>
                <FormInputControl
                  name="email"
                  label="Email"
                  type="email"
                  autoComplete="email"
                  inputMode="email"
                  inputLeftElement={
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon color="gray.400" as={MailIcon} />}
                    />
                  }
                />
              </GridItem>
              <GridItem colSpan={6}>
                <FormPasswordControl
                  name="password"
                  label="Password"
                  autoComplete="current-password"
                  inputMode="text"
                  inputLeftElement={
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon color="gray.400" as={KeyIcon} />}
                    />
                  }
                />
              </GridItem>
            </Grid>

            <Button
              colorScheme={'primary'}
              type="submit"
              isDisabled={isSubmitting}
              isFullWidth
            >
              Sign in
            </Button>

            {/* Display error */}
            {errors && status ? (
              <Text
                textStyle="indicator"
                mt={2}
                color={theme.color.danger}
                id="password-error"
              >
                {status}
              </Text>
            ) : null}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AuthSignInForm;
