import { Box, Stack, Text } from '@chakra-ui/react';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';
import React from 'react';
import { MutedLink } from '../../../components/shared/MutedLink';
import { Routes } from '../../../constants';
import AuthEmailConfirmForm from '../forms/AuthEmailConfirmForm';

export interface EmailConfirmError {
  key?: string;
  status: string;
}

const AuthEmailConfirm = props => {
  let prefillUsername = '';
  if (props.location?.state?.username) {
    prefillUsername = props.location?.state?.username;
  }

  const handleFormSubmit = async (username: string, code: string) => {
    try {
      await Auth.confirmSignUp(username, code);

      let goToSignIn: string = Routes.AuthSignIn;

      navigate(goToSignIn, { state: { username } });
    } catch (error: any) {
      // Parse cognito responses
      let err: EmailConfirmError = {
        status: 'Confirmation failed',
      };
      if (error.code === 'UserNotFoundException') {
        err = {
          key: 'email',
          status: 'Email not found',
        };
      }

      throw err;
    }
  };

  return (
    <Box>
      {/* Form title */}
      <Text textStyle="headline">Confirm Email</Text>
      <Text fontSize="sm">Check your email to get the confirmation code</Text>

      {/* Form */}
      <Stack spacing={6} mt={6}>
        {/* EmailConfirm Form */}
        <AuthEmailConfirmForm
          username={prefillUsername}
          handleFormSubmit={handleFormSubmit}
        ></AuthEmailConfirmForm>

        {/* Actions */}
        <Stack>
          <MutedLink
            to={Routes.AuthSignIn}
            label="back to sign in"
            leftIcon={ArrowNarrowLeftIcon}
          ></MutedLink>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AuthEmailConfirm;
