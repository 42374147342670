import {
  Box,
  Center,
  HStack,
  Icon,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  SortAscendingIcon,
  SortDescendingIcon,
  SwitchVerticalIcon,
} from '@heroicons/react/outline';
import * as React from 'react';
import { TableInstance } from 'react-table';
import { EmptySearchTableStub } from '../../../../components/shared/EmptySearchTableStub';
import { LoadingTableStub } from '../../../../components/shared/LoadingTableStub';
import { NoDataTableStub } from '../../../../components/shared/NoDataTableStub';
import { useTheme } from '../../../../providers/ThemeProvider';
import { IRoutingRule } from '../../models/RoutingRule';

interface IProps {
  table: TableInstance<IRoutingRule> & { isLoadingData: boolean };
}
export const RoutingRulesTableContent = ({ table }: IProps) => {
  const theme = useTheme();

  return (
    <Stack>
      <Table
        borderWidth="1px"
        borderColor={theme.bg.base2}
        {...table.getTableProps()}
      >
        <Thead
          bg={theme.bg.base1}
          borderBottomWidth={'1px'}
          borderColor={theme.bg.navBorder}
        >
          {
            // Loop over the header rows
            table.headerGroups.map(headerGroup => (
              // Apply the header row props
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers?.map((column, index) => {
                  return (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      scope="col"
                      key={index}
                    >
                      <HStack spacing={4} alignItems={'center'}>
                        <Box>{column.render('Header')}</Box>
                        <Center space={4}>
                          {column.isSorted && column.isSortedDesc && (
                            <Icon
                              color={theme.color.accent}
                              fontSize={'lg'}
                              as={SortDescendingIcon}
                            />
                          )}
                          {column.isSorted && !column.isSortedDesc && (
                            <Icon
                              color={theme.color.accent}
                              fontSize={'lg'}
                              as={SortAscendingIcon}
                            />
                          )}
                          {!column.isSorted && column.canSort && (
                            <Icon
                              color={theme.color.base4}
                              fontSize={'lg'}
                              as={SwitchVerticalIcon}
                            />
                          )}
                        </Center>
                      </HStack>
                    </Th>
                  );
                })}
              </Tr>
            ))
          }
        </Thead>

        <Tbody {...table.getTableBodyProps()}>
          {
            // Loop over the table rows
            table.page.map(row => {
              // Prepare the row for display
              table.prepareRow(row);
              return (
                // Apply the row props

                <Tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map(cell => {
                      // Apply the cell props
                      return (
                        <Td {...cell.getCellProps()} whiteSpace="nowrap">
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </Td>
                      );
                    })
                  }
                </Tr>
              );
            })
          }
        </Tbody>
      </Table>
      {table.isLoadingData && <LoadingTableStub />}
      {!table.isLoadingData &&
        (table.state.filters.length > 0 || table.state.globalFilter) &&
        !table.pageCount && (
          <EmptySearchTableStub
            currentSearch={
              table.state.globalFilter ||
              table.state.filters.map(({ id, value }) => value)
            }
            clearSearch={() => {
              table.setGlobalFilter('');
              table.setAllFilters([]);
            }}
          />
        )}
      {!table.isLoadingData &&
        table.state.filters.length === 0 &&
        !table.state.globalFilter &&
        !table.pageCount && <NoDataTableStub />}
    </Stack>
  );
};
