import { Icon } from '@chakra-ui/react';
import {
  CalendarIcon,
  ChatAlt2Icon,
  ClipboardListIcon,
  ClockIcon,
  LightningBoltIcon,
  MailIcon,
  PuzzleIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';
import React from 'react';
import { IPaginatedResponse } from '../../../models/PaginatedResponse';

export interface IConnectorMetaEmail {
  EmailsTo: string[];
  EmailsCc: string[];
}

export interface IConnectorMetaSmarsh {
  SmarshChannelToken: string;
}

export enum RoutingRuleConnectorTypeEnum {
  EMAIL = 'EMAIL',
  SMARSH = 'SMARSH',
  PROOFPOINT = 'PROOFPOINT',
  GLOBALRELAY = 'GLOBALRELAY',
  INTRADYN = 'INTRADYN',
}

export enum RoutingRuleEventNameEnum {
  MESSAGE_ALL = 'MESSAGE_ALL',
  NEW_SUBMISSION = 'NEW_SUBMISSION',
  NEW_AUDIT = 'NEW_AUDIT',
}

export enum RoutingRuleFrequencyEnum {
  STREAM = 'STREAM',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  // WEEKLY = 'WEEKLY',
}

export interface IEventNameConfig {
  label: string;
  bgColor: string;
  description: string;
  icon?: React.ReactElement;
}
export interface IFrequencyConfig extends IEventNameConfig {}
export interface IConnectorConfig {
  label: string;
  bgColor: string;
  description: string;
  logoUrl?: string;
  icon?: React.ReactElement;
}

export const ROUTING_RULE_EVENT_CONNECTOR_CONFIG: {
  [RoutingRuleConnectorTypeEnum: string]: IConnectorConfig;
} = {
  [RoutingRuleConnectorTypeEnum.EMAIL]: {
    label: 'Email',
    bgColor: 'lime.200',
    description: 'Provide emails address as the destination',
    icon: <Icon as={MailIcon} />,
  },
  [RoutingRuleConnectorTypeEnum.PROOFPOINT]: {
    label: 'Proofpoint',
    bgColor: 'blue.700',
    description: 'Provide your proofpoint journal destination address',
    logoUrl:
      'https://cc-west-prd-bucket-users.s3.us-west-2.amazonaws.com/public/general/media/proofpoint_logo.png',
    icon: <Icon as={PuzzleIcon} />,
  },
  [RoutingRuleConnectorTypeEnum.SMARSH]: {
    label: 'Smarsh',
    bgColor: 'inherit',
    description: 'Provide your smarsh authorized channel token',
    logoUrl:
      'https://cc-west-prd-bucket-users.s3.us-west-2.amazonaws.com/public/general/media/smarsh_logo.png',
    icon: <Icon as={PuzzleIcon} />,
  },
  [RoutingRuleConnectorTypeEnum.GLOBALRELAY]: {
    label: 'Global Relay',
    bgColor: 'blue.700',
    description: 'Provide your Global Relay journal destination address',
    logoUrl:
      'https://cc-west-prd-bucket-users.s3.us-west-2.amazonaws.com/public/general/media/globalrelay_logo.png',
    icon: <Icon as={PuzzleIcon} />,
  },
  [RoutingRuleConnectorTypeEnum.INTRADYN]: {
    label: 'Global Relay',
    bgColor: 'blue.700',
    description: 'Provide your Intradyn journal destination address',
    logoUrl:
      'https://cc-west-prd-bucket-users.s3.us-west-2.amazonaws.com/public/general/media/intradyn_logo.png',
    icon: <Icon as={PuzzleIcon} />,
  },
};

export const ROUTING_RULE_EVENT_NAME_CONFIG: {
  [RoutingRuleEventNameEnum: string]: IEventNameConfig;
} = {
  [RoutingRuleEventNameEnum.MESSAGE_ALL]: {
    label: 'All sms',
    bgColor: 'rose.200',
    description: 'When texts are sent and received',
    icon: <Icon as={ChatAlt2Icon} />,
  },
  [RoutingRuleEventNameEnum.NEW_AUDIT]: {
    label: 'Client Data Change',
    bgColor: 'cyan.200',
    description: 'When a client updates their info',
    icon: <Icon as={ShieldCheckIcon} />,
  },
  [RoutingRuleEventNameEnum.NEW_SUBMISSION]: {
    label: 'New submission',
    bgColor: 'indigo.200',
    description: 'When a client submits a form',
    icon: <Icon as={ClipboardListIcon} />,
  },
};

export const ROUTING_RULE_FREQUENCY_CONFIG: {
  [RoutingRuleFrequencyEnum: string]: IFrequencyConfig;
} = {
  [RoutingRuleFrequencyEnum.STREAM]: {
    label: 'Realtime',
    bgColor: 'blue.200',
    description: 'in realtime',
    icon: <Icon as={LightningBoltIcon} />,
  },
  [RoutingRuleFrequencyEnum.HOURLY]: {
    label: 'Hourly',
    bgColor: 'green.200',
    description: 'hourly with a summary',
    icon: <Icon as={ClockIcon} />,
  },
  [RoutingRuleFrequencyEnum.DAILY]: {
    label: 'Daily',
    bgColor: 'orange.200',
    description: 'once a day summary',
    icon: <Icon as={CalendarIcon} />,
  },
  // [RoutingRuleFrequencyEnum.WEEKLY]: {
  //   label: 'Weekly',
  //   bg: 'orange.500',
  //   description: 'once a week with summary',
  //   icon: <Icon as={ArchiveIcon} />,
  // },
};

export interface IRoutingRuleMold {
  templateRoutingRuleId?: string;
  routingRuleName: string;
  eventName: RoutingRuleEventNameEnum;
  connectorType: RoutingRuleConnectorTypeEnum;
  frequency: RoutingRuleFrequencyEnum;
  isEnabled: boolean;
  emailsTo?: string[];
  emailsCc?: string[];
  smarshChannelToken?: string;
}

export const initialRoutingRuleMoldForm: IRoutingRuleMold = {
  routingRuleName: '',
  eventName: RoutingRuleEventNameEnum.MESSAGE_ALL,
  connectorType: RoutingRuleConnectorTypeEnum.EMAIL,
  frequency: RoutingRuleFrequencyEnum.STREAM,
  isEnabled: false,
  emailsTo: [],
  emailsCc: [],
  smarshChannelToken: '',
};

export interface IRoutingRuleUpdate {
  RoutingRuleName: string;
  EventName: RoutingRuleEventNameEnum;
  ConnectorType: RoutingRuleConnectorTypeEnum;
  ConnectorMeta: IConnectorMetaSmarsh | IConnectorMetaEmail;
  Frequency: RoutingRuleFrequencyEnum;
  IsEnabled: boolean;
}

export interface IRoutingRule extends IRoutingRuleUpdate {
  TemplateRoutingRuleId: string;
  OrganizationId: string;
  CreatedByUserId: string;
  UpdatedAt?: string;
  CreatedTime: string;
}

export interface IPaginatedRoutingRules extends IPaginatedResponse {
  records: IRoutingRule[];
}

export const marshallRoutingRuleToMold = (
  rule: IRoutingRule
): IRoutingRuleMold => {
  let meta = rule.ConnectorMeta as any;

  return {
    templateRoutingRuleId: rule.TemplateRoutingRuleId || String(Date.now()),
    routingRuleName: rule.RoutingRuleName,
    eventName: rule.EventName,
    connectorType: rule.ConnectorType,
    frequency: rule.Frequency,
    isEnabled: rule.IsEnabled,
    emailsTo: meta?.EmailsTo,
    emailsCc: meta?.EmailsCc,
    smarshChannelToken: meta?.SmarshChannelToken,
  };
};

export interface IPaginatedRoutingRules extends IPaginatedResponse {
  records: IRoutingRule[];
}

export const marshallRoutingMoldToCreateApi = (
  mold: IRoutingRuleMold
): IRoutingRuleUpdate => {
  let metaEmail: IConnectorMetaEmail = {
    EmailsTo: mold.emailsTo as string[],
    EmailsCc: mold.emailsCc as string[],
  };

  let metaSmarsh: IConnectorMetaSmarsh = {
    SmarshChannelToken: mold.smarshChannelToken as string,
  };

  const metaToUse =
    mold.connectorType === RoutingRuleConnectorTypeEnum.EMAIL
      ? metaEmail
      : metaSmarsh;

  return {
    RoutingRuleName: mold.routingRuleName,
    EventName: mold.eventName,
    ConnectorType: mold.connectorType,
    ConnectorMeta: metaToUse,
    Frequency: mold.frequency,
    IsEnabled: mold.isEnabled,
  };
};
