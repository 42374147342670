import { Box, Divider, Stack, StackProps, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTheme } from '../../providers/ThemeProvider';

interface PropertyGroupProps extends StackProps {
  label?: string;
  level?: number;
  description?: string;
}

export const PropertyGroup = (props: PropertyGroupProps) => {
  const theme = useTheme();
  const { label, description, level = 0, children, ...flexProps } = props;
  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      w="full"
      spacing={{ base: '4', lg: '16', xl: '24' }}
      pt={level * 4}
      {...flexProps}
    >
      <Stack
        pl={level * 16}
        minW={{ base: 'inherit', md: 'xs', lg: 'sm', xl: 'md' }}
        maxW={'lg'}
        w="full"
        spacing={0}
        position={'relative'}
      >
        {level > 0 && (
          <Divider
            position={'absolute'}
            left={0}
            top="50%"
            w={level * 16 - 4}
          ></Divider>
        )}
        {label && (
          <Text textStyle="formLabel" flexShrink={0}>
            {label}
          </Text>
        )}
        {description && (
          <Text color={theme.color.base3} textStyle="description">
            {description}
          </Text>
        )}
      </Stack>
      <Box pl={{ base: level * 16, md: 0 }} flex={1}>
        {children}
      </Box>
    </Stack>
  );
};
