import { Badge, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useTheme } from '../../../providers/ThemeProvider';
import { IOrganizationEvent } from '../../organization/models/OrganizationEvent';

interface IProps {
  organizationEvent?: IOrganizationEvent;
}

export const OrganizationEventRecordMetaMessage = ({ meta }: { meta: any }) => {
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      <HStack whiteSpace={'pre-wrap'}>
        <Text textStyle={'description'}>Message:</Text>
        <Text isTruncated={true} maxW={'32rem'}>
          {meta.MessageBody}
        </Text>
      </HStack>
      <HStack>
        <Text textStyle={'description'}>Status:</Text>
        <Badge>{meta.IsApproved ? 'Approved' : 'Not Approved'}</Badge>
      </HStack>
    </Stack>
  );
};
export const OrganizationEventRecordMetaQuestion = ({
  meta,
}: {
  meta: any;
}) => {
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      <HStack whiteSpace={'pre-wrap'}>
        <Text textStyle={'description'}>Question:</Text>
        <Text isTruncated={true} maxW={'32rem'}>
          {meta.QuestionTitle}
        </Text>
      </HStack>
      <HStack>
        <Text textStyle={'description'}>Status:</Text>
        <Badge>{meta.IsApproved ? 'Approved' : 'Not Approved'}</Badge>
      </HStack>
    </Stack>
  );
};

export const OrganizationEventRecordMetaUser = ({ meta }: { meta: any }) => {
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      <HStack>
        <Text textStyle={'description'}>User:</Text>
        <Text isTruncated={true} maxW={'32rem'}>
          {meta.Name}
        </Text>
      </HStack>
      <HStack>
        <Text textStyle={'description'}>Status:</Text>
        <Badge>{meta.IsApproved ? 'Approved' : 'Not Approved'}</Badge>
      </HStack>
    </Stack>
  );
};

export const OrganizationEventRecordMeta = ({ organizationEvent }: IProps) => {
  const theme = useTheme();
  const [smudgeElem, set_smudgeElem] = React.useState<React.ReactNode>();

  const smudgeLookup: Record<string, React.ReactNode> = React.useMemo(
    () => ({
      TEMPLATEMESSAGE: (
        <OrganizationEventRecordMetaMessage meta={organizationEvent?.Meta} />
      ),
      TEMPLATEQUESTION: (
        <OrganizationEventRecordMetaQuestion meta={organizationEvent?.Meta} />
      ),
      USER: <OrganizationEventRecordMetaUser meta={organizationEvent?.Meta} />,
    }),
    [organizationEvent]
  );

  React.useEffect(() => {
    if (organizationEvent?.EventRecordType && organizationEvent?.Meta) {
      if (smudgeLookup[organizationEvent?.EventRecordType]) {
        set_smudgeElem(smudgeLookup[organizationEvent?.EventRecordType]);
      }
    } else {
      set_smudgeElem(<></>);
    }
  }, [organizationEvent]);

  return (
    <Stack spacing={8}>
      {smudgeElem}

      {!organizationEvent?.Meta && <Text>No meta</Text>}
    </Stack>
  );
};
