import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { IGuardrails } from '../../../../routes/guardrails/models/Guardrails';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (): Promise<IGuardrails> => {
  const qParams = {};

  return API.get('users', '/api/v1/guardrails/', qParams);
};

export const useReadGuardrails = () => {
  return useQuery<IGuardrails>([QueryKeys.guardrails], () => makeApiRequest(), {
    retry: 2,
  });
};
