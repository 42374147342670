import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { IOrganizationUserApprovalWorkingForm } from '../../../../routes/organization/models/Organization';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (
  userId: string,
  updateData: IOrganizationUserApprovalWorkingForm
) => {
  const payload = {
    body: updateData,
  };

  return API.post(
    'users',
    `/api/v1/organizations/users/${userId}/approved`,
    payload
  );
};

const useUpdateOrganizationUserApproval = () => {
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation<
    IOrganizationUserApprovalWorkingForm,
    any,
    {
      userId: string;
      updateData: IOrganizationUserApprovalWorkingForm;
    }
  >(
    ({ userId, updateData }) => {
      return makeApiRequest(userId, updateData);
    },
    {
      retry: 0,
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.organizations], {
          exact: false,
        });
      },
    }
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

export default useUpdateOrganizationUserApproval;
