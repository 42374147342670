import React, { useMemo } from 'react';
import {
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { PAGE_SIZE_OPTIONS } from '../../../constants';
import { useListOrganizationEvents } from '../../../services/api/organization/queries/useListOrganizationEvents';
import {
  COL_ID_EVENT_TYPE,
  OrganizationEventsColumns,
} from '../components/organizationEvents/OrganizationEventsColumns';
import { IOrganizationEvent } from '../models/OrganizationEvent';

export const useOrganizationEventsTable = () => {
  const filterTypes = React.useMemo(
    () => ({
      globalFilter: (
        rows: Row<IOrganizationEvent>[],
        columnIds: string[],
        filterValue: string
      ) => {
        return rows.filter(row => {
          let rowValue = `${row.original.EventName} ${
            row.original.EventUserName
          } ${row.original.EventRecordType?.toLowerCase()}`;

          if (row.original.EventRecordType === 'TEMPLATEQUESTION') {
            rowValue = `${rowValue} ${row.original.Meta?.QuestionTitle}`;
          } else if (row.original.EventRecordType === 'TEMPLATEMESSAGE') {
            rowValue = `${rowValue} ${row.original.Meta?.MessageBody}`;
          } else if (row.original.EventRecordType === 'USER') {
            rowValue = `${rowValue} ${row.original.Meta?.Name}`;
          }

          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
      filterEventType: (
        rows: Row<IOrganizationEvent>[],
        columnIds: string[],
        filterValue: string
      ) => {
        return rows.filter(row => {
          const rowValue = `${row.original.EventRecordType}`;

          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    data: tableData,
    isError: isErrorData,
    isLoading: isLoadingData,
  } = useListOrganizationEvents();

  let data = useMemo(() => tableData?.records ?? [], [tableData]);

  const columns = React.useMemo(() => OrganizationEventsColumns({}), []);

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
    }),
    []
  );

  const table = useTable<IOrganizationEvent>(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: PAGE_SIZE_OPTIONS[0],
        hiddenColumns: [COL_ID_EVENT_TYPE],
      },
      globalFilter: 'globalFilter',
      filterTypes: filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
    // useRowSelect,
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  return { ...table, isLoadingData, isErrorData };
};

export default useOrganizationEventsTable;
