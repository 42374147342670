// Format dates

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat

import {
  addMinutes,
  format,
  formatDistanceToNow,
  fromUnixTime,
  isDate,
  isFuture,
  parseISO,
} from "date-fns";

export const useFormatDate = (dateString: string = "") => {
  let formattedDate = "";

  // parseISO(format(fieldValue, "yyy-MM-dd"));

  if (dateString) {
    try {
      const dateObj = parseISO(dateString);
      formattedDate = format(dateObj, "PP");
    } catch (e) {}
  }

  return formattedDate;
};

export const runFormatDate = (dateString: string = "") => {
  let formattedDate = "";

  if (dateString) {
    try {
      const dateObj = parseISO(dateString);
      formattedDate = format(dateObj, "PP");
    } catch (e) {}
  }

  return formattedDate;
};

export const runConvertAndFormatSchoolDate = (
  dateString: string = ""
): string => {
  let formattedDate = "";

  try {
    const tempDateObj = new Date(dateString);

    // https://github.com/date-fns/date-fns/issues/1401#issuecomment-578580199
    let thisDate = format(
      addMinutes(tempDateObj, tempDateObj.getTimezoneOffset()),
      "yyyy-MM-dd"
    );

    let realDate = parseISO(thisDate);

    if (isDate(realDate) && !isFuture(realDate)) {
      formattedDate = format(realDate, "MM/dd/yyyy");
    }
  } catch (e) {}

  return formattedDate;
};

export const runConvertToDate = (dateString: string = ""): Date | null => {
  let dateObj: Date | null = null;

  if (dateString) {
    try {
      dateObj = parseISO(dateString);
    } catch (e) {}
  }

  return dateObj;
};

export const useFormatDateShortDate = (dateString: string = ""): string => {
  let formattedDate = "";

  if (dateString) {
    try {
      const dateObj = parseISO(dateString);
      formattedDate = format(dateObj, "MMM do");
    } catch (e) {}
  }
  return formattedDate;
};

export const useFormatDateShortTime = (dateString: string = ""): string => {
  let formattedDate = "";

  if (dateString) {
    try {
      const dateObj = parseISO(dateString);
      formattedDate = format(dateObj, "h:mm a");
    } catch (e) {}
  }
  return formattedDate;
};
export const useFormatDateShortDateTime = (dateString: string = ""): string => {
  let formattedDate = "";

  if (dateString) {
    try {
      const dateObj = parseISO(dateString);
      formattedDate = format(dateObj, "MMM do, h:mm a");
    } catch (e) {
      return "";
    }
  }
  return formattedDate;
};

export const useFormatDateWithAt = (dateString: string = ""): string => {
  let formattedDate = "";

  if (dateString) {
    const dateObj = parseISO(dateString);

    formattedDate = `${format(dateObj, "MMM do")} at ${format(
      dateObj,
      "h:mm a"
    )}`;
  }
  return formattedDate;
};

export const runFormatDateWithAt = (dateString: string = ""): string => {
  let formattedDate = "";

  try {
    if (dateString) {
      const dateObj = parseISO(dateString);

      formattedDate = `${format(dateObj, "MMM do")} at ${format(
        dateObj,
        "h:mm a"
      )}`;
    }
  } catch (e) {}
  return formattedDate;
};

export const runFormatTimeWithAt = (timeSeconds: string = ""): string => {
  let formattedDate = "";

  try {
    if (timeSeconds) {
      const unixTime = parseInt(timeSeconds);
      const dataObj = fromUnixTime(unixTime);

      formattedDate = `${format(dataObj, "MMM do")} at ${format(
        dataObj,
        "h:mm a"
      )}`;
    }
  } catch (e) {}
  return formattedDate;
};

export const runFormatDateToSimple = (dateObj: Date) => {
  let formattedDate = "";

  if (dateObj) {
    try {
      formattedDate = format(dateObj, "yyyy-MM-dd");
    } catch (e) {}
  }

  return formattedDate;
};

export const runFormatTimeFromNow = (timeSeconds: string): string => {
  let formattedDate = "";

  try {
    if (timeSeconds) {
      const unixTime = parseInt(timeSeconds);
      const momentObj = fromUnixTime(unixTime);
      formattedDate = formatDistanceToNow(momentObj);

      if (isFuture(momentObj)) {
        formattedDate = `in ${formatDistanceToNow(momentObj)}`;
      } else {
        formattedDate = `${formatDistanceToNow(momentObj)} ago`;
      }
    }
  } catch (e) {}
  return formattedDate;
};

export const runFormatDateFromNow = (dateValue: string): string => {
  let formattedDate = "";

  try {
    if (dateValue) {
      const dateObj = parseISO(dateValue);
      formattedDate = `${formatDistanceToNow(dateObj)} ago`;
    }
  } catch (e) {}
  return formattedDate;
};
