import { IOrganization } from '../../organization/models/Organization';

export enum QuestionTypeEnum {
  MULTI_SELECT = 'MULTI_SELECT',
  TEXT_LONG = 'TEXT_LONG',
  TEXT_SHORT = 'TEXT_SHORT',
}

export interface ITemplateQuestionGovernance {
  IsApproved?: boolean;
  OrganizationProfile?: IOrganization;
}

export interface IQuestionProbe {
  Name?: string;
  IsLockName?: boolean;
  IsAllowRemoveProbe?: boolean;
}

export interface IQuestionUpdate {
  QuestionTitle: string;
  QuestionType: QuestionTypeEnum;
  QuestionDescription: string;
  IsIncludeQuestionDescription: boolean;
  IsLockTitle?: boolean;
  IsLockDescription?: boolean;
  IsAllowRemoveProbes?: boolean;
  IsAllowAddProbes?: boolean;
  IsActive: boolean;
  Probes?: IQuestionProbe[];
}

export interface IQuestion extends IQuestionUpdate {
  Version?: number;
  TemplateQuestionId: string;
  OrganizationId: string;
  SecondaryOrganizationId: string;
  CreatedByUserId: string;
  UpdatedAt?: string;
  CreatedTime: string;
  QuestionId: string;
  Governance?: { [organizationId: string]: ITemplateQuestionGovernance };
  IsManaged?: boolean;
  IsProcessing?: boolean;
  IsDeleting?: boolean;
  IsLinked?: boolean;
}

// In the form, we change it to allow mode
export interface IQuestionWorkingForm extends Partial<IQuestionUpdate> {}

export const initialQuestionForm: IQuestionWorkingForm = {
  QuestionTitle: '',
  QuestionType: QuestionTypeEnum.TEXT_SHORT,
  QuestionDescription: '',
  IsIncludeQuestionDescription: true,
  IsLockTitle: true,
  IsLockDescription: true,
  IsAllowRemoveProbes: false,
  IsAllowAddProbes: false,
  Probes: [],
};

export interface ITemplateQuestionApprovalWorkingForm {
  IsApproved: boolean;
}

export interface ITemplateQuestionLinkWorkingForm {
  LinkOrganizationId: string;
}
