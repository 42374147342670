// Format phone

export const runFormatPhone = (phone: string = "") => {
  const cleaned = runFormatPhoneDigits(phone);
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const runFormatPhoneSimple = (phone: string = "") => {
  const cleaned = runFormatPhoneDigits(phone);
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const runFormatPhoneDigits = (phone: string = "") => {
  const cleaned = ("" + phone).replace(/\D/g, "");
  return cleaned;
};
