import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { IPaginatedUserProfiles } from '../../../../models/UserProfile';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (): Promise<IPaginatedUserProfiles> => {
  const qParams = {
    limit: 1000,
  };

  return API.get('users', '/api/v1/organizations/users', qParams);
};

export const useReadOrganizationUsers = () => {
  return useQuery<IPaginatedUserProfiles>(
    [QueryKeys.organizations, 'users'],
    () => makeApiRequest(),
    {
      retry: 1,
    }
  );
};
