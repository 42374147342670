import { CellProps, Column, Row } from 'react-table';
import { IOrganizationLink } from '../../../settings/partnerships/models/OrganizationLink';
import { IQuestion } from '../../models/Question';
import { CellQuestionAction } from './cells/CellQuestionAction';
import { CellQuestionApprove } from './cells/CellQuestionApprove';
import { CellQuestionGovernance } from './cells/CellQuestionGovernance';
import { CellQuestionTime } from './cells/CellQuestionTime';
import { CellQuestionTitle } from './cells/CellQuestionTitle';
import { CellQuestionType } from './cells/CellQuestionType';

export const QUESTION_TYPE_ID = 'questionTypeColId';
export const QUESTION_PUBLISH_ID = 'questionStatusColId';

interface IProps {
  onEditQuestion: (row: Row<IQuestion>) => void;
  onDeleteQuestion: (row: Row<IQuestion>) => void;
  onViewHistory: (row: Row<IQuestion>) => void;
  onCopyQuestion: (row: Row<IQuestion>) => void;
  onLinkQuestion: (
    row: Row<IQuestion>,
    organizationLink: IOrganizationLink
  ) => void;
}

export const QuestionsColumns = ({
  onEditQuestion,
  onViewHistory,
  onDeleteQuestion,
  onCopyQuestion,
  onLinkQuestion,
}: IProps) =>
  [
    {
      id: QUESTION_TYPE_ID,
      Header: 'Type',
      Cell: CellQuestionType,
      accessor: 'QuestionType',
      sortType: 'basic',
      width: 10,
    },
    {
      Header: 'Question',
      Cell: CellQuestionTitle,
      accessor: 'QuestionTitle',
      sortType: 'basic',
      width: 80,
    },
    // {
    //   Header: 'Description',
    //   accessor: 'QuestionDescription',
    //   sortType: 'basic',
    // },
    {
      Header: 'Created',
      accessor: 'CreatedTime',
      Cell: CellQuestionTime,
      sortType: 'basic',
    },
    {
      Header: 'Governance',
      Cell: CellQuestionGovernance,
    },
    {
      id: QUESTION_PUBLISH_ID,
      Header: 'Approved',
      Cell: CellQuestionApprove,
      filter: 'statusApprovedFilter',
    },
    {
      Header: 'Actions',
      width: 50,
      Cell: (props: CellProps<IQuestion>) => {
        return (
          <CellQuestionAction
            question={props.row.original}
            onViewHistory={() => onViewHistory(props.row)}
            onEditQuestion={() => onEditQuestion(props.row)}
            onDeleteQuestion={() => onDeleteQuestion(props.row)}
            onCopyQuestion={() => onCopyQuestion(props.row)}
            onLinkQuestion={(organizationLink: IOrganizationLink) =>
              onLinkQuestion(props.row, organizationLink)
            }
          />
        );
      },
    },
    // {
    //   Header: "Reported Status",
    //   minWidth: 70,
    //   width: 70,
    // },
  ] as Column<IQuestion>[];
