import { HStack, Switch, SwitchProps } from '@chakra-ui/react';
import { Field } from 'formik';
import React, { ReactNode } from 'react';
import { BaseProps } from '../baseProps';
import { FormControl } from '../FormControl';

export type SingleSwitchProps = BaseProps &
  SwitchProps & {
    children: ReactNode;
  };

export const SingleSwitch = (props: SingleSwitchProps) => {
  const { children, formControlProps, ...rest } = props;
  return (
    <FormControl {...formControlProps}>
      <HStack align={'center'}>
        <Switch mt={1} {...rest}></Switch>
        {children}
      </HStack>
    </FormControl>
  );
};

export type FormSingleSwitchProps = SingleSwitchProps;

export const FormSingleSwitch = (props: FormSingleSwitchProps) => {
  const { name, children, formControlProps, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <SingleSwitch
          {...rest}
          formControlProps={{
            isInvalid: meta.error && meta.touched,
            isChecked: field.value,
            ...meta,
            ...formControlProps,
          }}
          isInvalid={meta.error && meta.touched}
          isChecked={field.value}
          // {...meta}
          {...field}
        >
          {children}
        </SingleSwitch>
      )}
    </Field>
  );
};
