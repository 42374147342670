import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import {
  IOrganizationLink,
  IOrganizationLinkCreate,
} from '../../../../routes/settings/partnerships/models/OrganizationLink';

const makeApiRequest = (
  createData: IOrganizationLinkCreate
): Promise<IOrganizationLink> => {
  const postData = {
    body: createData,
  };

  return API.post('users', '/api/v1/organizationlinks/', postData);
};

const useCreateOrganizationLink = () => {
  return useMutation<IOrganizationLink, any, IOrganizationLinkCreate, unknown>(
    data => makeApiRequest(data),
    {
      // useErrorBoundary: true,
      retry: 1,
    }
  );
};

export default useCreateOrganizationLink;
