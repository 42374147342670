import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTheme } from '../../../../../providers/ThemeProvider';
import useUpdateTemplateMessage from '../../../../../services/api/templateMessages/mutations/useUpdateTemplateMessage';
import { MessageForm } from '../../../forms/messages/MessageForm';
import { IMessage, IMessageUpdate } from '../../../models/Message';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  message: IMessage | null;
}

export const TemplateMessageModalEdit = ({
  isOpen,
  onClose,
  message,
}: IProps) => {
  const theme = useTheme();

  const { mutateAsync: mutateAsyncUpdate } = useUpdateTemplateMessage();

  const handleSuccess = (dataIn: IMessage) => {
    handleFormClose();
  };

  const handleFormSubmit = async (data: IMessageUpdate) => {
    // Post data to api
    if (message) {
      try {
        await mutateAsyncUpdate(
          {
            templateId: message.TemplateMessageId,
            updateData: data,
          },
          {
            onSuccess: handleSuccess,
          }
        );
      } catch (err) {
        console.log(err);
        // Throw error for downstream form to handle
        throw err;
      }
    }
  };

  const handleFormClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={true}
      motionPreset="slideInBottom"
      size="6xl"
      colorScheme="primary"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack spacing={0}>
            <Text>Edit message</Text>
            <Text textStyle="description" color={theme.color.base3}>
              Make changes to this message and it will be updated from this
              point on, if a rep already used this message it will not be
              updated retroactively.
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={8}>
          <MessageForm
            message={message}
            onSubmit={handleFormSubmit}
            onCancel={handleFormClose}
            actionText={'Update message'}
          ></MessageForm>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
