import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { BadgeCheckIcon, PencilAltIcon } from '@heroicons/react/outline';
import { navigate } from '@reach/router';
import * as React from 'react';
import { useTheme } from '../../../providers/ThemeProvider';
import { useReadOrganization } from '../../../services/api/organization/queries/useReadOrganization';

export const OrganizationCard = () => {
  const theme = useTheme();
  const { data: dataOrganization } = useReadOrganization();

  const handleOnEditSettings = () => {
    navigate('/app/settings/organization');
  };

  return (
    <Flex
      position="relative"
      direction="column"
      alignItems="center"
      padding="12"
      cursor="pointer"
      onClick={handleOnEditSettings}
      role="group"
    >
      <Box pos="absolute" top="3" right="3">
        <Button
          _groupHover={{ transform: 'translateX(1px)' }}
          variant={'outline'}
          size="xs"
          leftIcon={<Icon as={PencilAltIcon}></Icon>}
        >
          Edit
        </Button>
      </Box>
      <Box
        bg={theme.bg.base}
        rounded={'sm'}
        className="group"
        h="120px"
        overflow="hidden"
      >
        <Image
          w="full"
          h="full"
          objectFit="contain"
          src={dataOrganization?.BrandImageUrl}
          transition="all 0.2s"
          _groupHover={{ transform: 'scale(1.01)' }}
        />
      </Box>

      <Stack mt={8} flex="1" spacing={0}>
        <HStack spacing="2">
          <Text textStyle="headline" textTransform={'capitalize'}>
            {dataOrganization?.OrganizationName}
          </Text>
          <Icon
            as={BadgeCheckIcon}
            fontSize="xl"
            color={theme.color.base3}
            verticalAlign="text-bottom"
          />
        </HStack>

        <Text textAlign={'center'} color={theme.color.base3}>
          {`${dataOrganization?.City || ''} ${dataOrganization?.State || ''}`}
        </Text>
      </Stack>
    </Flex>
  );
};
