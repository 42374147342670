import {
  RadioGroupProps,
  useRadioGroup,
  Wrap,
  WrapProps,
} from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import React, { ReactNode } from 'react';
import { BaseProps } from '../baseProps';
import { FormControl } from '../FormControl';

export type RadioCardGroupProps = BaseProps &
  Overwrite<RadioGroupProps, { name: string }> & {
    children: (getRadioProps: (props: any) => any) => ReactNode;
    wrapProps?: WrapProps;
    formHelperText?: ReactNode;
  };

export const RadioCardGroup = (props: RadioCardGroupProps) => {
  const {
    children,
    name,
    label,
    value,
    defaultValue,
    onChange,
    formControlProps,
    wrapProps,
    formHelperText,
    ...rest
  } = props;

  const { spacing = 4, ...wrapPropsRest } = wrapProps || {};

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue: value,
    onChange,
  });

  const group = getRootProps();

  return (
    <FormControl name={name} label={label} {...formControlProps}>
      <Wrap {...group} spacing={spacing} {...wrapPropsRest} {...rest}>
        {children(getRadioProps)}
      </Wrap>
      {formHelperText}
    </FormControl>
  );
};

export type FormRadioCardGroupProps = RadioCardGroupProps;

export const FormRadioCardGroup = (props: FormRadioCardGroupProps) => {
  const { name, children, formControlProps, ...rest } = props;
  const { setFieldValue, values } = useFormikContext();

  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <RadioCardGroup
            {...rest}
            formControlProps={{
              isInvalid: !!meta.error && meta.touched,
              ...meta,
              ...formControlProps,
            }}
            {...field}
            onChange={(value: string) => setFieldValue(name, value)}
          >
            {children}
          </RadioCardGroup>
        );
      }}
    </Field>
  );
};
