import { Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { IOrganizationEvent } from '../../models/OrganizationEvent';

export const CellEventUser = ({ row }: CellProps<IOrganizationEvent>) => {
  return (
    <Stack direction="row" spacing="4" align="center">
      <Box>
        <Box fontSize="sm" fontWeight="medium">
          {row.original.EventUserName}
        </Box>
        <Box fontSize="sm" color="gray.500">
          {row.original.EventUserEmail}
        </Box>
      </Box>
    </Stack>
  );
};
