import {
  Button,
  Grid,
  GridItem,
  Icon,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { MailIcon } from '@heroicons/react/outline';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { FormInputControl } from '../../../components/forms';
import { useTheme } from '../../../providers/ThemeProvider';

interface IProps {
  handleFormSubmit: (username: string) => Promise<void>;
}

const AuthResetPasswordForm = ({ handleFormSubmit }: IProps) => {
  const theme = useTheme();
  const AuthResetPasswordFormSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is required'),
  });

  const handleAuthResetPasswordFormSubmit = async (
    values: {
      email: string;
    },
    actions: FormikHelpers<{
      email: string;
    }>
  ) => {
    try {
      // Get the values from the form
      const username = values.email;

      // Pass up logic
      await handleFormSubmit(username);
    } catch (error: any) {
      console.log('Error ResetPassword:', error);

      if (error.key && error.key === 'email') {
        actions.setFieldError(error.key, error.status);
      } else {
        actions.setStatus(error.status);
      }
    }

    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={AuthResetPasswordFormSchema}
      onSubmit={(values, actions) => {
        handleAuthResetPasswordFormSubmit(values, actions);
      }}
    >
      {({ isSubmitting, errors, status }) => (
        <Form>
          <Stack spacing={6}>
            <Grid templateColumns="repeat(6, 1fr)" gap={{ base: 4, lg: 6 }}>
              <GridItem colSpan={6}>
                <FormInputControl
                  name="email"
                  label="Email"
                  type="email"
                  inputLeftElement={
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon color="gray.400" as={MailIcon} />}
                    />
                  }
                />
              </GridItem>
            </Grid>

            <Button
              colorScheme="primary"
              variant="outline"
              type="submit"
              isDisabled={isSubmitting}
              isFullWidth
            >
              Send reset link
            </Button>

            {/* Display error */}
            {errors && status ? (
              <Text
                textStyle="indicator"
                mt={2}
                color={theme.color.danger}
                id="password-error"
              >
                {status}
              </Text>
            ) : null}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AuthResetPasswordForm;
