import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { IOrganization } from '../../../../routes/organization/models/Organization';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (): Promise<IOrganization> => {
  const qParams = {};

  return API.get('users', '/api/v1/organizations/', qParams);
};

export const useReadOrganization = () => {
  return useQuery<IOrganization>(
    [QueryKeys.organizations],
    () => makeApiRequest(),
    {
      retry: 2,
    }
  );
};
