import { Box, Button, Heading, Icon, Stack, Text } from '@chakra-ui/react';
import { PencilAltIcon } from '@heroicons/react/outline';
import { navigate } from '@reach/router';
import * as React from 'react';
import { useAuth } from '../../../providers/AuthProvider';
import { useTheme } from '../../../providers/ThemeProvider';
import { useReadOrganization } from '../../../services/api/organization/queries/useReadOrganization';

export const WelcomeCard = () => {
  const theme = useTheme();
  const { userProfile } = useAuth();
  const { data: dataOrganization } = useReadOrganization();

  const handleOnEditSettings = () => {
    navigate('/app/settings/profile');
  };

  return (
    <Stack
      position="relative"
      direction={{ base: 'column', lg: 'row' }}
      alignItems="center"
      justifyContent={'center'}
      cursor="pointer"
      onClick={handleOnEditSettings}
      role="group"
      spacing={0}
      h={'full'}
      overflow={'hidden'}
    >
      <Box pos="absolute" top="1" right="3">
        <Button
          _groupHover={{ transform: 'translateX(1px)' }}
          variant={'outline'}
          size="xs"
          leftIcon={<Icon as={PencilAltIcon}></Icon>}
        >
          Edit
        </Button>
      </Box>

      <Stack p="12" flex={1}>
        <Stack spacing="0">
          <Text>✌️ Hey, welcome back!</Text>
          <Heading textTransform={'capitalize'}>
            {userProfile?.FirstName} {userProfile?.LastName}
          </Heading>
        </Stack>

        <Text color={theme.color.base3}>
          You are a governer over {dataOrganization?.OrganizationName}
        </Text>
      </Stack>
    </Stack>
  );
};
