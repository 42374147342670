import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import {
  runFormatTimeFromNow,
  runFormatTimeWithAt,
} from '../../../../hooks/useFormatDate';
import { useTheme } from '../../../../providers/ThemeProvider';
import { IOrganizationEvent } from '../../models/OrganizationEvent';

export const CellEventTime = ({ row }: CellProps<IOrganizationEvent>) => {
  const theme = useTheme();

  const timeIn = row.original.EventTime;

  const timeFromNow = runFormatTimeFromNow(timeIn);
  const timeAt = runFormatTimeWithAt(timeIn);
  return (
    <Stack spacing={0} h="full" justifyContent={'center'}>
      <Text color={theme.color.base} textStyle={'formLabel'}>
        {timeAt}
      </Text>
      <Text color={theme.color.base3} textStyle={'description'}>
        {timeFromNow}
      </Text>
    </Stack>
  );
};
