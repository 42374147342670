import { Box, Button, ButtonGroup, Stack } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import AlertCard from '../../../../components/shared/AlertCard';
import {
  initialQuestionForm,
  IQuestion,
  IQuestionUpdate,
  IQuestionWorkingForm,
  QuestionTypeEnum,
} from '../../models/Question';
import { QuestionFormGroup } from './QuestionFormGroup';

interface IProps {
  question?: IQuestion | null;
  actionText?: 'Add question' | 'Update question';
  onSubmit: (data: IQuestionUpdate) => Promise<void>;
  onCancel: () => void;
}

export const QuestionForm = ({
  question,
  onSubmit,
  onCancel,
  actionText,
}: IProps) => {
  const [workingFormValues, set_workingFormValues] =
    React.useState<IQuestionWorkingForm>(question || initialQuestionForm);

  const QuestionFormsSchema = Yup.object().shape({
    QuestionType: Yup.string()
      .oneOf([
        QuestionTypeEnum.MULTI_SELECT,
        QuestionTypeEnum.TEXT_LONG,
        QuestionTypeEnum.TEXT_SHORT,
      ])
      .required('Required'),
    QuestionTitle: Yup.string().required('Required'),
    IsLockTitle: Yup.bool().required('Required'),
    QuestionDescription: Yup.string(),
    IsIncludeQuestionDescription: Yup.bool().required('Required'),
    IsLockDescription: Yup.bool().required('Required'),
    Probes: Yup.array()
      .of(
        Yup.object().shape({
          Name: Yup.string().required('Required'),
          IsLockName: Yup.bool().required('Required'),
          IsAllowRemoveProbe: Yup.bool().required('Required'),
        })
      )
      .when('QuestionType', {
        is: val => val === QuestionTypeEnum.MULTI_SELECT,
        then: Yup.array()
          .min(2, 'Please include at least 2 options')
          .of(
            Yup.object().shape({
              Name: Yup.string().required('Required'),
              IsLockName: Yup.bool().required('Required'),
              IsAllowRemoveProbe: Yup.bool().required('Required'),
            })
          )
          .required('Required'),
      }),
  });

  const handleOnSubmit = async (
    values,
    actions: FormikHelpers<IQuestionWorkingForm>
  ) => {
    actions.setStatus();
    actions.setSubmitting(true);

    try {
      // Pass up logic
      await onSubmit(values);
    } catch (error) {
      // Error submitting form, set status for dispaly
      actions.setStatus('Failed to update question. Please try again.');
    }

    actions.setSubmitting(false);
  };

  React.useEffect(() => {
    if (question) {
      // Moving naming keys to match form
      set_workingFormValues(question);
    }
  }, [question]);

  return (
    <Formik
      initialValues={workingFormValues}
      validationSchema={QuestionFormsSchema}
      onSubmit={(values, actions) => {
        handleOnSubmit(values, actions);
      }}
      enableReinitialize={true}
    >
      {props => {
        return (
          <FormikForm>
            <Stack spacing="8">
              <QuestionFormGroup form={props} />

              <Stack spacing={4}>
                <ButtonGroup>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    isDisabled={!props.dirty}
                    isLoading={props.isSubmitting}
                  >
                    {actionText}
                  </Button>
                  <Button
                    variant="outline"
                    onClick={onCancel}
                    isDisabled={props.isSubmitting}
                  >
                    Close
                  </Button>
                </ButtonGroup>
                {/* Status of form (shows API errors) */}
                {props.status && (
                  <Box>
                    <AlertCard
                      title="Failed to save"
                      type="error"
                      variant="fill"
                      //   action="Contact support"
                      //   actionTo="/app/support"
                      icon={ExclamationCircleIcon}
                    >
                      <span>
                        Not working as it should! If you keep seeing this,
                        please message support.
                      </span>
                    </AlertCard>
                  </Box>
                )}
              </Stack>
            </Stack>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
