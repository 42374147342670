import { Stack, StackDivider, Text } from '@chakra-ui/react';
import { FormikProps, useFormikContext } from 'formik';
import React from 'react';
import { FormSingleSwitch } from '../../../components/forms';
import { FieldGroup } from '../../../components/forms/FieldGroup';
import { PropertyGroup } from '../../../components/forms/PropertyGroup';
import { useTheme } from '../../../providers/ThemeProvider';
import { IGuardrailsWorkingForm } from '../models/Guardrails';

const ValidateFormLogic = () => {
  // Grab values and submitForm from context
  const { values, setFieldValue } = useFormikContext<IGuardrailsWorkingForm>();

  React.useEffect(() => {
    if (!values.isAllowCampaign) {
      if (values.isAllowCampaignCustomDiscovery) {
        setFieldValue('isAllowCampaignCustomDiscovery', false);
      }
      if (values.isAllowCampaignCustomMessage) {
        setFieldValue('isAllowCampaignCustomMessage', false);
      }
    }
    if (!values.isAllowBroadcast) {
      if (values.isAllowBroadcastCustomMessage) {
        setFieldValue('isAllowBroadcastCustomMessage', false);
      }
    }
  }, [values, setFieldValue]);

  return null;
};

interface IProps {
  form: FormikProps<IGuardrailsWorkingForm>;
}

export const FeatureSettingsFormGroup = ({ form }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <ValidateFormLogic />
      <Stack spacing={4}>
        <FieldGroup
          title="Allowed Features"
          description="What features do you want to allow your organization to access?"
        >
          <Stack spacing="6" divider={<StackDivider />}>
            <PropertyGroup
              label="Ringless voicemail"
              description="Ringless voicemail allows users to record a short voice message and send it out to their clients."
            >
              <FormSingleSwitch name="isAllowRVM">
                <Text textStyle="switchLabel">Allow</Text>
              </FormSingleSwitch>
            </PropertyGroup>

            <Stack>
              <PropertyGroup
                label="Text broadcasts"
                description="Text broadcasts allows users to send the same messages to multiple users at once."
              >
                <FormSingleSwitch name="isAllowBroadcast">
                  <Text textStyle="switchLabel">Allow</Text>
                </FormSingleSwitch>
              </PropertyGroup>
              <PropertyGroup
                label="Custom broadcast messages"
                level={1}
                description="Users can build craft their own messages, if not they must use the organizations provided messages."
              >
                <FormSingleSwitch
                  name="isAllowBroadcastCustomMessage"
                  isDisabled={!form.values.isAllowBroadcast}
                >
                  <Text
                    textStyle="switchLabel"
                    color={
                      !form.values.isAllowBroadcast
                        ? theme.color.base3
                        : 'inherit'
                    }
                  >
                    Allow
                  </Text>
                </FormSingleSwitch>
              </PropertyGroup>
            </Stack>
            <Stack>
              <PropertyGroup
                label="Campaigns"
                description="Campaigns allow users to create a 'continuous discovery' form and send it out via text."
              >
                <FormSingleSwitch name="isAllowCampaign">
                  <Text textStyle="switchLabel">Allow</Text>
                </FormSingleSwitch>
              </PropertyGroup>
              <PropertyGroup
                label="Custom discovery form templates"
                level={1}
                description="Users can build their own forms and ask any questions they want, if not they must use the organizations provided templates."
              >
                <FormSingleSwitch
                  name="isAllowCampaignCustomDiscovery"
                  isDisabled={!form.values.isAllowCampaign}
                >
                  <Text
                    textStyle="switchLabel"
                    color={
                      !form.values.isAllowCampaign
                        ? theme.color.base3
                        : 'inherit'
                    }
                  >
                    Allow
                  </Text>
                </FormSingleSwitch>
              </PropertyGroup>
              <PropertyGroup
                label="Custom campaign messages"
                level={1}
                description="Users can build craft their own messages, if not they must use the organizations provided messages."
              >
                <FormSingleSwitch
                  name="isAllowCampaignCustomMessage"
                  isDisabled={!form.values.isAllowCampaign}
                >
                  <Text
                    textStyle="switchLabel"
                    color={
                      !form.values.isAllowCampaign
                        ? theme.color.base3
                        : 'inherit'
                    }
                  >
                    Allow
                  </Text>
                </FormSingleSwitch>
              </PropertyGroup>
            </Stack>
          </Stack>
        </FieldGroup>
      </Stack>
    </>
  );
};
