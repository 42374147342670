import { API } from 'aws-amplify';
import { useMutation } from 'react-query';
import { OrganizationUserInviteIn } from '../../../../routes/organization/models/Organization';

const makeApiRequest = (
  inviteIn: OrganizationUserInviteIn
): Promise<OrganizationUserInviteIn> => {
  const postData = {
    body: inviteIn,
  };

  return API.post('users', '/api/v1/organizations/users/invite', postData);
};

const useSendInviteUsers = () => {
  return useMutation<OrganizationUserInviteIn, any, any, unknown>(
    userSignup => makeApiRequest(userSignup),
    {
      retry: 0,
    }
  );
};

export default useSendInviteUsers;
