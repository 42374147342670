import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Icon,
  Stack,
  Text,
  useClipboard,
  useColorModeValue as mode,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { DuplicateIcon } from '@heroicons/react/outline';
import * as React from 'react';
import appConfig from '../../../app-config';
import { useReadOrganization } from '../../../services/api/organization/queries/useReadOrganization';
import { InviteOrgUserModal } from '../../organization/components/organizationUsers/InviteOrgUserModal';

export const InviteRep = () => {
  const [partnerShareLink, set_partnerShareLink] = React.useState('');

  const toast = useToast();

  const {
    isOpen: isOpenModal,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();

  const handleOnInvite = () => {
    onOpenModal();
  };

  // const doodleSvg = theme.useColorModeValue(DoodleSVGLight, DoodleSVGDark);
  const { data: dataOrganization } = useReadOrganization();

  const { onCopy: onCopyNew } = useClipboard(partnerShareLink);

  const handleOnCopyNew = () => {
    onCopyNew();
    toast({
      title: 'Copied',
      description: partnerShareLink,
      status: 'success',
      variant: 'subtle',
      duration: 2000,
      isClosable: true,
    });
  };

  React.useEffect(() => {
    if (dataOrganization?.OrganizationId) {
      set_partnerShareLink(
        `${appConfig.Organizations.baseInvite}${dataOrganization?.OrganizationId}`
      );
    }
  }, [dataOrganization]);

  return (
    <>
      <Box
        as="section"
        p="12"
        h="full"
        position={'relative'}
        overflow={'hidden'}
      >
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          justify="space-between"
          align="center"
          spacing="8"
        >
          <Box maxW="2xl">
            <Heading size="lg" fontWeight="extrabold" mb="2">
              Invite users to your organization
            </Heading>
            <Text color={mode('gray.600', 'gray.400')} mb="6">
              Enter a list of email addresses and we'll send them an invite to
              join your organization. They will receive a welcome and a unique
              link that will allow them to join your organization.
            </Text>
            <ButtonGroup spacing="4" size="sm">
              <Button onClick={handleOnInvite} colorScheme={'indigo'}>
                Send Invites
              </Button>
              <Button
                variant={'outline'}
                colorScheme="primary"
                onClick={handleOnCopyNew}
                leftIcon={<Icon as={DuplicateIcon}></Icon>}
              >
                or click to copy link
              </Button>
            </ButtonGroup>
          </Box>
        </Stack>
      </Box>

      <InviteOrgUserModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
      ></InviteOrgUserModal>
    </>
  );
};
