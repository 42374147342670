import React, { useMemo } from 'react';
import {
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { PAGE_SIZE_OPTIONS } from '../../../constants';
import { runFormatPhoneSimple } from '../../../hooks/useFormatPhone';
import { IUserProfile } from '../../../models/UserProfile';
import { useAuth } from '../../../providers/AuthProvider';
import { useReadOrganizationUsers } from '../../../services/api/organization/queries/useReadOrganizationUsers';
import { OrganizationUsersColumns } from '../components/organizationUsers/OrganizationUsersColumns';

interface IProps {}

export const useOrganizationUsersTable = () => {
  const { userProfile } = useAuth();
  const filterTypes = React.useMemo(
    () => ({
      globalFilter: (
        rows: Row<IUserProfile>[],
        columnIds: string[],
        filterValue: string
      ) => {
        return rows.filter(row => {
          const rowValue = `${row.original.FirstName} ${
            row.original.LastName
          } ${row.original.Email} ${row.original.CompanyName} ${
            row.original.Billing?.SubscriptionStatus
          } ${runFormatPhoneSimple(
            row.original.RegisteredNumber?.PhoneNumber
          )} ${row.original.RegisteredNumber?.PhoneNumber}`;

          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
      filterBillingStatus: (
        rows: Row<IUserProfile>[],
        columnIds: string[],
        filterValue: string
      ) => {
        return rows.filter(row => {
          const rowValue = `${row.original.Billing.SubscriptionStatus}`;

          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
      filterApprovalStatus: (
        rows: Row<IUserProfile>[],
        columnIds: string[],
        filterValue: string
      ) => {
        const targetOrganization = userProfile.OrganizationId;
        let isViewApprovedStatus = false;

        if (filterValue === '') {
          return rows;
        } else if (filterValue == 'approved') {
          isViewApprovedStatus = true;
        } else {
          isViewApprovedStatus = false;
        }

        return rows.filter(row => {
          let isApproved: boolean | undefined = true;

          if (
            row?.original?.Governance &&
            row?.original?.Governance[targetOrganization]
          ) {
            isApproved =
              row?.original?.Governance[targetOrganization]?.IsApproved;
          }

          return isViewApprovedStatus === isApproved;
        });
      },
    }),
    [userProfile]
  );

  const {
    data: tableData,
    isError: isErrorData,
    isLoading: isLoadingData,
    isFetching,
  } = useReadOrganizationUsers();

  let data = useMemo(() => tableData?.records ?? [], [tableData]);

  const columns = React.useMemo(() => OrganizationUsersColumns({}), []);

  const handleRowClick = (row: Row<IUserProfile>) => {
    row.toggleRowSelected();
  };

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
    }),
    []
  );

  const table = useTable<IUserProfile>(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: PAGE_SIZE_OPTIONS[0] },
      globalFilter: 'globalFilter',
      filterTypes: filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
    // useRowSelect,
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  return { ...table, isLoadingData, isErrorData };
};

export default useOrganizationUsersTable;
