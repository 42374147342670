import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { useTheme } from '../../../../../providers/ThemeProvider';
import {
  IRoutingRule,
  ROUTING_RULE_FREQUENCY_CONFIG,
} from '../../../models/RoutingRule';
import { RoutingRuleFrequencySmudge } from '../RoutingRuleFrequencySmudge';

export const CellRoutingRuleFrequency = ({ row }: CellProps<IRoutingRule>) => {
  const theme = useTheme();

  return (
    <HStack
      spacing="2"
      borderColor={
        ROUTING_RULE_FREQUENCY_CONFIG[row.original.Frequency].bgColor
      }
      bg={theme.bg.base1}
      borderWidth={1}
      rounded="sm"
      h="full"
      overflow={'hidden'}
    >
      <RoutingRuleFrequencySmudge
        rounded="0"
        h="full"
        frequency={row.original.Frequency}
      />

      <Text
        pr={2}
        textStyle={'description'}
        whiteSpace={'normal'}
        wordBreak={'normal'}
      >
        {ROUTING_RULE_FREQUENCY_CONFIG[row.original.Frequency].label}
      </Text>
    </HStack>
  );
};
