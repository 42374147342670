import { Box, Button, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { UploadIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTheme } from '../../../providers/ThemeProvider';
import useUploadGovernorProfileBranding from '../../../services/api/governorProfiles/mutations/useUploadGovernorProfileBranding';

export const GovernorProfileImageUpload = ({
  currentBrandUrl,
  onChange,
}: {
  currentBrandUrl?: string;
  onChange: any;
}) => {
  const [uploadError, set_uploadError] = React.useState<string | null>(null);
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);

  const theme = useTheme();

  const { mutateAsync, isLoading } = useUploadGovernorProfileBranding();

  const handleClick = () => {
    set_uploadError(null);
    if (hiddenFileInput?.current) {
      hiddenFileInput.current.click();
    }
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleFileInputChange = async event => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const maxAllowedSize = 5 * 1024 * 1024;
      if (selectedFile.size < maxAllowedSize) {
        const formData = new FormData();
        formData.append('image', selectedFile);

        const newUrlImage = await mutateAsync({ formData });

        if (onChange) {
          onChange(newUrlImage['BrandImageUrl']);
        }
      } else {
        set_uploadError('File too big. Can you pick something smaller?');
      }
    }
  };

  return (
    <Stack direction="row" spacing="6" align="center" width="full">
      <input
        type="file"
        accept="image/*"
        ref={hiddenFileInput}
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
      />

      <Image
        src={currentBrandUrl}
        fallbackSrc="https://cc-west-prd-bucket-users.s3.us-west-2.amazonaws.com/public/general/media/image-placeholder.png"
        h={16}
      />

      <Box>
        <HStack spacing="5">
          <Button
            onClick={handleClick}
            leftIcon={<UploadIcon />}
            isLoading={isLoading}
          >
            Change image
          </Button>
        </HStack>
        <Text textStyle="indicator" mt="3" color={theme.color.base3}>
          .jpg, or .png. Use 1.9 x 1 ratio
        </Text>

        {uploadError && (
          <Text textStyle="indicator" mt="3" color={theme.color.danger}>
            {uploadError}
          </Text>
        )}
      </Box>
    </Stack>
  );
};
