import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { TableInstance } from 'react-table';
import { PAGE_SIZE_OPTIONS } from '../../../../constants';
import { useTheme } from '../../../../providers/ThemeProvider';
import { IMessage } from '../../models/Message';

interface IProps {
  table: TableInstance<IMessage>;
}
export const MessagesTablePagination = ({ table }: IProps) => {
  const theme = useTheme();

  return (
    <Stack
      align={{ base: 'flex-start', md: 'center' }}
      justify="space-between"
      direction={{ base: 'column', md: 'row' }}
      spacing={2}
    >
      <Text color={theme.color.base3} textStyle={'description'} minW={'72'}>
        {table.preFilteredRows.length} total messages
      </Text>

      <Text
        color={theme.color.base3}
        textStyle={'description'}
        flex={1}
        textAlign={'center'}
      >
        Page {table?.state.pageIndex + 1} of {table.pageOptions.length}
      </Text>
      {/* <Box>
        <div>Go to page:</div>
        <input
          type="number"
          defaultValue={pageIndex + 1 || 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
        />
      </Box> */}

      <HStack minW={'72'}>
        <ButtonGroup variant="outline" size="sm">
          <Button
            rel="prev"
            onClick={() => table.previousPage()}
            disabled={!table.canPreviousPage}
          >
            Previous
          </Button>
          <Button
            rel="next"
            onClick={() => table.nextPage()}
            disabled={!table.canNextPage}
          >
            Next
          </Button>
        </ButtonGroup>
        <Box>
          <Select
            size="sm"
            value={table.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {PAGE_SIZE_OPTIONS.map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Box>
      </HStack>
    </Stack>
  );
};
