// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/form.ts

const Form = {
  parts: ["helperText"],
  baseStyle: {
    helperText: {
      display: "flex",
      alignItems: "center",
      fontSize: "sm",
      // this should prob match the color-subtle class
      color: "gray.400"
    }
  },
}

export default Form
