import { Stack } from '@chakra-ui/react';
import * as React from 'react';
import useOrganizationEventsTable from '../../hooks/useOrganizationEventsTable';
import { OrganizationEventsTableActions } from './OrganizationEventsTableActions';
import { OrganizationEventsTableContent } from './OrganizationEventsTableContent';
import { OrganizationEventsTablePagination } from './OrganizationEventsTablePagination';

export const OrganizationEventsTable = () => {
  const table = useOrganizationEventsTable();

  return (
    <>
      <Stack as="section" minW={'lg'} spacing={6}>
        <OrganizationEventsTableActions table={table} />
        <OrganizationEventsTableContent table={table} />
        <OrganizationEventsTablePagination table={table} />
      </Stack>
    </>
  );
};
