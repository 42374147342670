import { Badge, Stack } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { IUserProfile } from '../../../../models/UserProfile';

// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
// Possible values are incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid

const badgeEnum: Record<string, string> = {
  incomplete_expired: 'orange',
  incomplete: 'orange',
  trialing: 'blue',
  active: 'green',
  past_due: 'red',
  canceled: 'gray',
  unpaid: 'red',
};

export const CellOrgStatus = ({ row }: CellProps<IUserProfile>) => {
  const [colorScheme, set_colorScheme] = React.useState('gray');

  React.useEffect(() => {
    if (row?.original?.Billing?.SubscriptionStatus) {
      if (badgeEnum[row.original.Billing.SubscriptionStatus]) {
        set_colorScheme(badgeEnum[row.original.Billing.SubscriptionStatus]);
      }
    }
  }, [row]);

  return (
    <Stack direction="row" spacing="4" align="center">
      <Badge fontSize="xs" colorScheme={colorScheme}>
        {row.original.Billing.SubscriptionStatus}
      </Badge>
    </Stack>
  );
};
