import {
  HStack,
  Icon,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ClockIcon, UserIcon } from '@heroicons/react/outline';
import React from 'react';
import {
  runFormatTimeFromNow,
  runFormatTimeWithAt,
} from '../../../hooks/useFormatDate';
import { useTheme } from '../../../providers/ThemeProvider';
import { useListOrganizationEvents } from '../../../services/api/organization/queries/useListOrganizationEvents';

interface IProps {
  eventRecordId?: string;
}

export const OrganizationEventRecordHistory = ({ eventRecordId }: IProps) => {
  const theme = useTheme();

  const { data: dataHistoryEvents, isLoading } =
    useListOrganizationEvents(eventRecordId);

  return (
    <Stack spacing={8}>
      <SimpleGrid templateColumns={'1fr 2fr 1fr'} alignItems="center">
        <HStack>
          <Icon as={ClockIcon}></Icon>
          <Text textStyle={'badge'}>Time</Text>
        </HStack>
        <Text textStyle={'badge'}>Event name</Text>
        <HStack>
          <Icon as={UserIcon}></Icon>
          <Text textStyle={'badge'}>User</Text>
        </HStack>
      </SimpleGrid>
      {dataHistoryEvents &&
        dataHistoryEvents.records.map((historyEvent, idx) => {
          return (
            <Stack spacing={0} key={idx}>
              <SimpleGrid templateColumns={'1fr 2fr 1fr'} alignItems="center">
                <Text>{runFormatTimeWithAt(historyEvent.EventTime)}</Text>
                <Text>{historyEvent.EventName}</Text>
                <Text>{historyEvent.EventUserEmail}</Text>
              </SimpleGrid>
              <Text color={theme.color.base4}>
                {runFormatTimeFromNow(historyEvent.EventTime)}
              </Text>
            </Stack>
          );
        })}

      {!isLoading && dataHistoryEvents && dataHistoryEvents.meta.total < 1 && (
        <Text>No history</Text>
      )}

      {isLoading && (
        <>
          <Skeleton
            h="8"
            w="full"
            startColor={theme.bg.base2}
            endColor={theme.bg.base3}
          />
          <Skeleton
            h="8"
            w="80%"
            startColor={theme.bg.base2}
            endColor={theme.bg.base3}
          />
          <Skeleton
            h="8"
            w="70%"
            startColor={theme.bg.base2}
            endColor={theme.bg.base3}
          />
        </>
      )}
    </Stack>
  );
};
