import { Box, Stack, Text } from '@chakra-ui/react';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import { navigate, Redirect } from '@reach/router';
import { Auth } from 'aws-amplify';
import React from 'react';
import { MutedLink } from '../../../components/shared/MutedLink';
import { Routes } from '../../../constants';
import AuthResetPasswordConfirmForm from '../forms/AuthResetPasswordConfirmForm';

export interface ResetPasswordConfirmError {
  key?: string;
  status: string;
}

const AuthResetPasswordConfirm = props => {
  if (!props.location?.state?.username) {
    return <Redirect path="/" />;
  }

  const handleFormSubmit = async (
    code: string,
    username: string,
    password: string
  ) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);

      navigate(Routes.AuthSignIn);
    } catch (error: any) {
      // Parse cognito responses
      let err: ResetPasswordConfirmError = {
        status: 'Reset failed',
      };
      if (error.code === 'LimitExceededException') {
        err = {
          status: 'Reached limit. Wait 15 minutes and try again.',
        };
      }

      throw err;
    }
  };

  return (
    <Box>
      {/* Form title */}
      <Text textStyle="headline">Confirm Reset Password</Text>
      <Text fontSize="sm">Check your email to get the confirmation code</Text>

      {/* Form */}
      <Stack spacing={6} mt={6}>
        {/* ResetPasswordConfirm Form */}
        <AuthResetPasswordConfirmForm
          username={props.location.state.username}
          handleFormSubmit={handleFormSubmit}
        ></AuthResetPasswordConfirmForm>

        {/* Actions */}
        <Stack>
          {/* Back to login */}
          <MutedLink
            to={Routes.AuthSignIn}
            label="back to sign in"
            leftIcon={ArrowNarrowLeftIcon}
          ></MutedLink>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AuthResetPasswordConfirm;
