import { Box } from '@chakra-ui/react';
import React from 'react';
import { PageHeader } from '../../../layouts/PageHeader';
import { OrganizationUsersTable } from './organizationUsers/OrganizationUsersTable';

const Organization = () => {
  return (
    <>
      <PageHeader title="Users"></PageHeader>
      <Box py={8}>
        <OrganizationUsersTable></OrganizationUsersTable>
      </Box>
    </>
  );
};

export default Organization;
