// Store provides state that are pure ui manipulations

import produce from 'immer';
import { IBreadcrumb } from '../../models/Breadcrumb';
import {
  ILayoutStore,
  layoutStoreInitialState,
} from '../../models/LayoutStore';
import makeStore from '../../utils/makeStore';

export enum LayoutStoreActions {
  toggleNavOpen = 'toggleNavOpen',
  toggleSubLayoutNavOpen = 'toggleSubLayoutNavOpen',
  toggleAsideOpen = 'toggleAsideOpen',
  setUserMenuOpen = 'setUserMenuOpen',
  setBreadcrumb = 'setBreadcrumb',
  setStreamSearch = 'setStreamSearch',
}

export interface IILayoutStoreAction {
  type: LayoutStoreActions;
  payload:
    | ILayoutToggleNavOpenPayloadProps
    | ILayoutSetBreadcrumbActionPayloadProps
    | ILayoutSetUserMenuOpenActionPayloadProps;
}

// toggleNavOpen
export interface ILayoutToggleNavOpenPayloadProps {}

const toggleSubLayoutNavOpen = (draft: ILayoutStore) => {
  draft.isSubLayoutNavOpen = !draft.isSubLayoutNavOpen;
};
const toggleAsideOpen = (draft: ILayoutStore) => {
  draft.isAsideOpen = !draft.isAsideOpen;
};

// setUserMenuOpen
export interface ILayoutSetUserMenuOpenActionPayloadProps {
  isOpen: boolean;
}
const setUserMenuOpen = (
  draft: ILayoutStore,
  payload: ILayoutSetUserMenuOpenActionPayloadProps
) => {
  draft.isUserMenuOpen = payload.isOpen;
};

// setBreadcrumb
export interface ILayoutSetBreadcrumbActionPayloadProps {
  breadcrumb: IBreadcrumb[];
}
const setBreadcrumb = (
  draft: ILayoutStore,
  payload: ILayoutSetBreadcrumbActionPayloadProps
) => {
  draft.breadcrumb = payload.breadcrumb;
};

const layoutReducer = (state: ILayoutStore, action: IILayoutStoreAction) =>
  produce(state, draft => {
    switch (action.type) {
      case LayoutStoreActions.setBreadcrumb:
        setBreadcrumb(
          draft,
          action.payload as ILayoutSetBreadcrumbActionPayloadProps
        );
        break;

      case LayoutStoreActions.toggleSubLayoutNavOpen:
        toggleSubLayoutNavOpen(draft);
        break;

      case LayoutStoreActions.toggleAsideOpen:
        toggleAsideOpen(draft);
        break;

      case LayoutStoreActions.setUserMenuOpen:
        setUserMenuOpen(
          draft,
          action.payload as ILayoutSetUserMenuOpenActionPayloadProps
        );
        break;

      default:
        break;
    }
  });

const [LayoutStoreProvider, useLayoutStore, useLayoutDispatch] = makeStore(
  layoutReducer,
  layoutStoreInitialState,
  'ccLayoutStore',
  true
);

export { LayoutStoreProvider, useLayoutStore, useLayoutDispatch };
