import { HStack, Icon, Link, LinkProps, Text } from '@chakra-ui/react';
import { Link as ReachLink } from '@reach/router';
import * as React from 'react';
import { useTheme } from '../../providers/ThemeProvider';

interface IMutedLinkProps extends LinkProps {
  to: string;
  label: string;
  leftIcon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
}

export const MutedLink = (props: IMutedLinkProps) => {
  const theme = useTheme();
  const { to, label, leftIcon } = props;
  return (
    <Link
      as={ReachLink}
      to={to}
      role="group"
      whiteSpace="nowrap"
      fontSize="sm"
      _hover={{
        textDecoration: 'none',
        color: theme.color.linkHover,
      }}
    >
      <HStack>
        {props.leftIcon && (
          <Icon
            color={theme.color.link}
            h={5}
            w={5}
            _groupHover={{ color: theme.color.linkHover }}
            as={leftIcon}
          ></Icon>
        )}

        <Text
          _groupHover={{ color: theme.color.linkHover }}
          color={theme.color.link}
        >
          {label}
        </Text>
      </HStack>
    </Link>
  );
};
