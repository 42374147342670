import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { useTheme } from '../../../../../providers/ThemeProvider';
import { IMessage } from '../../../models/Message';

export const CellMessageTitle = ({ row }: CellProps<IMessage>) => {
  const theme = useTheme();

  return (
    <Stack
      spacing={0}
      justifyContent={'center'}
      h="full"
      whiteSpace={'pre-wrap'}
    >
      <Text noOfLines={4} color={theme.color.base} textStyle={'formLabel'}>
        {row.original.MessageTitle}
      </Text>
    </Stack>
  );
};
