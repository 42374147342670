import {
  Box,
  Button,
  ButtonGroup,
  Icon,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { DuplicateIcon, MailIcon } from '@heroicons/react/outline';
import React from 'react';
import appConfig from '../../../app-config';
import { PageHeader } from '../../../layouts/PageHeader';
import { useReadOrganization } from '../../../services/api/organization/queries/useReadOrganization';
import { InviteOrgPartnerModal } from '../partnerships/components/InviteOrgPartnerModal';
import { OrganizationPartnerships } from '../partnerships/components/OrganizationPartnerships';

const SettingsPartnerships = () => {
  const [partnerShareLink, set_partnerShareLink] = React.useState('');

  const toast = useToast();

  const {
    isOpen: isOpenModal,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();

  const { data: dataOrganization } = useReadOrganization();

  const { onCopy: onCopyNew } = useClipboard(partnerShareLink);

  const handleOnCopyNew = () => {
    onCopyNew();
    toast({
      title: 'Copied',
      description: partnerShareLink,
      status: 'success',
      variant: 'subtle',
      duration: 2000,
      isClosable: true,
    });
  };

  React.useEffect(() => {
    if (dataOrganization?.OrganizationId) {
      set_partnerShareLink(
        `${appConfig.Organizations.basePartnerInvite}${dataOrganization?.OrganizationId}`
      );
    }
  }, [dataOrganization]);

  return (
    <>
      <PageHeader
        title="Partnerships"
        description="Link your organization with another to begin sharing policies and various templates."
      >
        <ButtonGroup spacing="4" size="sm">
          <Button
            colorScheme={'indigo'}
            leftIcon={<Icon as={MailIcon} />}
            onClick={onOpenModal}
          >
            Send invite
          </Button>
          <Button
            variant={'outline'}
            colorScheme="primary"
            onClick={handleOnCopyNew}
            leftIcon={<Icon as={DuplicateIcon}></Icon>}
          >
            or click to copy link
          </Button>
        </ButtonGroup>
      </PageHeader>
      <Box py={8}>
        <OrganizationPartnerships></OrganizationPartnerships>
      </Box>
      <InviteOrgPartnerModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
      ></InviteOrgPartnerModal>
    </>
  );
};

export default SettingsPartnerships;
