export interface IGovernorProfileUpdate {
  // Person details
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  BrandImageUrl?: string;
  // Address
  Address1?: string;
  Address2?: string;
  City?: string;
  State?: string;
  Zip?: string;
}
export interface IGovernorProfileBase extends IGovernorProfileUpdate {
  // Org
  OrganizationId?: string;
}

export interface IGovernorProfileWorkingForm extends IGovernorProfileUpdate {}

export const initialGovernorProfileSettingsForm: IGovernorProfileWorkingForm = {
  FirstName: '',
  LastName: '',
  Email: '',
  Phone: '',
  BrandImageUrl: '',
  Address1: '',
  Address2: '',
  City: '',
  State: '',
  Zip: '',
};

export interface IGovernorProfile extends IGovernorProfileBase {
  UserId: string;
  CreatedAt: string;
  UpdatedAt?: string;
  OrganizationId: string;
  // Branding
  BrandImageUrl?: string;
}

// Use to post signup
export interface IGovernorSignup {
  Username: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  Zip: string;
  OrganizationName?: string;
  OrganizationId?: string;
}

export const convertGovernorProfileToWorkingForm = (
  dataIn: IGovernorProfile
): IGovernorProfileWorkingForm => {
  const formOut: IGovernorProfileWorkingForm = {
    FirstName: dataIn.FirstName || '',
    LastName: dataIn.LastName || '',
    Email: dataIn.Email || '',
    Phone: dataIn.Phone || '',
    BrandImageUrl: dataIn.BrandImageUrl || '',
    Address1: dataIn.Address1 || '',
    Address2: dataIn.Address2 || '',
    City: dataIn.City || '',
    State: dataIn.State || '',
    Zip: dataIn.Zip || '',
  };

  return formOut;
};
