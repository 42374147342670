import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import {
  IGuardrails,
  IGuardrailsUpdate,
} from '../../../../routes/guardrails/models/Guardrails';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (updateData: IGuardrailsUpdate) => {
  const payload = {
    body: updateData,
  };

  return API.put('users', '/api/v1/guardrails/', payload);
};

const useUpdateGuardrails = () => {
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation<
    IGuardrails,
    any,
    {
      updateData: IGuardrailsUpdate;
    },
    {
      previousRecord: IGuardrails | any;
      updateData: IGuardrailsUpdate;
    }
  >(
    ({ updateData }) => {
      return makeApiRequest(updateData);
    },
    {
      retry: 0,

      onMutate: async ({ updateData }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([QueryKeys.guardrails]);

        // Snapshot the previous value
        const previousRecord = queryClient.getQueryData<IGuardrails>([
          QueryKeys.guardrails,
        ]);

        // Optimistically update to the new value
        queryClient.setQueryData([QueryKeys.guardrails], {
          ...previousRecord,
          ...updateData,
        });

        // Return a context object with the snapshotted value
        return { previousRecord, updateData };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, newTodo, context) => {
        if (context) {
          queryClient.setQueryData(
            [QueryKeys.guardrails],
            context.previousRecord
          );
        }
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.guardrails]);
      },
    }
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

export default useUpdateGuardrails;
