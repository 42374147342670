// chakra theme setup


// Component style overrides
import { extendTheme, theme as defaultTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import customColors from "./colors";
import Alert from "./components/Alert";
import Avatar from "./components/Avatar";
import Badge from "./components/Badge";
import Breadcrumb from "./components/Breadcrumb";
import Button from "./components/Button";
import Form from "./components/Form";
import FormLabel from "./components/FormLabel";
import Input from "./components/Input";
import Menu from "./components/Menu";
import Modal from "./components/Modal";
import NumberInput from "./components/NumberInput";
import Select from "./components/Select";
import Tabs from "./components/Tabs";
import Tag from "./components/Tag";
import Textarea from "./components/Textarea";
import Tooltip from "./components/Tooltip";



const colors = {...defaultTheme.colors, ...customColors};


const overrides = {
  styles: {
    global: (props) => ({
      html: {
        fontSize: "14px",
      },
      body: {
        fontFamily: 'body',
        padding: '0',
        color: mode('slate.800', 'whiteAlpha.900')(props),
        bg: mode('white', 'slate.800')(props),
        lineHeight: 'base',
      },
      '*::placeholder': {
        color: mode('slate.400', 'whiteAlpha.400')(props),
      },
      '*, *::before, &::after': {
        borderColor: mode('slate.200', 'whiteAlpha.300')(props),
        wordWrap: 'break-word',
      },
    }),
  },
  useSystemColorMode: false,
  initialColorMode: "system",
  // styles,
  // borders,
  colors: {
    // Build your palette here
    // This needs to be the same as the tailwind config
    // but putting here so we can use chakra color scheme
    // on components
    ...colors,
    primary: {
      ...colors.blue,
    },
    action: {
      ...colors.green,
    },
    accent: {
      ...colors.violet,
    },
  },
  // Removed fonts so they are just defined in tailwind
  fonts: {
    // https://chakra-ui.com/docs/theming/theme#typography
    heading: "Inter, Arial, serif",
    body: "Inter, Arial, sans-serif",
    mono: "Source Code Pro, monospace",
  },
  // Other foundational style overrides go here
  components: {
    Alert,
    Avatar,
    Badge,
    Breadcrumb,
    Button,
    Form,
    FormLabel,
    Input,
    NumberInput,
    Menu,
    Tooltip,
    Modal,
    Select,
    Tag,
    Tabs,
    Textarea,
  },
  textStyles: {
    headline: {
      fontSize: "2xl",
      fontWeight: "500",
    },
    strong: {
      fontWeight: "500",
    },
    badge: {
      fontSize: "xs",
      textTransform: "uppercase",
      fontWeight: "500",
    },
    navLink: {
      fontWeight: "500",
    },
    indicator: {
      fontSize: "sm",
    },
    title: {
      fontWeight: "semibold", fontSize: "lg"
    },
    description: {
      fontSize: "sm",
      fontWeight: "normal"
    },
    descriptionLabel: {
      fontWeight: "bold",
      fontSize: "sm",
      casing: "uppercase",
      whiteSpace: "nowrap",
    },
    descriptionValue: {
      fontSize:"md",
      fontWeight:"medium"
    },
    formLabel: {
      fontSize: "md",
    },
    switchLabel: {
      fontSize: "sm",
      // fontFamily: "mono",
      // fontWeight: "semibold",
      // textTransform: "uppercase",
      letterSpacing: "0.05em",

    },
  },
};

const ChakraTheme = extendTheme(overrides);

export default ChakraTheme;
