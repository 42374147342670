import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { IGovernorProfileUpdate } from '../../../../models/GovernorProfile';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (formData: FormData) => {
  const postData = {
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return API.post('users', '/api/v1/governors/uploadbranding', postData);
};

const useUploadGovernorProfileBranding = () => {
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation<IGovernorProfileUpdate, any, { formData: FormData }>(
    ({ formData }) => makeApiRequest(formData),
    {
      retry: 0,
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.governorProfiles]);
      },
    }
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

export default useUploadGovernorProfileBranding;
