const FormLabel = {
  // The styles all button have in common
  baseStyle: ({ colorMode }) => ({
    color: colorMode === 'dark' ? 'slate.300' : 'gray.700',
    fontSize: 'xs',
    fontWeight: "bold",
  }),

}

export default FormLabel