import { Stack, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import useMessagesTable from '../../hooks/useMessagesTable';
import { OrganizationEventRecordHistoryModal } from '../OrganizationEventRecordHistoryModal';
import { MessagesTableActions } from './MessagesTableActions';
import { MessagesTableContent } from './MessagesTableContent';
import { MessagesTablePagination } from './MessagesTablePagination';
import TemplateMessageDialogDelete from './modals/TemplateMessageDialogDelete';
import TemplateMessageDialogLink from './modals/TemplateMessageDialogLink';
import { TemplateMessageModalEdit } from './modals/TemplateMessageModalEdit';

export const MessagesTable = () => {
  const {
    isOpen: isOpenModal,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();
  const {
    isOpen: isOpenEventModal,
    onClose: onCloseEventModal,
    onOpen: onOpenEventModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteDialog,
    onOpen: onOpenDeleteDialog,
    onClose: onCloseDeleteDialog,
  } = useDisclosure();

  const {
    isOpen: isOpenLinkDialog,
    onOpen: onOpenLinkDialog,
    onClose: onCloseLinkDialog,
  } = useDisclosure();

  const table = useMessagesTable();

  const handleOnClose = () => {
    table.setEditMessage(null);
    onCloseModal();
  };
  const handleOnEventClose = () => {
    table.setViewHistoryMessage(null);
    onCloseEventModal();
  };
  const handleOnCloseDelete = () => {
    table.setDeleteMessage(null);
    onCloseDeleteDialog();
  };
  const handleOnCloseLink = () => {
    table.setLinkMessage(null);
    onCloseLinkDialog();
  };

  React.useEffect(() => {
    if (table.editMessage && !isOpenModal) {
      onOpenModal();
    }
    if (table.viewHistoryMessage && !isOpenEventModal) {
      onOpenEventModal();
    }

    if (table.deleteMessage && !isOpenDeleteDialog) {
      onOpenDeleteDialog();
    }

    if (table.linkMessage && !isOpenLinkDialog) {
      onOpenLinkDialog();
    }
  }, [
    table,
    isOpenModal,
    isOpenEventModal,
    isOpenDeleteDialog,
    isOpenLinkDialog,
    onOpenModal,
    onOpenEventModal,
    onOpenDeleteDialog,
    onOpenLinkDialog,
  ]);

  return (
    <>
      <Stack as="section" minW={'5xl'} spacing={6}>
        <MessagesTableActions table={table} />
        <MessagesTableContent table={table} />
        <MessagesTablePagination table={table} />
      </Stack>

      <TemplateMessageModalEdit
        isOpen={isOpenModal}
        onClose={handleOnClose}
        message={table.editMessage}
      ></TemplateMessageModalEdit>

      <OrganizationEventRecordHistoryModal
        isOpen={isOpenEventModal}
        onClose={handleOnEventClose}
        eventRecordId={table.viewHistoryMessage?.TemplateMessageId}
      ></OrganizationEventRecordHistoryModal>

      <TemplateMessageDialogDelete
        message={table.deleteMessage}
        isOpen={isOpenDeleteDialog}
        onClose={handleOnCloseDelete}
      ></TemplateMessageDialogDelete>

      <TemplateMessageDialogLink
        templateMessage={table.linkMessage?.message}
        organizationLink={table.linkMessage?.organizationLink}
        isOpen={isOpenLinkDialog}
        onClose={handleOnCloseLink}
      ></TemplateMessageDialogLink>
    </>
  );
};
