import { Center, CenterProps } from '@chakra-ui/react';
import React from 'react';
import { useTheme } from '../../../../providers/ThemeProvider';
import {
  IEventNameConfig,
  RoutingRuleEventNameEnum,
  ROUTING_RULE_EVENT_NAME_CONFIG,
} from '../../models/RoutingRule';

interface IProps extends CenterProps {
  eventName: RoutingRuleEventNameEnum;
}

export const RoutingRuleEventNameSmudge = ({ eventName, ...props }: IProps) => {
  const [configLookup, set_configLookup] =
    React.useState<null | IEventNameConfig>(null);

  const theme = useTheme();

  React.useEffect(() => {
    if (eventName && ROUTING_RULE_EVENT_NAME_CONFIG[eventName]) {
      set_configLookup(ROUTING_RULE_EVENT_NAME_CONFIG[eventName]);
    }
  }, [eventName]);

  return (
    <Center
      h={8}
      minH={8}
      w={8}
      minW={8}
      bg={configLookup?.bgColor}
      rounded="sm"
      overflow={'hidden'}
      p={1}
      color={theme.bg.dark}
      {...props}
    >
      {configLookup?.icon}
    </Center>
  );
};
