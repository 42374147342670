import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import * as React from 'react';
import { BsSearch } from 'react-icons/bs';
import { RiAddFill } from 'react-icons/ri';
import { TableInstance, useAsyncDebounce } from 'react-table';
import { IUserProfile } from '../../../../models/UserProfile';
import { useTheme } from '../../../../providers/ThemeProvider';
import {
  COL_ID_APPROVAL_STATUS,
  COL_ID_BILLING_STATUS,
} from './OrganizationUsersColumns';

const GlobalFilter = ({ preFilterCount, globalFilter, setGlobalFilter }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  React.useEffect(() => {
    if (!globalFilter) {
      setValue('');
    }
  }, [globalFilter]);

  return (
    <FormControl minW={{ md: '320px' }} id="search">
      <InputGroup size="sm">
        <FormLabel srOnly>Filter records</FormLabel>
        <InputLeftElement pointerEvents="none" color={theme.color.base3}>
          <BsSearch />
        </InputLeftElement>
        <Input
          value={value || ''}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          type="search"
          placeholder={`Filter ${preFilterCount} records...`}
        />
      </InputGroup>
    </FormControl>
  );
};

interface IProps {
  onInvite: () => void;
  table: TableInstance<IUserProfile>;
}
export const OrganizationUsersTableActions = ({ onInvite, table }: IProps) => {
  const [filterStatusValue, set_filterStatusValue] = React.useState<string>('');
  const [filterApprovalValue, set_filterApprovalValue] =
    React.useState<string>('');

  React.useEffect(() => {
    if (!table.state.filters.length) {
      set_filterStatusValue('');
      set_filterApprovalValue('');
    }
  }, [table?.state?.filters]);

  return (
    <Stack
      spacing="4"
      direction={{ base: 'column', md: 'row' }}
      justify="space-between"
    >
      <HStack>
        <GlobalFilter
          preFilterCount={table.preGlobalFilteredRows.length}
          globalFilter={table.state.globalFilter}
          setGlobalFilter={table.setGlobalFilter}
        />

        <Select
          // w={{ base: '400px', md: 'unset' }}
          rounded="base"
          size="sm"
          value={filterStatusValue}
          placeholder="All subscriptions"
          onChange={e => {
            const updatedFilter = e.target.value.toLowerCase();
            set_filterStatusValue(updatedFilter);
            table.setFilter(COL_ID_BILLING_STATUS, updatedFilter);
          }}
        >
          <option value="active">Active</option>
          <option value="trialing">Trialing</option>
          <option value="cancelled">Cancelled</option>
        </Select>
        <Select
          // w={{ base: '400px', md: 'unset' }}
          rounded="base"
          size="sm"
          value={filterApprovalValue}
          placeholder="All users"
          onChange={e => {
            const updatedFilter = e.target.value.toLowerCase();
            set_filterApprovalValue(updatedFilter);
            table.setFilter(COL_ID_APPROVAL_STATUS, updatedFilter);
          }}
        >
          <option value="approved">All approved</option>
          <option value="not approved">All not approved</option>
        </Select>
      </HStack>

      <ButtonGroup size="sm" variant="outline">
        <Button
          onClick={onInvite}
          iconSpacing="1"
          leftIcon={<RiAddFill fontSize="1.25em" />}
        >
          Invite user
        </Button>
        {/* <Button
          iconSpacing="1"
          leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
        >
          Export CSV
        </Button> */}
      </ButtonGroup>
    </Stack>
  );
};
