import { Box, Center, Icon } from '@chakra-ui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import * as React from 'react';
import { useTheme } from '../providers/ThemeProvider';

interface MobileHamburgerMenuProps {
  onClick?: VoidFunction;
  isOpen: boolean;
}

export const MobileHamburgerMenu = (props: MobileHamburgerMenuProps) => {
  const { onClick, isOpen } = props;
  const theme = useTheme();
  return (
    <Box
      ms="-2"
      minW={{ base: '12', md: '76px' }}
      display={{ md: 'none' }}
      color={theme.color.base}
    >
      <Center as="button" onClick={onClick} p="2" fontSize="xl">
        {isOpen ? <Icon as={XIcon} /> : <Icon as={MenuIcon} />}
        <Box srOnly>{isOpen ? 'Close menu' : 'Open menu'}</Box>
      </Center>
    </Box>
  );
};
