import { Stack } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { useAuth } from '../../../../providers/AuthProvider';
import { useReadGuardrails } from '../../../../services/api/guardrails/queries/useReadGuardrails';
import { OrganizationEventRecordMeta } from '../../../guardrails/components/OrganizationEventRecordMeta';
import { IOrganizationEvent } from '../../models/OrganizationEvent';

export const CellEventMeta = ({ row }: CellProps<IOrganizationEvent>) => {
  const { userProfile } = useAuth();
  const { data: dataGuardrails } = useReadGuardrails();

  React.useEffect(() => {}, [userProfile, row, dataGuardrails]);

  return (
    <Stack direction="row" spacing="4" align="center">
      <OrganizationEventRecordMeta
        organizationEvent={row.original}
      ></OrganizationEventRecordMeta>
    </Stack>
  );
};
