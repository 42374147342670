import { Box, Grid } from '@chakra-ui/react';
import React from 'react';
import { DescriptionLabel } from '../../../components/shared/Description';
import {
  runFormatDate,
  runFormatDateWithAt,
} from '../../../hooks/useFormatDate';
import { PageHeader } from '../../../layouts/PageHeader';
import { useReadOrganization } from '../../../services/api/organization/queries/useReadOrganization';
import { OrganizationSettings } from './OrganizationSettings';

const SettingsOrganization = () => {
  const { data: dataOrganization } = useReadOrganization();

  return (
    <>
      <PageHeader title="Organization">
        <Grid
          templateColumns={{
            base: '1fr',
            sm: 'repeat(2, 1fr)',
            lg: 'repeat(2, fit-content(320px))',
          }}
          columnGap="20"
          rowGap={{ base: '8', lg: '14' }}
        >
          <DescriptionLabel title="Joined">
            {runFormatDate(dataOrganization?.CreatedAt)}
          </DescriptionLabel>
          <DescriptionLabel title="Last Updated">
            {runFormatDateWithAt(dataOrganization?.UpdatedAt)}
          </DescriptionLabel>
        </Grid>
      </PageHeader>
      <Box py={8}>
        <OrganizationSettings></OrganizationSettings>
      </Box>
    </>
  );
};

export default SettingsOrganization;
