import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {
  AppTrackEventsEnum,
  useAppTrack,
} from '../../../../../providers/AppTrackProvider';
import { useTheme } from '../../../../../providers/ThemeProvider';
import useCreateTemplateQuestion from '../../../../../services/api/templateQuestions/mutations/useCreateTemplateQuestion';
import { QuestionForm } from '../../../forms/questions/QuestionForm';
import { IQuestion, IQuestionUpdate } from '../../../models/Question';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: IQuestion | null;
}

export const TemplateQuestionModalAdd = ({
  isOpen,
  onClose,
  initialValues,
}: IProps) => {
  const theme = useTheme();

  const { mutateAsync: mutateAsyncCreate } = useCreateTemplateQuestion();

  const { logEvent: appTrackLogEvent } = useAppTrack();

  const handleSuccess = (dataIn: IQuestion) => {
    // Close modal when mutate is successful
    appTrackLogEvent(AppTrackEventsEnum.QUESTION_CREATED, {
      question_id: dataIn.QuestionId,
    });
    handleFormClose();
  };

  const handleFormSubmit = async (data: IQuestionUpdate) => {
    // Post data to api

    try {
      await mutateAsyncCreate(data, {
        onSuccess: handleSuccess,
      });
    } catch (err) {
      console.log(err);
      // Throw error for downstream form to handle
      throw err;
    }
  };

  const handleFormClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      size="4xl"
      colorScheme="primary"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack spacing={0}>
            <Text>Add Question</Text>
            <Text textStyle="description" color={theme.color.base3}>
              Create new question that will be available for the users in your
              organization to use in their discovery forms.
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={8}>
          <QuestionForm
            onSubmit={handleFormSubmit}
            onCancel={handleFormClose}
            actionText={'Add question'}
            question={initialValues}
          ></QuestionForm>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
