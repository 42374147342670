import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  InformationCircleIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { Field, FieldArray, FormikProps, getIn } from 'formik';
import React from 'react';
import {
  ChakraInputStatusIcon,
  FormInputControl,
} from '../../../../components/forms';
import { PropertyStack } from '../../../../components/forms/PropertyStack';
import { FormRadioItemTextList } from '../../../../components/forms/Radio/common/RadioItemTextList';
import { useTheme } from '../../../../providers/ThemeProvider';
import { RoutingRuleConnectorTypeSmudge } from '../../components/routingRules/RoutingRuleConnectorTypeSmudge';
import { RoutingRuleEventNameSmudge } from '../../components/routingRules/RoutingRuleEventNameSmudge';
import { RoutingRuleFrequencySmudge } from '../../components/routingRules/RoutingRuleFrequencySmudge';
import {
  IRoutingRuleMold,
  RoutingRuleConnectorTypeEnum,
  RoutingRuleEventNameEnum,
  RoutingRuleFrequencyEnum,
} from '../../models/RoutingRule';

interface IProps {
  form: FormikProps<IRoutingRuleMold>;
}

const ErrorMessage = ({ name }) => (
  <Field name={name}>
    {({ field, form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? error : null;
    }}
  </Field>
);

export const RoutingRuleFormGroup = ({ form }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <Stack spacing={4}>
        <Stack spacing="6">
          <PropertyStack
            label="Name"
            description="What is the name of this rule?"
          >
            <FormInputControl
              name="routingRuleName"
              formControlProps={{
                maxW: '28rem',
              }}
            />
          </PropertyStack>

          <PropertyStack
            label="Event"
            description="What event is the trigger for this rule?"
          >
            <FormRadioItemTextList
              name="eventName"
              choices={[
                {
                  item: (
                    <RoutingRuleEventNameSmudge
                      eventName={RoutingRuleEventNameEnum.MESSAGE_ALL}
                    />
                  ),
                  label: 'All sms conversations',
                  value: RoutingRuleEventNameEnum.MESSAGE_ALL,
                },
                {
                  item: (
                    <RoutingRuleEventNameSmudge
                      eventName={RoutingRuleEventNameEnum.NEW_SUBMISSION}
                    />
                  ),
                  label: 'A submission of a form',
                  value: RoutingRuleEventNameEnum.NEW_SUBMISSION,
                },
              ]}
            />
          </PropertyStack>

          <PropertyStack
            label="Connector"
            description="Where should data be sent?"
          >
            <FormRadioItemTextList
              name="connectorType"
              choices={[
                {
                  item: (
                    <RoutingRuleConnectorTypeSmudge
                      connectorType={RoutingRuleConnectorTypeEnum.EMAIL}
                    />
                  ),
                  label: 'Custom email',
                  value: RoutingRuleConnectorTypeEnum.EMAIL,
                },
                {
                  item: (
                    <RoutingRuleConnectorTypeSmudge
                      connectorType={RoutingRuleConnectorTypeEnum.SMARSH}
                    />
                  ),
                  label: 'Direct smarsh',
                  value: RoutingRuleConnectorTypeEnum.SMARSH,
                },
                {
                  item: (
                    <RoutingRuleConnectorTypeSmudge
                      connectorType={RoutingRuleConnectorTypeEnum.PROOFPOINT}
                    />
                  ),
                  label: 'Direct proofpoint',
                  value: RoutingRuleConnectorTypeEnum.PROOFPOINT,
                },
                {
                  item: (
                    <RoutingRuleConnectorTypeSmudge
                      connectorType={RoutingRuleConnectorTypeEnum.GLOBALRELAY}
                    />
                  ),
                  label: 'Direct global relay',
                  value: RoutingRuleConnectorTypeEnum.GLOBALRELAY,
                },
                {
                  item: (
                    <RoutingRuleConnectorTypeSmudge
                      connectorType={RoutingRuleConnectorTypeEnum.INTRADYN}
                    />
                  ),
                  label: 'Direct intradyn',
                  value: RoutingRuleConnectorTypeEnum.INTRADYN,
                },
              ]}
            />
          </PropertyStack>

          {form.values.connectorType === RoutingRuleConnectorTypeEnum.EMAIL && (
            <PropertyStack
              label="Email details"
              description="Configure the email details"
            >
              <Stack>
                <Text textStyle={'description'}>Emails To:</Text>
                <FieldArray
                  name="emailsTo"
                  validateOnChange={true}
                  render={arrayHelpers => (
                    <Box>
                      <FormControl>
                        <Stack spacing={4} maxW={'lg'}>
                          {form.values.emailsTo &&
                            form.values.emailsTo.length > 0 &&
                            form.values.emailsTo.map((email, index) => (
                              <HStack key={index} alignItems="flex-start">
                                <Field name={`emailsTo.${index}`}>
                                  {({ field }) => {
                                    return (
                                      <FormControl
                                        isRequired
                                        isInvalid={
                                          form.errors.emailsTo &&
                                          form.errors.emailsTo[index] &&
                                          Array.isArray(form.errors.emailsTo) &&
                                          form.touched.emailsTo &&
                                          form.touched.emailsTo[index]
                                        }
                                      >
                                        <InputGroup>
                                          <Input
                                            {...field}
                                            id={`emailsTo.${index}`}
                                            placeholder=""
                                          />

                                          <ChakraInputStatusIcon
                                            isRequired
                                            error={
                                              form.errors.emailsTo &&
                                              Array.isArray(
                                                form.errors.emailsTo
                                              ) &&
                                              form.errors.emailsTo[index]
                                            }
                                            touched={
                                              form.touched.emailsTo &&
                                              form.touched.emailsTo[index]
                                            }
                                          />
                                        </InputGroup>

                                        <FormErrorMessage>
                                          <ErrorMessage
                                            name={`emailsTo.${index}`}
                                          />
                                        </FormErrorMessage>
                                      </FormControl>
                                    );
                                  }}
                                </Field>

                                <IconButton
                                  variant="outline"
                                  size="md"
                                  aria-label="remove email"
                                  onClick={() => arrayHelpers.remove(index)}
                                  icon={<Icon as={TrashIcon}></Icon>}
                                />
                              </HStack>
                            ))}
                          <Box>
                            <Button
                              onClick={() => arrayHelpers.push('')}
                              variant="outline"
                              size="sm"
                              leftIcon={<Icon as={PlusIcon}></Icon>}
                            >
                              Add email
                            </Button>
                          </Box>
                        </Stack>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          typeof form.errors.emailsTo === 'string' &&
                          form.touched.emailsTo
                        }
                      >
                        <FormErrorMessage>
                          {form.errors.emailsTo}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  )}
                ></FieldArray>

                <Text textStyle={'description'}>Emails Cc (Optional):</Text>

                <FieldArray
                  name="emailsCc"
                  validateOnChange={true}
                  render={arrayHelpers => (
                    <Box>
                      <FormControl>
                        <Stack spacing={4} maxW={'lg'}>
                          {form.values.emailsCc &&
                            form.values.emailsCc.length > 0 &&
                            form.values.emailsCc.map((email, index) => (
                              <HStack key={index} alignItems="flex-start">
                                <Field name={`emailsCc.${index}`}>
                                  {({ field }) => {
                                    return (
                                      <FormControl
                                        isRequired
                                        isInvalid={
                                          form.errors.emailsCc &&
                                          form.errors.emailsCc[index] &&
                                          Array.isArray(form.errors.emailsCc) &&
                                          form.touched.emailsCc &&
                                          form.touched.emailsCc[index]
                                        }
                                      >
                                        <InputGroup>
                                          <Input
                                            {...field}
                                            id={`emailsCc.${index}`}
                                            placeholder=""
                                          />

                                          <ChakraInputStatusIcon
                                            isRequired
                                            error={
                                              form.errors.emailsCc &&
                                              Array.isArray(
                                                form.errors.emailsCc
                                              ) &&
                                              form.errors.emailsCc[index]
                                            }
                                            touched={
                                              form.touched.emailsCc &&
                                              form.touched.emailsCc[index]
                                            }
                                          />
                                        </InputGroup>

                                        <FormErrorMessage>
                                          <ErrorMessage
                                            name={`emailsCc.${index}`}
                                          />
                                        </FormErrorMessage>
                                      </FormControl>
                                    );
                                  }}
                                </Field>

                                <IconButton
                                  variant="outline"
                                  size="md"
                                  aria-label="remove email"
                                  onClick={() => arrayHelpers.remove(index)}
                                  icon={<Icon as={TrashIcon}></Icon>}
                                />
                              </HStack>
                            ))}
                          <Box>
                            <Button
                              onClick={() => arrayHelpers.push('')}
                              variant="outline"
                              size="sm"
                              leftIcon={<Icon as={PlusIcon}></Icon>}
                            >
                              Add CC email
                            </Button>
                          </Box>
                        </Stack>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          typeof form.errors.emailsCc === 'string' &&
                          form.touched.emailsCc
                        }
                      >
                        <FormErrorMessage>
                          {form.errors.emailsCc}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  )}
                ></FieldArray>
              </Stack>
            </PropertyStack>
          )}

          {form.values.connectorType ===
            RoutingRuleConnectorTypeEnum.SMARSH && (
            <PropertyStack
              label="Smarsh details"
              description="Configure the smarsh connector"
            >
              <FormInputControl
                name="smarshChannelToken"
                formControlProps={{
                  maxW: '28rem',
                }}
              />
            </PropertyStack>
          )}

          {[
            RoutingRuleConnectorTypeEnum.INTRADYN,
            RoutingRuleConnectorTypeEnum.GLOBALRELAY,
            RoutingRuleConnectorTypeEnum.PROOFPOINT,
          ].indexOf(form.values.connectorType) >= 0 && (
            <Box>
              <Center>
                <HStack>
                  <Icon
                    color={theme.color.base3}
                    fontSize="lg"
                    as={InformationCircleIcon}
                  ></Icon>

                  <Text textStyle="description" color={theme.color.base3}>
                    Support for this connector is coming soon.
                  </Text>
                </HStack>
              </Center>
            </Box>
          )}

          <PropertyStack
            label="Frequency"
            description="How often should data be sent?"
          >
            <FormRadioItemTextList
              name="frequency"
              choices={[
                {
                  item: (
                    <RoutingRuleFrequencySmudge
                      frequency={RoutingRuleFrequencyEnum.STREAM}
                    />
                  ),
                  label: 'Realtime',
                  value: RoutingRuleFrequencyEnum.STREAM,
                },
                {
                  item: (
                    <RoutingRuleFrequencySmudge
                      frequency={RoutingRuleFrequencyEnum.HOURLY}
                    />
                  ),
                  label: 'Hourly',
                  value: RoutingRuleFrequencyEnum.HOURLY,
                },
                {
                  item: (
                    <RoutingRuleFrequencySmudge
                      frequency={RoutingRuleFrequencyEnum.DAILY}
                    />
                  ),
                  label: 'Daily',
                  value: RoutingRuleFrequencyEnum.DAILY,
                },
              ]}
            />
          </PropertyStack>

          {/* <PropertyStack
            label="View status"
            description="Set this to publish for users to start using it, else it will be hidden from the user as draft."
          >
            <FormSingleSwitch name="IsActive">
              <Text textStyle="switchLabel">Publish</Text>
            </FormSingleSwitch>
          </PropertyStack> */}
        </Stack>
      </Stack>
    </>
  );
};
