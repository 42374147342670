import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FiTrash } from 'react-icons/fi';
import DialogContent from '../../../../../components/shared/DialogContent';
import useDeleteTemplateQuestion from '../../../../../services/api/templateQuestions/mutations/useDeleteTemplateQuestion';
import { IQuestion } from '../../../models/Question';
import { QuestionTypeMultiSelectSmudge } from '../QuestionTypeMultiSelectSmudge';
import { QuestionTypeTextLongSmudge } from '../QuestionTypeTextLongSmudge';
import { QuestionTypeTextShortSmudge } from '../QuestionTypeTextShortSmudge';

const TemplateQuestionDialogDelete = ({
  onClose,
  question,
  isOpen = false,
}: {
  onClose: () => void;
  question?: IQuestion | null;
  isOpen: boolean;
}) => {
  const cancelRef = React.useRef(null);

  const toast = useToast();
  const { mutateAsync: mutateAsyncDelete, isLoading } =
    useDeleteTemplateQuestion();

  const [smudgeElem, set_smudgeElem] = React.useState<React.ReactNode>();

  const smudgeLookup: Record<string, React.ReactNode> = React.useMemo(
    () => ({
      TEXT_LONG: <QuestionTypeTextLongSmudge />,
      TEXT_SHORT: <QuestionTypeTextShortSmudge />,
      MULTI_SELECT: <QuestionTypeMultiSelectSmudge />,
    }),
    []
  );

  const handleOnConfirm = async () => {
    if (question) {
      try {
        await mutateAsyncDelete({
          templateQuestionId: question.TemplateQuestionId,
        });
        toast({
          title: 'Question is removed',
          status: 'success',
          variant: 'subtle',
          duration: 2000,
          isClosable: true,
        });
        onClose();
      } catch (err) {
        console.error(err);
        toast({
          title: 'Failed to deleted',
          status: 'error',
          variant: 'subtle',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  React.useEffect(() => {
    if (question?.QuestionType) {
      if (smudgeLookup[question.QuestionType]) {
        set_smudgeElem(smudgeLookup[question.QuestionType]);
      }
    }
  }, [question]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size={'lg'}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody>
              <DialogContent
                title="Remove Question"
                description={'Please confirm you want to remove question:'}
                type="danger"
                icon={FiTrash}
              >
                <VStack>
                  <Box maxW={8}>{smudgeElem}</Box>
                  <Text>{question?.QuestionTitle}</Text>
                </VStack>
              </DialogContent>
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup size="sm" spacing={4}>
                <Button ref={cancelRef} onClick={onClose}>
                  Keep
                </Button>
                <Button
                  colorScheme="red"
                  isLoading={isLoading}
                  loadingText="Removing"
                  onClick={handleOnConfirm}
                >
                  Remove
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default TemplateQuestionDialogDelete;
