import { Box, SimpleGrid, Skeleton, Stack, Text } from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import React from 'react';
import AlertCard from '../../../../components/shared/AlertCard';
import { useListOrganizationLinks } from '../../../../services/api/organization/queries/useListOrganizationLinks';
import { OrganizationLinkCard } from './OrganizationLinkCard';

export const OrganizationPartnerships = () => {
  const { data: dataOrganizationLinks, isLoading: isLoadingLinks } =
    useListOrganizationLinks();

  return (
    <Stack spacing={8}>
      <AlertCard
        title="What is a linked organization?"
        type="info"
        variant="fill"
        //   action="Contact support"
        //   actionTo="/app/support"
        icon={InformationCircleIcon}
      >
        <Text as="span">
          If you have organization users that need to abide by another
          organization policies in addition to yours then you can link with that
          organization. This will allow you to double approve questions and
          messages that the users can use. Any user that has multiple
          organizations governing its account will receive the least privilege
          of the two policies.
        </Text>
      </AlertCard>
      <Box>
        {isLoadingLinks && (
          <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing={8}>
            {Array.from(Array(2), (e, idx) => {
              return (
                <Box key={`idx-${idx}`} minH="32rem">
                  <Skeleton
                    h="full"
                    w="full"
                    startColor="gray.100"
                    endColor="gray.300"
                  />
                </Box>
              );
            })}
          </SimpleGrid>
        )}
        <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing={8} zIndex={1}>
          {dataOrganizationLinks?.map((organizationlink, idx) => (
            <Box key={`${organizationlink.OrganizationId}-${idx}`}>
              <OrganizationLinkCard
                organizationLink={organizationlink}
              ></OrganizationLinkCard>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Stack>
  );
};
