import { Box } from '@chakra-ui/react';
import React from 'react';
import { PageHeader } from '../../../layouts/PageHeader';
import { QuestionsTable } from './questions/QuestionsTable';

const GuardrailsQuestionsBank = () => {
  return (
    <>
      <PageHeader
        title="Questions"
        description="Create approved question templates for your users to use."
      ></PageHeader>
      <Box py={8}>
        <QuestionsTable></QuestionsTable>
      </Box>
    </>
  );
};

export default GuardrailsQuestionsBank;
