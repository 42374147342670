import { Box, Stack, StackDivider } from '@chakra-ui/react';
import { FormikProps } from 'formik';
import React from 'react';
import { FormInputControl } from '../../../../components/forms';
import { FieldGroup } from '../../../../components/forms/FieldGroup';
import { PropertyGroup } from '../../../../components/forms/PropertyGroup';
import { IGovernorProfileWorkingForm } from '../../../../models/GovernorProfile';

const ValidateFormLogic = () => {
  // Grab values and submitForm from context
  // const { values, setFieldValue } =
  //   useFormikContext<IGovernorProfileWorkingForm>();

  return null;
};

interface IProps {
  form: FormikProps<IGovernorProfileWorkingForm>;
}

export const GovProfileAddressSettingsFormGroup = ({ form }: IProps) => {
  return (
    <>
      <ValidateFormLogic />
      <Stack spacing={4}>
        <FieldGroup title="Address">
          <Stack spacing="6" divider={<StackDivider />}>
            <PropertyGroup label="Address" level={0}>
              <Stack>
                <Box>
                  <FormInputControl
                    name="Address1"
                    placeholder="Street Address"
                    formControlProps={{
                      maxW: '24rem',
                    }}
                  />
                </Box>
                <FormInputControl
                  name="Address2"
                  placeholder="Apt, Suite, etc."
                  formControlProps={{
                    maxW: '12rem',
                  }}
                />
              </Stack>
            </PropertyGroup>
            <PropertyGroup label="City">
              <FormInputControl
                name="City"
                formControlProps={{
                  maxW: '16rem',
                }}
              />
            </PropertyGroup>
            <PropertyGroup label="State">
              <FormInputControl
                name="State"
                formControlProps={{
                  maxW: '16rem',
                }}
              />
            </PropertyGroup>
            <PropertyGroup label="Zip">
              <FormInputControl
                name="Zip"
                formControlProps={{
                  maxW: '8rem',
                }}
              />
            </PropertyGroup>
          </Stack>
        </FieldGroup>
      </Stack>
    </>
  );
};
