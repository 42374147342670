export interface OrganizationPartnerInviteIn {
  InviteLink: string;
  EmailsTo: string[];
  Note?: string;
}

export interface OrganizationUserInviteIn extends OrganizationPartnerInviteIn {}

export interface IOrganizationUpdate {
  OrganizationName: string;
  BrandImageUrl?: string;
  Email: string;
  AuditTrailEmail?: string;
  Phone: string;
  // Address
  Address1?: string;
  Address2?: string;
  City?: string;
  State?: string;
  Zip?: string;
}

export interface IOrganizationPublic extends IOrganizationUpdate {}

export interface IOrganization extends IOrganizationUpdate {
  CreatedAt: string;
  UpdatedAt?: string;
  OrganizationId: string;
  CreatedByUserId: string;
}

export interface IOrganizationWorkingForm extends IOrganizationUpdate {}

export interface IOrganizationUserApprovalWorkingForm {
  IsApproved: boolean;
}

export interface IOrganizationWorkingForm {}

export const initialOrganizationSettingsForm: IOrganizationWorkingForm = {
  OrganizationName: '',
  Email: '',
  AuditTrailEmail: '',
  Phone: '',
  Address1: '',
  Address2: '',
  City: '',
  State: '',
  Zip: '',
};

export const convertOrganizationToWorkingForm = (
  orgIn: IOrganization
): IOrganizationWorkingForm => {
  const formOut: IOrganizationWorkingForm = {
    OrganizationName: orgIn.OrganizationName || '',
    BrandImageUrl: orgIn.BrandImageUrl || '',
    Email: orgIn.Email || '',
    AuditTrailEmail: orgIn.AuditTrailEmail || '',
    Phone: orgIn.Phone || '',
    Address1: orgIn.Address1 || '',
    Address2: orgIn.Address2 || '',
    City: orgIn.City || '',
    State: orgIn.State || '',
    Zip: orgIn.Zip || '',
  };

  return formOut;
};
