import {
  Avatar,
  Box,
  Circle,
  Flex,
  HStack,
  Icon,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useMenuButton,
  UseMenuButtonProps,
} from '@chakra-ui/react';
import { ExternalLinkIcon, LogoutIcon } from '@heroicons/react/outline';
import { Link as ReachLink, navigate } from '@reach/router';
import * as React from 'react';
import { useAuth } from '../providers/AuthProvider';
import { useTheme } from '../providers/ThemeProvider';

const UserAvatar = () => {
  const { userProfile } = useAuth();
  const theme = useTheme();

  return (
    <Circle bg={theme.color.accent} p={1}>
      <Avatar
        size="sm"
        bg={theme.bg.base}
        src={userProfile?.BrandImageUrl}
        color={theme.color.accent}
        name={`${userProfile?.FirstName} ${userProfile?.LastName}`}
      />
    </Circle>
  );
};

const ProfileMenuButton = (props: UseMenuButtonProps) => {
  const buttonProps = useMenuButton(props);
  return (
    <Flex
      {...buttonProps}
      as="button"
      flexShrink={0}
      rounded="full"
      outline="0"
      _focus={{ shadow: 'outline' }}
    >
      <Box srOnly>Open user menu</Box>
      <UserAvatar />
    </Flex>
  );
};

export const ProfileDropdown = () => {
  const { userProfile, logout } = useAuth();
  const theme = useTheme();

  const handleSignout = async values => {
    try {
      await logout();
      // Startup amplitude
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Menu>
      <ProfileMenuButton />
      <MenuList
        rounded="sm"
        // shadow="lg"
        py="1"
        bg={theme.bg.base1}
        color={theme.color.base1}
        fontSize="sm"
      >
        <HStack px="3" py="4">
          <UserAvatar />
          <Box lineHeight="1">
            <Text fontWeight="semibold">
              {`${userProfile?.FirstName} ${userProfile?.LastName}`}
            </Text>
            <Text mt="1" fontSize="xs" color={theme.color.base3}>
              {userProfile?.Email}
            </Text>
          </Box>
        </HStack>
        <MenuItem textStyle={'navLink'}>
          <ReachLink className="w-full" to="/app/settings/profile">
            Your Profile
          </ReachLink>
        </MenuItem>
        <MenuItem>
          <Link
            w="full"
            isExternal
            textStyle={'navLink'}
            _hover={{ textDecoration: 'none' }}
            href="https://www.youtube.com/channel/UCOCC_XWXvT-z3WZZ9RDigGg/playlists"
          >
            <HStack spacing="4" justifyContent={'space-between'}>
              <Text as="span">Support Center</Text>
              <Icon as={ExternalLinkIcon} fontSize="lg" opacity={0.64} />
            </HStack>
          </Link>
        </MenuItem>
        <MenuItem
          onClick={handleSignout}
          textStyle={'navLink'}
          color={theme.color.danger}
        >
          <HStack w="full" spacing="4" justifyContent={'space-between'}>
            <Text textStyle={'navLink'} as="span">
              Sign out
            </Text>
            <Icon as={LogoutIcon} fontSize="lg" opacity={0.64} />
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
