// Auth util

export const setUser = (user: any) =>
  (window.localStorage.ccAppUser = JSON.stringify(user));

const getUser = () => {
  if (window.localStorage.ccAppUser) {
    let user = JSON.parse(window.localStorage.ccAppUser);
    return user ? user : {};
  }
  return {};
};

export const isLoggedIn = () => {
  const user = getUser();
  if (user) return !!user.username;
};

export const getCurrentUser = () => getUser();

export const logout = (callback: any) => {
  setUser({});
  callback();
};
