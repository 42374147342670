import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { useTheme } from '../../../../../providers/ThemeProvider';
import { IRoutingRule } from '../../../models/RoutingRule';

export const CellRoutingRuleName = ({ row }: CellProps<IRoutingRule>) => {
  const theme = useTheme();

  return (
    <Stack spacing={0} h="full" whiteSpace={'pre-wrap'}>
      <Text noOfLines={2} color={theme.color.base} textStyle={'formLabel'}>
        {row.original.RoutingRuleName}
      </Text>
    </Stack>
  );
};
