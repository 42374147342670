import { Badge, Link, Stack, StackDivider } from '@chakra-ui/react';
import { Link as ReachLink } from '@reach/router';
import React from 'react';
import { FieldGroup } from '../../../components/forms/FieldGroup';
import { PropertyGroup } from '../../../components/forms/PropertyGroup';
import { useTheme } from '../../../providers/ThemeProvider';

export const ManagementSettingsFormGroup = () => {
  const theme = useTheme();

  return (
    <>
      <Stack spacing={4}>
        <FieldGroup
          title="Account Settings"
          description="What settings should be enforced on the users account?"
        >
          <Stack spacing="6" divider={<StackDivider />}>
            <PropertyGroup
              label="Enforce MFA login"
              description="Users will have to configure MFA to login."
            >
              <Badge colorScheme={'green'}>Coming soon</Badge>
              {/* <FormSingleSwitch name="IsEnforceMFA">
                <Text textStyle="switchLabel">Enforce</Text>
              </FormSingleSwitch> */}
            </PropertyGroup>
            <PropertyGroup
              label="Mandatory routing policy"
              description="Set mandatory routing policies for the users account. This is a great way to enforce archiving routing is in place and cannot be changed by the user."
            >
              <Link
                as={ReachLink}
                textStyle={'description'}
                to={'/app/guardrails/routing'}
                whiteSpace="nowrap"
                target={'_blank'}
                color={theme.color.base}
              >
                Manage routing policies <span aria-hidden="true">→</span>
              </Link>
            </PropertyGroup>
          </Stack>
        </FieldGroup>
      </Stack>
    </>
  );
};
