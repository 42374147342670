import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import {
  IRoutingRule,
  IRoutingRuleUpdate,
} from '../../../../routes/guardrails/models/RoutingRule';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (routingruleUpdate: IRoutingRuleUpdate) => {
  const postData = {
    body: routingruleUpdate,
  };

  return API.post('users', '/api/v1/templateroutingrules/', postData);
};

const useCreateTemplateRoutingRule = () => {
  const queryClient = useQueryClient();

  return useMutation<IRoutingRule, any, IRoutingRuleUpdate>(
    dropsForm => makeApiRequest(dropsForm),
    {
      // useErrorBoundary: true,
      retry: 0,
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.templateRoutingRules], {
          exact: false,
        });
      },
    }
  );
};

export default useCreateTemplateRoutingRule;
