import { Box, Stack, Text } from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';
import React from 'react';
import { LibFullWidthSpinner } from '../../../components/lib';
import AlertCard from '../../../components/shared/AlertCard';
import { MutedLink } from '../../../components/shared/MutedLink';
import { Routes } from '../../../constants';
import { useQueryParam } from '../../../hooks/useQueryParam';
import { IGovernorSignup } from '../../../models/GovernorProfile';
import { useTheme } from '../../../providers/ThemeProvider';
import useCreateSignup from '../../../services/api/governorProfiles/mutations/useCreateSignup';
import { useReadPublicOrganization } from '../../../services/api/organization/queries/useReadPublicOrganization';
import { setUser } from '../../../services/auth';
import AuthSignUpForm from '../forms/AuthSignUpForm';

export interface SignUpError {
  key?: string;
  status: string;
}

// Submit form values
export interface IPropsUserSignupSubmit {
  username: string;
  password: string;
  organizationId?: string;
  organizationName?: string;
  firstName: string;
  lastName: string;
  phone: string;
  zip: string;
}

const AuthSignUp = () => {
  const organizationId = useQueryParam('org');
  const theme = useTheme();

  // Setup user profile
  const { mutateAsync } = useCreateSignup();

  const {
    data: dataOrganization,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization,
  } = useReadPublicOrganization(organizationId);

  const handleFormSubmit = async ({
    username,
    password,
    organizationId,
    organizationName,
    firstName,
    lastName,
    phone,
    zip,
  }: IPropsUserSignupSubmit) => {
    if (!isLoadingOrganization && !isErrorOrganization) {
      try {
        const resUser = await Auth.signUp({
          username,
          password,
          attributes: {
            // preferred_username: firstName,
            // given_name: firstName,
            // family_name: lastName,
            // "custom:organization": organization,
          },
        });

        const userSignup: IGovernorSignup = {
          Username: username,
          Email: username, // username is the email
          OrganizationName: organizationName,
          OrganizationId: organizationId,
          FirstName: firstName,
          LastName: lastName,
          Phone: phone,
          Zip: zip,
        };

        // Try to create the profile here
        try {
          await mutateAsync(userSignup);
        } catch (e) {
          console.error(e);
          console.log(
            'Failed to get signup ready, will proceed and create later'
          );
        }

        // Set user in storage
        try {
          setUser(resUser);
        } catch (e) {
          console.error(e);
          console.log(
            'Failed to set user to storage, will proceed and create later'
          );
        }

        let goToEmailConfirmationUrl: string = Routes.AuthEmailConfirmation;

        navigate(goToEmailConfirmationUrl, {
          state: { username },
        });
      } catch (error: any) {
        // Parse cognito responses
        let err: SignUpError = {
          status: 'Sign in failed',
        };

        if (error.code === 'UserNotFoundException') {
          err = {
            key: 'email',
            status: 'Email not found',
          };
        } else if (error.code === 'NotAuthorizedException') {
          err = {
            key: 'password',
            status: 'Please try password again',
          };
        } else if (error.code === 'UsernameExistsException') {
          err = {
            key: 'email',
            status: 'This username has already been used, sign in instead.',
          };
        }

        throw err;
      }
    }
  };

  return (
    <Box>
      {/* Form title */}
      <Text textStyle="headline">🍪 Create account</Text>
      <Text>
        Welcome! Create a governance account to setup guardrails in
        CurrentClient for your reps.
      </Text>

      <Box mt={4}>
        {isLoadingOrganization && (
          <LibFullWidthSpinner size="sm" thickness="4px"></LibFullWidthSpinner>
        )}
        {isErrorOrganization && (
          <AlertCard
            title="Organization exist?"
            type="notice"
            variant="fill"
            icon={ExclamationIcon}
          >
            <span>Failed to load the organization. Reach out to us.</span>
          </AlertCard>
        )}
      </Box>
      {organizationId && dataOrganization && (
        <Box
          p={4}
          borderWidth="1px"
          rounded="sm"
          borderColor={theme.color.accent}
          bg={theme.bg.accentSoft}
        >
          <Text textStyle="badge" color={theme.color.accent}>
            account organization
          </Text>
          <Text textStyle="headline">{dataOrganization.OrganizationName}</Text>
        </Box>
      )}

      {/* Form */}
      <Stack spacing={6} mt={6}>
        {/* SignUp Form */}
        <AuthSignUpForm
          handleFormSubmit={handleFormSubmit}
          organizationId={organizationId}
        ></AuthSignUpForm>

        {/* Actions */}
        <Stack>
          {/* Back to login */}
          <MutedLink
            to={Routes.AuthSignIn}
            label="Already have an account?"
          ></MutedLink>

          {/* Confirm email */}
          <MutedLink
            to={Routes.AuthEmailConfirmation}
            label="Confirm your email"
          ></MutedLink>

          {/* Resend email */}
          <MutedLink
            to={Routes.AuthResendEmailConfirmation}
            label="Resend confirmation"
          ></MutedLink>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AuthSignUp;
