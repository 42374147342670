import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  HStack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FiTrash } from 'react-icons/fi';
import DialogContent from '../../../../../components/shared/DialogContent';
import useDeleteTemplateRoutingRule from '../../../../../services/api/templateRoutingRules/mutations/useDeleteTemplateRoutingRule';
import { IRoutingRule } from '../../../models/RoutingRule';
import { RoutingRuleConnectorTypeSmudge } from '../RoutingRuleConnectorTypeSmudge';
import { RoutingRuleEventNameSmudge } from '../RoutingRuleEventNameSmudge';
import { RoutingRuleFrequencySmudge } from '../RoutingRuleFrequencySmudge';
import { RoutingRuleTypeMultiSelectSmudge } from '../RoutingRuleTypeMultiSelectSmudge';
import { RoutingRuleTypeTextLongSmudge } from '../RoutingRuleTypeTextLongSmudge';
import { RoutingRuleTypeTextShortSmudge } from '../RoutingRuleTypeTextShortSmudge';

const TemplateRoutingRuleDialogDelete = ({
  onClose,
  routingrule,
  isOpen = false,
}: {
  onClose: () => void;
  routingrule?: IRoutingRule | null;
  isOpen: boolean;
}) => {
  const cancelRef = React.useRef(null);

  const toast = useToast();
  const { mutateAsync: mutateAsyncDelete, isLoading } =
    useDeleteTemplateRoutingRule();

  const [smudgeElem, set_smudgeElem] = React.useState<React.ReactNode>();

  const smudgeLookup: Record<string, React.ReactNode> = React.useMemo(
    () => ({
      TEXT_LONG: <RoutingRuleTypeTextLongSmudge />,
      TEXT_SHORT: <RoutingRuleTypeTextShortSmudge />,
      MULTI_SELECT: <RoutingRuleTypeMultiSelectSmudge />,
    }),
    []
  );

  const handleOnConfirm = async () => {
    if (routingrule) {
      try {
        await mutateAsyncDelete({
          templateRoutingRuleId: routingrule.TemplateRoutingRuleId,
        });
        toast({
          title: 'Routing rule is removed',
          status: 'success',
          variant: 'subtle',
          duration: 2000,
          isClosable: true,
        });
        onClose();
      } catch (err: any) {
        if (err && err?.response?.status === 406) {
          toast({
            title: 'Unpublish rule before deleting',
            status: 'warning',
            variant: 'subtle',
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Failed to deleted',
            status: 'error',
            variant: 'subtle',
            duration: 2000,
            isClosable: true,
          });
        }
      }
    }
  };

  React.useEffect(() => {
    if (routingrule?.EventName) {
      if (smudgeLookup[routingrule.EventName]) {
        set_smudgeElem(smudgeLookup[routingrule.EventName]);
      }
    }
  }, [smudgeLookup, routingrule]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size={'lg'}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody>
              <DialogContent
                title="Remove Routing Rule"
                description={'Please confirm you want to remove routing rule:'}
                type="danger"
                icon={FiTrash}
              >
                <VStack>
                  {routingrule && (
                    <HStack spacing={4}>
                      <RoutingRuleEventNameSmudge
                        eventName={routingrule?.EventName}
                      />
                      <RoutingRuleConnectorTypeSmudge
                        connectorType={routingrule?.ConnectorType}
                      />
                      <RoutingRuleFrequencySmudge
                        frequency={routingrule?.Frequency}
                      />
                    </HStack>
                  )}
                  <Box maxW={8}>{smudgeElem}</Box>
                  <Text>{routingrule?.RoutingRuleName}</Text>
                </VStack>
              </DialogContent>
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup size="sm" spacing={4}>
                <Button ref={cancelRef} onClick={onClose}>
                  Keep
                </Button>
                <Button
                  colorScheme="red"
                  isLoading={isLoading}
                  loadingText="Removing"
                  onClick={handleOnConfirm}
                >
                  Remove
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default TemplateRoutingRuleDialogDelete;
