/*
DialogContent
*/

import { Box, Circle, Icon, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { useTheme } from '../../providers/ThemeProvider';

export interface DialogContentProps {
  title: string;
  description?: string;
  type?: 'notice' | 'warning' | 'danger' | 'info';
  icon?: any;
  children?: React.ReactElement;
}

export const DialogContent = ({
  title,
  description,
  type,
  icon,
  children,
}: DialogContentProps) => {
  const theme = useTheme();

  const [backgroundColor, set_backgroundColor] = React.useState<string>(
    theme.bg.noticeGradient
  );

  React.useEffect(() => {
    let backgroundColorUpdate = theme.bg.noticeGradient;

    if (type === 'danger') {
      backgroundColorUpdate = theme.bg.dangerGradient;
    } else if (type === 'warning') {
      backgroundColorUpdate = theme.bg.warningGradient;
    } else if (type === 'info') {
      backgroundColorUpdate = theme.bg.primaryGradient;
    } else if (type === 'notice') {
      backgroundColorUpdate = theme.bg.noticeGradient;
    }

    set_backgroundColor(backgroundColorUpdate);
  }, [type]);

  return (
    <VStack p={8}>
      <Circle bgGradient={backgroundColor} w={12} h={12} p={4}>
        <Icon
          h={6}
          w={6}
          boxShadow="2xl"
          color="white"
          as={icon ? icon : FiSearch}
        ></Icon>
      </Circle>

      <Stack spacing={8}>
        <Stack maxW={'md'} mx="auto" textAlign={'center'}>
          <Text textStyle={'headline'}>{title}</Text>
          <Text textStyle={'description'}>{description}</Text>
        </Stack>
        {children && <Box>{children}</Box>}
      </Stack>
    </VStack>
  );
};

export default DialogContent;
