import { Box } from '@chakra-ui/react';
import React from 'react';
import { PageHeader } from '../../../layouts/PageHeader';
import { MessagesTable } from './messages/MessagesTable';

const GuardrailsMessagesBank = () => {
  return (
    <>
      <PageHeader
        title="Messages"
        description="Create approved message templates for your users to use."
      ></PageHeader>
      <Box py={8}>
        <MessagesTable></MessagesTable>
      </Box>
    </>
  );
};

export default GuardrailsMessagesBank;
