import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import {
  runFormatTimeFromNow,
  runFormatTimeWithAt,
} from '../../../../../hooks/useFormatDate';
import { useTheme } from '../../../../../providers/ThemeProvider';
import { IRoutingRule } from '../../../models/RoutingRule';

export const CellRoutingRuleTime = ({ row }: CellProps<IRoutingRule>) => {
  const theme = useTheme();

  const timeIn = row.original.CreatedTime;

  const timeFromNow = runFormatTimeFromNow(timeIn);
  const timeAt = runFormatTimeWithAt(timeIn);
  return (
    <Stack spacing={0} h="full">
      <Text color={theme.color.base} textStyle={'formLabel'}>
        {timeAt}
      </Text>
      <Text color={theme.color.base3} textStyle={'description'}>
        {timeFromNow}
      </Text>
    </Stack>
  );
};
