import {
  Box,
  Center,
  HStack,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import { DuplicateIcon } from '@heroicons/react/outline';
import React from 'react';
import { runFormatDateFromNow } from '../../../../hooks/useFormatDate';
import { runFormatPhoneSimple } from '../../../../hooks/useFormatPhone';
import { useTheme } from '../../../../providers/ThemeProvider';
import { IOrganizationLink } from '../models/OrganizationLink';

interface OrganizationLinkCardProps {
  organizationLink?: IOrganizationLink;
}

export const OrganizationLinkCard = ({
  organizationLink,
}: OrganizationLinkCardProps) => {
  const theme = useTheme();

  const toast = useToast();

  const { onCopy: onCopyEmail, value: valueEmail } = useClipboard(
    organizationLink?.Email || ''
  );

  const { onCopy: onCopyPhone, value: valuePhone } = useClipboard(
    runFormatPhoneSimple(organizationLink?.Phone) || ''
  );

  const handleOnCopyEmail = () => {
    onCopyEmail();
    toast({
      title: 'Copied',
      description: valueEmail,
      status: 'success',
      variant: 'subtle',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleCopyPhone = () => {
    onCopyPhone();
    toast({
      title: 'Copied',
      description: valuePhone,
      status: 'success',
      variant: 'subtle',
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box
      borderWidth={'1px'}
      rounded="sm"
      py={12}
      px={12}
      h="full"
      pos="relative"
      w="full"
      bg={theme.bg.base}
    >
      <Stack spacing={4} h="full">
        <Center pos="relative" pb={8} minH={48} flex={1}>
          <Image
            pos="relative"
            alt={organizationLink?.OrganizationName}
            mx="auto"
            rounded="md"
            overflow={'hidden'}
            objectFit="contain"
            src={organizationLink?.BrandImageUrl}
            fallbackSrc="https://cc-west-prd-bucket-users.s3.us-west-2.amazonaws.com/public/general/media/image-placeholder.png"
            zIndex="1"
          />
        </Center>

        <Stack spacing={0}>
          <Text fontWeight="bold" mt="4">
            {organizationLink?.OrganizationName}
          </Text>
          <Text color={theme.color.base3} textStyle={'description'}>
            Linked {runFormatDateFromNow(organizationLink?.CreatedAt || '')}
          </Text>
        </Stack>

        <Stack spacing={2}>
          {organizationLink?.Email && (
            <HStack>
              <IconButton
                size="xs"
                aria-label="copy email"
                onClick={handleOnCopyEmail}
                icon={<Icon as={DuplicateIcon}></Icon>}
              ></IconButton>
              <Text wordBreak="break-all">{organizationLink?.Email}</Text>
            </HStack>
          )}

          {organizationLink?.Phone && (
            <HStack>
              <IconButton
                size="xs"
                aria-label="copy phone number"
                onClick={handleCopyPhone}
                icon={<Icon as={DuplicateIcon}></Icon>}
              ></IconButton>
              <Text wordBreak="break-all">
                {runFormatPhoneSimple(organizationLink?.Phone)}
              </Text>
            </HStack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
