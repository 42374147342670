import { Column } from 'react-table';
import { IOrganizationEvent } from '../../models/OrganizationEvent';
import { CellEventMeta } from './CellEventMeta';
import { CellEventTime } from './CellEventTime';
import { CellEventUser } from './CellEventUser';

interface IProps {}

export const COL_ID_EVENT_TYPE = 'COL_ID_EVENT_TYPE';

export const OrganizationEventsColumns = (props: IProps) =>
  [
    {
      Header: 'User',
      accessor: 'EventUserName',
      Cell: CellEventUser,
      sortType: 'basic',
    },
    {
      id: COL_ID_EVENT_TYPE,
      // Cell: CellOrgStatus,
      accessor: 'EventRecordType',
      sortType: 'basic',
      filter: 'filterEventType',
    },
    {
      Header: 'Event',
      accessor: 'EventName',
    },
    {
      Header: 'Time',
      Cell: CellEventTime,
      accessor: 'EventTime',
    },
    {
      Header: 'Meta',
      width: 40,
      Cell: CellEventMeta,
    },
    // {
    //   Header: "Reported Status",
    //   minWidth: 70,
    //   width: 70,
    // },
  ] as Column<IOrganizationEvent>[];
