import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {
  AppTrackEventsEnum,
  useAppTrack,
} from '../../../../../providers/AppTrackProvider';
import { useTheme } from '../../../../../providers/ThemeProvider';
import useCreateTemplateMessage from '../../../../../services/api/templateMessages/mutations/useCreateTemplateMessage';
import { MessageForm } from '../../../forms/messages/MessageForm';
import { IMessage, IMessageUpdate } from '../../../models/Message';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: IMessage | null;
}

export const TemplateMessageModalAdd = ({
  isOpen,
  onClose,
  initialValues,
}: IProps) => {
  const theme = useTheme();

  const { mutateAsync: mutateAsyncCreate } = useCreateTemplateMessage();

  const { logEvent: appTrackLogEvent } = useAppTrack();

  const handleSuccess = (dataIn: IMessage) => {
    // Close modal when mutate is successful
    appTrackLogEvent(AppTrackEventsEnum.MESSAGE_CREATED, {
      message_id: dataIn.MessageId,
    });
    handleFormClose();
  };

  const handleFormSubmit = async (data: IMessageUpdate) => {
    // Post data to api

    try {
      await mutateAsyncCreate(data, {
        onSuccess: handleSuccess,
      });
    } catch (err) {
      console.log(err);
      // Throw error for downstream form to handle
      throw err;
    }
  };

  const handleFormClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      size="6xl"
      colorScheme="primary"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack spacing={0}>
            <Text>Add Message</Text>
            <Text textStyle="description" color={theme.color.base3}>
              Create new message that will be available for the users in your
              organization to use in their mass message outreaches.
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={8}>
          <MessageForm
            onSubmit={handleFormSubmit}
            onCancel={handleFormClose}
            actionText={'Add message'}
            message={initialValues}
          ></MessageForm>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
