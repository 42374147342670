import { CellProps, Column, Row } from 'react-table';
import { IRoutingRule } from '../../models/RoutingRule';
import { CellRoutingRuleAction } from './cells/CellRoutingRuleAction';
import { CellRoutingRuleConnector } from './cells/CellRoutingRuleConnector';
import { CellRoutingRuleEvent } from './cells/CellRoutingRuleEvent';
import { CellRoutingRuleFrequency } from './cells/CellRoutingRuleFrequency';
import { CellRoutingRuleName } from './cells/CellRoutingRuleName';
import { CellRoutingRulePublish } from './cells/CellRoutingRulePublish';
import { CellRoutingRuleTime } from './cells/CellRoutingRuleTime';

export const ROUTINGRULE_EVENT_ID = 'routingruleEventColId';
export const ROUTINGRULE_PUBLISH_ID = 'routingruleStatusColId';

interface IProps {
  onViewRoutingRule: (row: Row<IRoutingRule>) => void;
  onDeleteRoutingRule: (row: Row<IRoutingRule>) => void;
}

export const RoutingRulesColumns = ({
  onViewRoutingRule,
  onDeleteRoutingRule,
}: IProps) =>
  [
    {
      Header: 'Name',
      Cell: CellRoutingRuleName,
      accessor: 'RoutingRuleName',
      sortType: 'basic',
      width: 50,
    },
    {
      id: ROUTINGRULE_EVENT_ID,
      Header: 'Event',
      Cell: CellRoutingRuleEvent,
      accessor: 'RoutingRuleEventName',
      sortType: 'basic',
    },
    {
      Header: 'Connector',
      Cell: CellRoutingRuleConnector,
      accessor: 'RoutingRuleConnectorType',
      sortType: 'basic',
    },
    {
      Header: 'Frequency',
      Cell: CellRoutingRuleFrequency,
      accessor: 'RoutingRuleFrequency',
      sortType: 'basic',
    },
    // {
    //   Header: 'Description',
    //   accessor: 'RoutingRuleDescription',
    //   sortType: 'basic',
    // },
    {
      Header: 'Created',
      accessor: 'CreatedTime',
      Cell: CellRoutingRuleTime,
      sortType: 'basic',
    },
    {
      id: ROUTINGRULE_PUBLISH_ID,
      Header: 'Publish',
      accessor: 'IsEnabled',
      sortType: 'basic',
      Cell: CellRoutingRulePublish,
      filter: 'statusEnabledFilter',
    },
    {
      Header: 'Actions',
      Cell: (props: CellProps<IRoutingRule>) => {
        return (
          <CellRoutingRuleAction
            onViewRoutingRule={() => onViewRoutingRule(props.row)}
            onDeleteRoutingRule={() => onDeleteRoutingRule(props.row)}
          />
        );
      },
    },
    // {
    //   Header: "Reported Status",
    //   minWidth: 70,
    //   width: 70,
    // },
  ] as Column<IRoutingRule>[];
