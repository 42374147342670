import { Divider, FlexProps, Stack } from '@chakra-ui/react';
import {
  ChatAltIcon,
  OfficeBuildingIcon,
  QuestionMarkCircleIcon,
  TruckIcon,
} from '@heroicons/react/outline';
import * as React from 'react';
// import Glob2PNG from '../../../assets/art/glob2.png';
import { SubNavLink } from '../../../layouts/SubNavLink';
import { SupportNavLink } from '../../../layouts/SupportNavLink';

export const GuardrailsSidebar = (props: FlexProps) => {
  return (
    <Stack h="full" spacing="6" as="nav" aria-label="Guardrails Navigation">
      <Stack spacing="0">
        <SubNavLink
          label="Policies"
          to="/app/guardrails/policies"
          icon={OfficeBuildingIcon}
        />
        <SubNavLink
          label="Question Bank"
          to="/app/guardrails/questions"
          icon={QuestionMarkCircleIcon}
          // isNewBadge
        />
        <SubNavLink
          label="Message Bank"
          to="/app/guardrails/messages"
          icon={ChatAltIcon}
          // isNewBadge
        />
        <SubNavLink
          label="Routing"
          to="/app/guardrails/routing"
          icon={TruckIcon}
          isNewBadge
        />
      </Stack>

      <Divider />

      <SupportNavLink></SupportNavLink>
    </Stack>
  );
};
