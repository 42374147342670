import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import {
  IQuestion,
  IQuestionUpdate,
} from '../../../../routes/guardrails/models/Question';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (questionUpdate: IQuestionUpdate) => {
  const postData = {
    body: questionUpdate,
  };

  return API.post('users', '/api/v1/templatequestions/', postData);
};

const useCreateTemplateQuestion = () => {
  const queryClient = useQueryClient();

  return useMutation<IQuestion, any, IQuestionUpdate>(
    dropsForm => makeApiRequest(dropsForm),
    {
      // useErrorBoundary: true,
      retry: 0,
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.templateQuestions], {
          exact: false,
        });
      },
    }
  );
};

export default useCreateTemplateQuestion;
