import { Box, Button, Icon, Stack, Text } from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { FormikProps } from 'formik';
import React from 'react';
import {
  FormInputControl,
  FormTextareaControl,
} from '../../../../components/forms';
import { PropertyStack } from '../../../../components/forms/PropertyStack';
import { IMessageWorkingForm } from '../../models/Message';

interface IProps {
  form: FormikProps<IMessageWorkingForm>;
}

export const MessageFormGroup = ({ form }: IProps) => {
  const [isShowPersonalize, set_isShowPersonalize] =
    React.useState<boolean>(false);

  const handleOnShowPersonalize = () => {
    set_isShowPersonalize(!isShowPersonalize);
  };

  return (
    <>
      <Stack spacing={4}>
        <Stack spacing="6">
          <PropertyStack
            label="Template Title"
            description="Give this a title that will help you identify it."
          >
            <Stack>
              <FormInputControl
                name="MessageTitle"
                formControlProps={{
                  maxW: '28rem',
                }}
              />
            </Stack>
          </PropertyStack>

          <PropertyStack
            label="Message Body"
            description="This is the message content."
          >
            <Stack>
              <FormTextareaControl
                name="MessageBody"
                formControlProps={{
                  w: '32rem',
                }}
              />
              {/* Under body helpers */}
              <Stack maxW={'30rem'}>
                <Box>
                  <Button
                    leftIcon={<Icon as={InformationCircleIcon} />}
                    variant="link"
                    onClick={handleOnShowPersonalize}
                    colorScheme={'primary'}
                    size="sm"
                  >
                    How can I personalize this message?
                  </Button>
                </Box>

                {/* Under body personalize message */}
                {isShowPersonalize && (
                  <Stack>
                    <Text textStyle={'description'}>
                      If you want to include the contacts first and/or last
                      name, you can put in a placeholder, and it will be swapped
                      out with the real name before it is sent. For example:
                    </Text>
                    <Text fontStyle={'italic'}>
                      {`Dear $\{FirstName} $\{LastName}, thanks for being splendid.`}
                    </Text>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </PropertyStack>

          {/* <PropertyStack
            label="View status"
            description="Set this to publish for users to start using it, else it will be hidden from the user as draft."
          >
            <FormSingleSwitch name="IsActive">
              <Text textStyle="switchLabel">Publish</Text>
            </FormSingleSwitch>
          </PropertyStack> */}
        </Stack>
      </Stack>
    </>
  );
};
