export enum QueryKeys {
  governorProfiles = "governorProfiles",
  organizations = "organizations",
  guardrails = "guardrails",
  templateQuestions = "templateQuestions",
  templateMessages = "templateMessages",
  templateRoutingRules = "templateRoutingRules",
  organizationLinks = "organizationLinks",
  organizationEvents = "organizationEvents",
}
