import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { IPaginatedOrganizationEvent } from '../../../../routes/organization/models/OrganizationEvent';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = ({
  eventRecordId,
}: {
  eventRecordId?: string;
}): Promise<IPaginatedOrganizationEvent> => {
  const qParams = {
    queryStringParameters: {
      ...(eventRecordId && { eventRecordId: eventRecordId }),
    },
  };

  return API.get('users', '/api/v1/organizations/events', qParams);
};

export const useListOrganizationEvents = (eventRecordId?: string) => {
  return useQuery<IPaginatedOrganizationEvent>(
    [QueryKeys.organizations, 'events', eventRecordId],
    () => makeApiRequest({ eventRecordId }),
    {
      retry: 1,
    }
  );
};
