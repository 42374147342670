import { Box, Circle, Icon, Image } from '@chakra-ui/react';
import { BanIcon, CheckIcon } from '@heroicons/react/outline';
import React from 'react';
import { useTheme } from '../../providers/ThemeProvider';

interface IProps {
  brandImageUrl?: string;
  isApproved?: boolean;
  name?: string;
}

export const GovernanceSmudge = ({
  brandImageUrl,
  isApproved,
  name,
}: IProps) => {
  const theme = useTheme();

  return (
    <Box position={'relative'}>
      <Image
        borderRadius={4}
        border={'1px'}
        borderColor={theme.bg.base3}
        p={2}
        minH={10}
        maxW={24}
        h={10}
        objectFit={'contain'}
        src={brandImageUrl}
        name={name}
      />
      <Box
        pos={'absolute'}
        bottom={-2}
        right={-2}
        rounded="full"
        overflow={'hidden'}
      >
        {isApproved ? (
          <Circle
            boxSize="1.25em"
            border={'2px'}
            borderColor={theme.bg.base}
            bg={theme.color.success}
            color={theme.color.contrast}
          >
            <Icon as={CheckIcon}></Icon>
          </Circle>
        ) : (
          <Circle
            boxSize="1.25em"
            border={'2px'}
            borderColor={theme.bg.base}
            bg={theme.color.danger}
            color={theme.color.contrast}
          >
            <Icon as={BanIcon}></Icon>
          </Circle>
        )}
      </Box>
    </Box>
  );
};
