import { Box, BoxProps, Image } from '@chakra-ui/react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { FunctionComponent } from 'react';
import LightLogoMark from '../assets/art/dark/logo_mark.png';
import { useTheme } from '../providers/ThemeProvider';
import SettingsMFA from '../routes/settings/components/SettingsMFA';
import SettingsNewPartner from '../routes/settings/components/SettingsNewPartner';
import SettingsOrganization from '../routes/settings/components/SettingsOrganization';
import SettingsPartnerships from '../routes/settings/components/SettingsPartnerships';
import SettingsProfile from '../routes/settings/components/SettingsProfile';
import { SettingsSidebar } from '../routes/settings/components/SettingsSidebar';
import { PageContainer } from './PageContainer';
import { PageContent } from './PageContent';
import { PageSidebar } from './PageSidebar';

const SettingsLayout = () => {
  const theme = useTheme();
  const opacity = theme.useColorModeValue(0.8, 0.1);

  return (
    <PageContainer>
      <PageSidebar overflow={'hidden'} position="relative">
        <SettingsSidebar />
        <Box
          pos="absolute"
          bottom={{ base: '1%', lg: '-20' }}
          left={{ base: '-20', lg: '-20' }}
          transform="rotate(7deg)"
          rounded={'sm'}
          className="group"
          overflow="hidden"
          opacity={opacity}
        >
          <Image
            w="48"
            objectFit="contain"
            src={LightLogoMark}
            transition="all 0.2s"
            _groupHover={{ transform: 'scale(1.01)' }}
          />
        </Box>
      </PageSidebar>
      <PageContent>
        {/* Routes */}
        <Box as={Router} primary={false}>
          <Redirect default from="/" to="organization" noThrow />
          <Route path="/organization" component={SettingsOrganization} />
          <Route path="/partnerships" component={SettingsPartnerships} />
          <Route
            path="/new-partner/:organizationId"
            component={SettingsNewPartner}
          />
          <Route path="/profile" component={SettingsProfile} />
          <Route path="/mfa" component={SettingsMFA} />
        </Box>
      </PageContent>
    </PageContainer>
  );
};

export default SettingsLayout;

const Route: FunctionComponent<
  {
    component: React.ComponentType<any>;
  } & RouteComponentProps &
    BoxProps
> = ({ component: Component, ...rest }) => {
  return <Component {...rest} />;
};
