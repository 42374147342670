import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { IRoutingRule } from '../../../../routes/guardrails/models/RoutingRule';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (templateId: string) => {
  const payload = {};

  return API.post(
    'users',
    `/api/v1/templateroutingrules/${templateId}/publish`,
    payload
  );
};

const usePublishTemplateRoutingRule = () => {
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation<
    IRoutingRule,
    any,
    {
      templateId: string;
    },
    {
      previousRecords: IRoutingRule | any;
    }
  >(
    ({ templateId }) => {
      return makeApiRequest(templateId);
    },
    {
      retry: 0,
      onMutate: async ({ templateId }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([QueryKeys.templateRoutingRules]);

        // Snapshot the previous value
        const previousRecords = queryClient.getQueryData<IRoutingRule[]>([
          QueryKeys.templateRoutingRules,
        ]);

        const targetIdx = previousRecords?.findIndex(
          routingrule => routingrule.TemplateRoutingRuleId === templateId
        );

        if (targetIdx !== undefined && targetIdx > -1 && previousRecords) {
          previousRecords[targetIdx] = {
            ...previousRecords[targetIdx],
            IsEnabled: true,
          };
        }

        const updatedRecords = previousRecords ? [...previousRecords] : [];

        // Optimistically update to the new value
        queryClient.setQueryData(
          [QueryKeys.templateRoutingRules],
          updatedRecords
        );

        // Return a context object with the snapshotted value
        return { previousRecords };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, newTodo, context) => {
        if (context) {
          queryClient.setQueryData(
            [QueryKeys.templateRoutingRules],
            context.previousRecords
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.templateRoutingRules], {
          exact: false,
        });
      },
    }
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

export default usePublishTemplateRoutingRule;
