// makeStore

// Used to setup store and dispatch contexts for managing state
// https://kentcdodds.com/blog/how-to-use-react-context-effectively
// https://dev.to/ankitjena/ciao-redux-using-react-hooks-and-context-effectively-398j

// TODO: prob a way to get this to typescript
// -> https://kentcdodds.com/blog/how-to-use-react-context-effectively#typescript--flow

import React from "react"

const makeStore = (userReducer, initialState, key, useStorage = true) => {

  const storeContext = React.createContext()
  const dispatchContext = React.createContext()

  const StoreProvider = ({ children }) => {

    // Get initial data out of localstorage if stored there
    try {
      if (useStorage) {
        initialState = JSON.parse(localStorage.getItem(key)) || initialState
      }
    } catch {}


    // Wire in the provided reducer, saving to localstorage if enabled
    const reducer = (state, action) => {
      const newState = userReducer(state, action)
      if (useStorage) {
        localStorage.setItem(key, JSON.stringify(newState))
      }

      return newState
    }

    const [store, dispatch] = React.useReducer(reducer, initialState)

    // Wrap in seperated dispatch and store provider
    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={store}>
          {children}
        </storeContext.Provider>
      </dispatchContext.Provider>
    )
  }

  const useStore = () => {
    const context = React.useContext(storeContext)
    if (context === undefined) {
      throw new Error('useStore must be used within a Provider')
    }
    return context
  }

  const useDispatch = () => {
    const context = React.useContext(dispatchContext)
    if (context === undefined) {
      throw new Error('useDispatch must be used within a Provider')
    }
    return context
  }


  return [StoreProvider, useStore, useDispatch]
}


export default makeStore