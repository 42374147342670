import React, { useMemo } from 'react';
import {
  Row,
  TableInstance,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { PAGE_SIZE_OPTIONS } from '../../../constants';
import { useAuth } from '../../../providers/AuthProvider';
import useListTemplateMessages from '../../../services/api/templateMessages/queries/useListTemplateMessages';
import { IOrganizationLink } from '../../settings/partnerships/models/OrganizationLink';
import { MessagesColumns } from '../components/messages/MessagesColumns';
import { IMessage } from '../models/Message';

interface ILinkMessageAction {
  message: IMessage;
  organizationLink: IOrganizationLink;
}

export interface IMessagesTableResource extends TableInstance<IMessage> {
  isErrorData: boolean;
  isLoadingData: boolean;
  editMessage: IMessage | null;
  setEditMessage: React.Dispatch<React.SetStateAction<IMessage | null>>;
  deleteMessage: IMessage | null;
  setDeleteMessage: React.Dispatch<React.SetStateAction<IMessage | null>>;
  duplicateMessage: IMessage | null;
  setDuplicateMessage: React.Dispatch<React.SetStateAction<IMessage | null>>;
  linkMessage: ILinkMessageAction | null;
  setLinkMessage: React.Dispatch<
    React.SetStateAction<ILinkMessageAction | null>
  >;
  viewHistoryMessage: IMessage | null;
  setViewHistoryMessage: React.Dispatch<React.SetStateAction<IMessage | null>>;
}

export const useMessagesTable = (): IMessagesTableResource => {
  const { userProfile } = useAuth();
  const [editMessage, set_editMessage] = React.useState<IMessage | null>(null);
  const [duplicateMessage, set_duplicateMessage] =
    React.useState<IMessage | null>(null);
  const [viewHistoryMessage, set_viewHistoryMessage] =
    React.useState<IMessage | null>(null);
  const [deleteMessage, set_deleteMessage] = React.useState<IMessage | null>(
    null
  );
  const [linkMessage, set_linkMessage] =
    React.useState<ILinkMessageAction | null>(null);

  const filterTypes = React.useMemo(
    () => ({
      globalFilter: (
        rows: Row<IMessage>[],
        columnIds: string[],
        filterValue: string
      ) => {
        return rows.filter(row => {
          const rowValue = `${row.original.MessageTitle} ${row.original.MessageBody}`;

          return rowValue
            .toLowerCase()
            .includes(String(filterValue).toLowerCase());
        });
      },
      statusApprovedFilter: (
        rows: Row<IMessage>[],
        columnIds: string[],
        filterValue: boolean
      ) => {
        return rows.filter(row => {
          let isRowApproved;

          if (
            row.original.Governance &&
            row.original.Governance[userProfile.OrganizationId]
          ) {
            isRowApproved =
              row.original.Governance[userProfile.OrganizationId].IsApproved;
          }

          return filterValue !== null ? isRowApproved === filterValue : true;
        });
      },
    }),
    [userProfile]
  );

  const {
    data: tableData,
    isError: isErrorData,
    isLoading: isLoadingData,
  } = useListTemplateMessages();

  let data = useMemo(() => tableData ?? [], [tableData]);

  const handleOnEditMessage = (message: IMessage) => {
    set_editMessage(message);
  };

  const handleOnViewHistoryMessage = (message: IMessage) => {
    set_viewHistoryMessage(message);
  };

  const handleOnDuplicateMessage = (message: IMessage) => {
    set_duplicateMessage(message);
  };

  const handleOnDeleteMessage = (message: IMessage) => {
    set_deleteMessage(message);
  };

  const handleOnLinkMessage = (
    message: IMessage,
    organizationLink: IOrganizationLink
  ) => {
    set_linkMessage({ message, organizationLink });
  };

  const columns = React.useMemo(
    () =>
      MessagesColumns({
        onEditMessage: (row: Row<IMessage>) => {
          handleOnEditMessage(row.original);
        },
        onViewHistory: (row: Row<IMessage>) => {
          handleOnViewHistoryMessage(row.original);
        },
        onCopyMessage: (row: Row<IMessage>) => {
          handleOnDuplicateMessage(row.original);
        },
        onDeleteMessage: (row: Row<IMessage>) => {
          handleOnDeleteMessage(row.original);
        },
        onLinkMessage: (
          row: Row<IMessage>,
          organizationLink: IOrganizationLink
        ) => {
          handleOnLinkMessage(row.original, organizationLink);
        },
      }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:

      width: 40, // width is used for both the flex-basis and flex-grow
    }),
    []
  );

  const table = useTable<IMessage>(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: PAGE_SIZE_OPTIONS[0] },
      globalFilter: 'globalFilter',
      filterTypes: filterTypes,
    },
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination

    // useRowSelect,
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  return {
    ...table,
    isLoadingData,
    isErrorData,
    editMessage,
    setEditMessage: set_editMessage,
    deleteMessage,
    setDeleteMessage: set_deleteMessage,
    duplicateMessage,
    setDuplicateMessage: set_duplicateMessage,
    linkMessage,
    setLinkMessage: set_linkMessage,
    viewHistoryMessage,
    setViewHistoryMessage: set_viewHistoryMessage,
  };
};

export default useMessagesTable;
