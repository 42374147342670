import { Icon, Stack, Text } from '@chakra-ui/react';
import { FlagIcon } from '@heroicons/react/outline';
import React from 'react';
import { CellProps } from 'react-table';
import { SingleSwitch } from '../../../../../components/forms';
import { LibFullWidthSpinner } from '../../../../../components/lib';
import { useTheme } from '../../../../../providers/ThemeProvider';
import usePublishTemplateRoutingRule from '../../../../../services/api/templateRoutingRules/mutations/usePublishTemplateRoutingRule';
import useWithdrawTemplateRoutingRule from '../../../../../services/api/templateRoutingRules/mutations/useWithdrawTemplateRoutingRule';
import { IRoutingRule } from '../../../models/RoutingRule';

export const CellRoutingRulePublish = ({ row }: CellProps<IRoutingRule>) => {
  const [valStatus, set_valStatus] = React.useState<boolean>(
    row.original.IsEnabled
  );

  const {
    mutateAsync: mutateAsyncPublish,
    isLoading: isLoadingPublish,
    isError: isErrorPublish,
  } = usePublishTemplateRoutingRule();
  const {
    mutateAsync: mutateAsyncWithdraw,
    isLoading: isLoadingWithdraw,
    isError: isErrorWithdraw,
  } = useWithdrawTemplateRoutingRule();
  const theme = useTheme();

  const handleOnChange = async evt => {
    const valStatusUpdate = evt.target.checked;

    let runOperation = mutateAsyncPublish;

    if (valStatusUpdate === false) {
      runOperation = mutateAsyncWithdraw;
    }

    try {
      await runOperation({
        templateId: row.original.TemplateRoutingRuleId,
      });

      set_valStatus(valStatusUpdate);
    } catch (err) {}
  };

  React.useEffect(() => {
    if (row?.original) {
      set_valStatus(row.original.IsEnabled);
    }
  }, [row.original]);

  return (
    <Stack direction="row" spacing="4" alignItems={'center'} h="full">
      <SingleSwitch
        name={`${row.original.TemplateRoutingRuleId}-IsEnabled`}
        onChange={handleOnChange}
        isDisabled={isLoadingPublish || isLoadingWithdraw}
        isChecked={valStatus}
      >
        {(isErrorPublish || isErrorWithdraw) && (
          <Icon color={theme.color.danger} as={FlagIcon} />
        )}
        {isLoadingPublish || isLoadingWithdraw ? (
          <LibFullWidthSpinner h={4} w={4} thickness={'2px'} />
        ) : (
          <Text textStyle="switchLabel">Publish</Text>
        )}
      </SingleSwitch>
    </Stack>
  );
};
