import { Box, HStack, Icon, Text, Tooltip } from '@chakra-ui/react';
import { FlagIcon, RefreshIcon, TrashIcon } from '@heroicons/react/outline';
import React from 'react';
import { CellProps } from 'react-table';
import { SingleSwitch } from '../../../../../components/forms';
import { LibFullWidthSpinner } from '../../../../../components/lib';
import { useAuth } from '../../../../../providers/AuthProvider';
import { useTheme } from '../../../../../providers/ThemeProvider';
import useUpdateTemplateQuestionApproval from '../../../../../services/api/templateQuestions/mutations/useUpdateTemplateQuestionApproval';
import {
  IQuestion,
  ITemplateQuestionApprovalWorkingForm,
} from '../../../models/Question';

export const CellQuestionApprove = ({ row }: CellProps<IQuestion>) => {
  const { userProfile } = useAuth();

  const [valStatus, set_valStatus] = React.useState<boolean>(
    row.original.IsActive
  );

  const { mutateAsync, isLoading, isError } =
    useUpdateTemplateQuestionApproval();

  const theme = useTheme();

  const handleOnChange = async evt => {
    const valStatusUpdate = evt.target.checked;

    const updateData: ITemplateQuestionApprovalWorkingForm = {
      IsApproved: valStatusUpdate,
    };

    try {
      await mutateAsync({
        templateId: row.original.TemplateQuestionId,
        updateData,
      });

      set_valStatus(valStatusUpdate);
    } catch (err) {}
  };

  React.useEffect(() => {
    if (userProfile.OrganizationId && row?.original.Governance) {
      const goveranceObj = row.original.Governance[userProfile.OrganizationId];
      set_valStatus(goveranceObj.IsApproved || false);
    }
  }, [userProfile, row]);

  return (
    <HStack direction="row" spacing="4" alignItems={'center'} h="full">
      <SingleSwitch
        name={`${row.original.QuestionId}-IsApproved`}
        onChange={handleOnChange}
        isDisabled={
          isLoading || row.original.IsProcessing || row.original.IsDeleting
        }
        isChecked={valStatus}
      >
        {isError && <Icon color={theme.color.danger} as={FlagIcon} />}
        {isLoading ? (
          <LibFullWidthSpinner h={4} w={4} thickness={'2px'} />
        ) : (
          <Text textStyle="switchLabel">Approved</Text>
        )}
      </SingleSwitch>

      {row.original.IsProcessing && (
        <Box pos={'relative'}>
          <Tooltip
            label="Updates actively being applied to your organization"
            fontSize="xs"
          >
            <Text as="span">
              <Icon
                h={6}
                w={6}
                color={theme.color.success}
                style={{
                  animation: 'spin 2s infinite linear',
                }}
                as={RefreshIcon}
              ></Icon>
            </Text>
          </Tooltip>
        </Box>
      )}
      {row.original.IsDeleting && (
        <Box pos={'relative'}>
          <Tooltip
            label="Updates actively being applied to your organization"
            fontSize="xs"
          >
            <Text as="span">
              <Icon
                h={6}
                w={6}
                color={theme.color.danger}
                style={{
                  animation: 'pulse 2s infinite linear',
                }}
                as={TrashIcon}
              ></Icon>
            </Text>
          </Tooltip>
        </Box>
      )}
    </HStack>
  );
};
