import { Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';
import { useTheme } from '../../providers/ThemeProvider';

export const LoadingTableStub = () => {
  const theme = useTheme();
  return (
    <Stack>
      {Array.from(Array(3), (e, i) => {
        return (
          <Skeleton
            key={i}
            height={16}
            startColor={theme.bg.base1}
            endColor={theme.bg.base3}
          />
        );
      })}
    </Stack>
  );
};
