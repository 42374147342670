import {
  HStack,
  PinInput,
  PinInputField,
  PinInputProps,
  StackProps,
} from "@chakra-ui/react";
import { FastField, useFormikContext } from "formik";
import React from "react";
import { BaseProps } from "../baseProps";
import { FormControl } from "../FormControl/FormControl";

export type PinInputControlProps = BaseProps &
  Omit<PinInputProps, "children"> & {
    pinLength: number;
    stackProps?: StackProps;
  };

export const PinInputControl = (props: PinInputControlProps) => {
  const {
    name,
    label,
    pinLength,
    stackProps,
    formControlProps,
    ...rest
  } = props;
  const renderedPinInputFields = [...Array(pinLength)].map((_noop, i) => (
    <PinInputField key={i} />
  ));
  const { justifyContent = "center", ...stackPropsRest } = stackProps || {};

  return (
    <FormControl name={name} label={label} {...formControlProps}>
      <HStack justifyContent={justifyContent} {...stackPropsRest}>
        <PinInput {...rest}>{renderedPinInputFields}</PinInput>
      </HStack>
    </FormControl>
  );
};

export type FormPinInputControlProps = PinInputControlProps;

export const FormPinInputControl = (props: FormPinInputControlProps) => {
  const { name, label, formControlProps, ...rest } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <PinInputControl
          {...rest}
          {...field}
          onChange={(value: string) => setFieldValue(name, value)}
          formControlProps={{ ...meta, label, ...formControlProps }}
          isInvalid={meta.touched && meta.error}
        />
      )}
    </FastField>
  );
};
