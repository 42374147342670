import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { IMessage } from '../../../../routes/guardrails/models/Message';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (templateMessageId): Promise<IMessage> => {
  return API.post(
    'users',
    `/api/v1/templatemessages/${templateMessageId}/remove`,
    {}
  );
};

const useDeleteTemplateMessage = () => {
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation<IMessage, any, { templateMessageId: string }, any>(
    ({ templateMessageId }) => makeApiRequest(templateMessageId),
    {
      retry: 0,
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.templateMessages], {
          exact: false,
        });
      },
    }
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

export default useDeleteTemplateMessage;
