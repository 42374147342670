import amplitude from 'amplitude-js';
import React from 'react';
import ReactGA from 'react-ga';
import appConfig from '../app-config';

export enum AppTrackEventsEnum {
  // Object_Action
  APP_ERROR = 'App_Error',
  // Signup
  GOVERNOR_SIGNEDUP = 'Governor_SignedUp',
  GOVERNOR_SIGNEDIN = 'Governor_SignedIn',
  GOVERNOR_SIGNEDOUT = 'Governor_SignedOut',
  // Question
  QUESTION_CREATED = 'Question_Created',
  QUESTION_UPDATED = 'Question_Updated',
  QUESTION_DELETED = 'Question_Deleted',
  // Question
  MESSAGE_CREATED = 'TemplateMessage_Created',
  MESSAGE_UPDATED = 'TemplateMessage_Updated',
  MESSAGE_DELETED = 'TemplateMessage_Deleted',
  // RoutingRule
  ROUTINGRULE_CREATED = 'TemplateRoutingRule_Created',
  ROUTINGRULE_UPDATED = 'TemplateRoutingRule_Updated',
  ROUTINGRULE_DELETED = 'TemplateRoutingRule_Deleted',
  // Partnerships
  ORGPARTNERSHIP_CREATED = 'Orgpartnership_Created',
}

interface AppTrackContextProps {
  setUserId: (userId: string, userEmail?: string) => void;
  logEvent: (
    eventName: AppTrackEventsEnum,
    eventProperties?: { [name: string]: string } | null
  ) => void;
  clearUserId: () => void;
}

const AppTrackContext = React.createContext<AppTrackContextProps | null>(null);

AppTrackContext.displayName = 'AppTrackContext';

const AppTrackProvider = (props: any) => {
  ReactGA.initialize(appConfig.GoogleAnalytics.TrackingId as string, {
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });

  const amplitudeClient = amplitude.getInstance();

  amplitudeClient.init(appConfig.Amplitude.ApiKey, {
    includeReferrer: true,
    includeUtm: true,
  });

  if (process.env.NODE_ENV === 'development') {
    amplitudeClient.setOptOut(true);
  }

  const logEvent = React.useCallback(
    (
      eventName: AppTrackEventsEnum,
      eventProperties: { [name: string]: string } | null = null
    ) => {
      ReactGA.event({
        category: 'App',
        action: eventName,
      });
      amplitudeClient.logEvent(eventName, eventProperties);
    },
    [amplitudeClient]
  );

  const setUserId = React.useCallback(
    (userId: string, userEmail?: string) => {
      amplitudeClient.setUserId(userId);
      if (userEmail) {
        amplitudeClient.setUserProperties({ email: userEmail });
      }
    },
    [amplitudeClient]
  );

  const clearUserId = React.useCallback(
    userId => {
      amplitudeClient.clearUserProperties();
    },
    [amplitudeClient]
  );

  const value = React.useMemo(
    () => ({
      amplitudeClient,
      AppTrackEventsEnum,
      setUserId,
      clearUserId,
      logEvent,
    }),
    [amplitudeClient, setUserId, clearUserId, logEvent]
  );

  return <AppTrackContext.Provider value={value} {...props} />;
};

const useAppTrack = (): AppTrackContextProps => {
  const context = React.useContext(AppTrackContext);

  if (context === undefined || context === null) {
    throw new Error(`useAppTrack must be used within AppTrackProvider`);
  }
  return context;
};

export { AppTrackProvider, useAppTrack };
