import { IBreadcrumb } from "./Breadcrumb";

export interface ILayoutStore {
  isSubLayoutNavOpen: boolean | null;
  isAsideOpen: boolean | null;
  isUserMenuOpen: boolean | null;
  breadcrumb: IBreadcrumb[] | null;
}

export const layoutStoreInitialState: ILayoutStore = {
  isSubLayoutNavOpen: true,
  isAsideOpen: true,
  isUserMenuOpen: false,
  breadcrumb: null,
};
