import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
} from '@chakra-ui/react';
import { DocumentDownloadIcon } from '@heroicons/react/outline';
import * as React from 'react';
import { BsSearch } from 'react-icons/bs';
import { TableInstance, useAsyncDebounce } from 'react-table';
import { useTheme } from '../../../../providers/ThemeProvider';
import { IOrganizationEvent } from '../../models/OrganizationEvent';
import { COL_ID_EVENT_TYPE } from './OrganizationEventsColumns';

const GlobalFilter = ({ preFilterCount, globalFilter, setGlobalFilter }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  React.useEffect(() => {
    if (!globalFilter) {
      setValue('');
    }
  }, [globalFilter]);

  return (
    <FormControl minW={{ md: '320px' }} id="search">
      <InputGroup size="sm">
        <FormLabel srOnly>Filter records</FormLabel>
        <InputLeftElement pointerEvents="none" color={theme.color.base3}>
          <BsSearch />
        </InputLeftElement>
        <Input
          value={value || ''}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          type="search"
          placeholder={`Filter ${preFilterCount} records...`}
        />
      </InputGroup>
    </FormControl>
  );
};

interface IProps {
  table: TableInstance<IOrganizationEvent>;
}
export const OrganizationEventsTableActions = ({ table }: IProps) => {
  const [filterEventType, set_filterEventType] = React.useState<string>('');

  React.useEffect(() => {
    if (!table.state.filters.length) {
      set_filterEventType('');
    }
  }, [table?.state?.filters]);

  return (
    <Stack
      spacing="4"
      direction={{ base: 'column', md: 'row' }}
      justify="space-between"
    >
      <HStack>
        <GlobalFilter
          preFilterCount={table.preGlobalFilteredRows.length}
          globalFilter={table.state.globalFilter}
          setGlobalFilter={table.setGlobalFilter}
        />

        <Select
          // w={{ base: '400px', md: 'unset' }}
          rounded="base"
          size="sm"
          value={filterEventType}
          placeholder="All types"
          onChange={e => {
            const updatedFilter = e.target.value.toLowerCase();
            set_filterEventType(updatedFilter);
            table.setFilter(COL_ID_EVENT_TYPE, updatedFilter);
          }}
        >
          <option value="templatequestion">Questions</option>
          <option value="templatemessage">Messages</option>
          <option value="user">Users</option>
        </Select>
      </HStack>

      <ButtonGroup size="sm" variant="outline" alignItems={'center'}>
        <Button
          isDisabled={true}
          iconSpacing="1"
          leftIcon={<Icon as={DocumentDownloadIcon} fontSize="1.25em" />}
        >
          Export CSV
        </Button>
        <Box>
          <Badge colorScheme={'green'}>Soon</Badge>
        </Box>
      </ButtonGroup>
    </Stack>
  );
};
