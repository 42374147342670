// List Forms

import { API } from 'aws-amplify';
import { useQuery } from 'react-query';
import { IOrganizationLink } from '../../../../routes/settings/partnerships/models/OrganizationLink';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (): Promise<IOrganizationLink[]> => {
  const qParams = {};

  return API.get('users', '/api/v1/organizationlinks/', qParams);
};

export const useListOrganizationLinks = () => {
  return useQuery<IOrganizationLink[]>(
    [QueryKeys.organizationLinks],
    () => makeApiRequest(),
    {
      retry: 2,
    }
  );
};
