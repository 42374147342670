import React from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

const ReactQueryProvider = ({ children }) => {
  const queryClient = new QueryClient({
    queryCache: new QueryCache(),
    defaultOptions: {
      queries: {
        staleTime: 4000,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <>{children}</>
      {false ? <ReactQueryDevtools initialIsOpen={false} /> : null}
    </QueryClientProvider>
  );
};

export { ReactQueryProvider };
