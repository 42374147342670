import { Box, Stack, Text } from '@chakra-ui/react';
import { navigate } from '@reach/router';
import React, { useState } from 'react';
import { MutedLink } from '../../../components/shared/MutedLink';
import { Routes } from '../../../constants';
import { useAuth } from '../../../providers/AuthProvider';
import { useTheme } from '../../../providers/ThemeProvider';
import AuthSignInForm from '../forms/AuthSignInForm';

export interface SignInError {
  key?: string;
  status: string;
}

const AuthSignIn = props => {
  const theme = useTheme();
  const [showResend, setShowResend] = useState(false);
  const { login } = useAuth();

  const handleFormSubmit = async (username: string, password: string) => {
    try {
      await login(username, password);

      let goToApp = '/app/dashboard';

      navigate(goToApp);
    } catch (error: any) {
      // Parse cognito responses
      let err: SignInError = {
        status: 'Sign in failed',
      };
      if (error.code === 'UserNotFoundException') {
        err = {
          key: 'email',
          status: 'Email not found',
        };
      } else if (error.code === 'NotAuthorizedException') {
        err = {
          key: 'password',
          status: 'Please try password again',
        };
      } else if (error.code === 'UserNotConfirmedException') {
        err = {
          status: 'Please confirm your email',
        };
        setShowResend(true);
      }

      throw err;
    }
  };

  return (
    <Box>
      {/* Form title */}
      <Text textStyle="headline">
        {theme.useColorModeValue('✌🏾', '✌️')} Welcome Back
      </Text>

      {/* Form */}
      <Stack spacing={6} mt={6}>
        {/* SignIn Form */}
        <AuthSignInForm
          username={props?.location?.state?.username}
          handleFormSubmit={handleFormSubmit}
        ></AuthSignInForm>

        {/* Actions */}
        <Stack>
          {/* Resend confirmation code */}
          {showResend ? (
            <MutedLink
              to={Routes.AuthResendEmailConfirmation}
              label="Resend confirmation code"
            ></MutedLink>
          ) : null}

          {/* Forgot password */}
          <MutedLink
            to={Routes.AuthResetPassword}
            label="Forgot your password?"
          ></MutedLink>

          {/* Create account */}
          <MutedLink to={Routes.AuthSignUp} label="Create Account"></MutedLink>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AuthSignIn;
