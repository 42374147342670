import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import {
  IGovernorProfile,
  IGovernorProfileUpdate,
} from '../../../../models/GovernorProfile';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (formIn): Promise<IGovernorProfileUpdate> => {
  const reqData = {
    body: formIn,
  };

  return API.put('users', `/api/v1/governors`, reqData);
};

const useUpdateGovernorProfile = () => {
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation<
    IGovernorProfileUpdate,
    Error,
    {
      updateData: IGovernorProfileUpdate;
    },
    {
      previousRecord: any;
      updateData: any;
    }
  >(
    ({ updateData }) => {
      return makeApiRequest(updateData);
    },
    {
      retry: 0,
      onMutate: async ({ updateData }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([QueryKeys.governorProfiles]);

        // Snapshot the previous value
        const previousRecord = queryClient.getQueryData<IGovernorProfile>([
          QueryKeys.governorProfiles,
        ]);
        // Optimistically update to the new value
        queryClient.setQueryData([QueryKeys.governorProfiles], {
          ...previousRecord,
          ...updateData,
        });

        // Return a context object with the snapshotted value
        return { previousRecord, updateData };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, _newPost, context: any) => {
        queryClient.setQueryData(
          [QueryKeys.governorProfiles],
          context.previousRecord
        );
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.governorProfiles]);
      },
    }
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

export default useUpdateGovernorProfile;
