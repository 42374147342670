import { Box, Stack, useToast } from '@chakra-ui/react';
import React from 'react';
import useUpdateGuardrails from '../../../services/api/guardrails/mutations/useUpdateGuardrails';
import { useReadGuardrails } from '../../../services/api/guardrails/queries/useReadGuardrails';
import { GuardrailSettingsForm } from '../forms/GuardrailSettingsForm';
import { IGuardrailsUpdate } from '../models/Guardrails';

export const GuardrailSettings = () => {
  const toast = useToast();
  const toastIdRef = React.useRef<any>();

  const { data: dataGuardrails, isLoading: isLoadingGuardrails } =
    useReadGuardrails();

  const {
    mutateAsync: mutateAsyncGuardrails,
    isLoading: isLoadingMutateGuardrails,
  } = useUpdateGuardrails();

  const handleOnSubmit = async (values: IGuardrailsUpdate) => {
    if (!isLoadingMutateGuardrails) {
      toastIdRef.current = toast({
        title: 'Saving...',
        description: 'Should be quick to get this update in.',
        status: 'info',
        variant: 'subtle',
        isClosable: true,
        duration: null,
      });

      try {
        await mutateAsyncGuardrails({ updateData: values });

        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }

        toast({
          title: 'Saved',
          description: 'Update is good and ready.',
          status: 'success',
          duration: 2000,
          variant: 'subtle',
          isClosable: true,
        });
      } catch (e) {
        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }
        throw e;
      }
    }
  };

  return (
    <Box>
      <Stack spacing="8">
        <GuardrailSettingsForm
          guardrails={dataGuardrails}
          onSubmit={handleOnSubmit}
        ></GuardrailSettingsForm>
      </Stack>
    </Box>
  );
};
