import { Stack } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { IQuestion } from '../../../models/Question';
import { QuestionTypeMultiSelectSmudge } from '../QuestionTypeMultiSelectSmudge';
import { QuestionTypeTextLongSmudge } from '../QuestionTypeTextLongSmudge';
import { QuestionTypeTextShortSmudge } from '../QuestionTypeTextShortSmudge';

export const CellQuestionType = ({ row }: CellProps<IQuestion>) => {
  const [smudgeElem, set_smudgeElem] = React.useState<React.ReactNode>();

  const smudgeLookup: Record<string, React.ReactNode> = React.useMemo(
    () => ({
      TEXT_LONG: <QuestionTypeTextLongSmudge />,
      TEXT_SHORT: <QuestionTypeTextShortSmudge />,
      MULTI_SELECT: <QuestionTypeMultiSelectSmudge />,
    }),
    []
  );

  React.useEffect(() => {
    if (row?.original?.QuestionType) {
      if (smudgeLookup[row.original.QuestionType]) {
        set_smudgeElem(smudgeLookup[row.original.QuestionType]);
      }
    }
  }, [row]);

  return (
    <Stack direction="row" spacing="4" align="center">
      {smudgeElem}
    </Stack>
  );
};
