import { Box, useToast } from '@chakra-ui/react';
import React from 'react';
import useUpdateOrganization from '../../../services/api/organization/mutations/useUpdateOrganization';
import { useReadOrganization } from '../../../services/api/organization/queries/useReadOrganization';
import { IOrganizationUpdate } from '../../organization/models/Organization';
import { OrganizationSettingsForm } from '../forms/organization/OrganizationSettingsForm';

export const OrganizationSettings = () => {
  const toast = useToast();
  const toastIdRef = React.useRef<any>();

  const { data: dataOrganization, isLoading: isLoadingOrganization } =
    useReadOrganization();

  const {
    mutateAsync: mutateAsyncOrganization,
    isLoading: isLoadingMutateOrganization,
  } = useUpdateOrganization();

  const handleOnSubmit = async (values: IOrganizationUpdate) => {
    if (!isLoadingMutateOrganization) {
      toastIdRef.current = toast({
        title: 'Saving...',
        description: 'Should be quick to get this update in.',
        status: 'info',
        variant: 'subtle',
        isClosable: true,
        duration: null,
      });

      try {
        await mutateAsyncOrganization({ updateData: values });

        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }

        toast({
          title: 'Saved',
          description: 'Update is good and ready.',
          status: 'success',
          duration: 2000,
          variant: 'subtle',
          isClosable: true,
        });
      } catch (e) {
        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }

        throw e;
      }
    }
  };

  return (
    <Box>
      <OrganizationSettingsForm
        organization={dataOrganization}
        onSubmit={handleOnSubmit}
      ></OrganizationSettingsForm>
    </Box>
  );
};
