import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTheme } from '../../../providers/ThemeProvider';
import { OrganizationEventRecordHistory } from './OrganizationEventRecordHistory';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  eventRecordId?: string;
}

export const OrganizationEventRecordHistoryModal = ({
  isOpen,
  onClose,
  eventRecordId,
}: IProps) => {
  const theme = useTheme();

  const handleFormClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={true}
      motionPreset="slideInBottom"
      size="6xl"
      scrollBehavior="inside"
      colorScheme="primary"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack spacing={0}>
            <Text>History</Text>
            <Text textStyle="description" color={theme.color.base3}>
              A history of this record
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={8}>
          <OrganizationEventRecordHistory
            eventRecordId={eventRecordId}
          ></OrganizationEventRecordHistory>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={4}>
            <Button onClick={handleFormClose}>Cancel</Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
