import { Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { FunctionComponent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { LibFullPageErrorFallback } from './components/lib';
import AuthLayout from './layouts/AuthLayout';
import AuthEmailConfirm from './routes/auth/components/AuthEmailConfirm';
import AuthResendEmailConfirm from './routes/auth/components/AuthResendEmailConfirm';
import AuthResetPassword from './routes/auth/components/AuthResetPassword';
import AuthResetPasswordConfirm from './routes/auth/components/AuthResetPasswordConfirm';
import AuthSignIn from './routes/auth/components/AuthSignIn';
import AuthSignUp from './routes/auth/components/AuthSignUp';

const AppUnauthenticated = () => {
  return (
    <ErrorBoundary FallbackComponent={LibFullPageErrorFallback}>
      <div>
        <main>
          <AppRoutes />
        </main>
      </div>
    </ErrorBoundary>
  );
};

const AppRoutes = () => {
  return (
    <Router basepath="/">
      <Redirect from="/" to="/auth/signin" noThrow />
      <Route default path="auth/*" component={AuthRoutes} />
    </Router>
  );
};

const AuthRoutes = () => {
  return (
    <AuthLayout>
      <Router basepath="/auth">
        <Redirect default from="/" to="/auth/signin" noThrow />
        <Route path="signup" component={AuthSignUp} />
        <Route path="reset-password" component={AuthResetPassword} />
        <Route
          path="reset-password-confirmation"
          component={AuthResetPasswordConfirm}
        />
        <Route
          path="resend-email-confirmation"
          component={AuthResendEmailConfirm}
        />
        <Route path="email-confirmation" component={AuthEmailConfirm} />
        <Route path="signin" component={AuthSignIn} />
      </Router>
    </AuthLayout>
  );
};

const Route: FunctionComponent<
  { component: React.ComponentType } & RouteComponentProps
> = ({ component: Component, ...rest }) => <Component {...rest} />;

export default AppUnauthenticated;
