import {
  Box,
  Center,
  Circle,
  Flex,
  Spinner,
  SpinnerProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTheme } from '../providers/ThemeProvider';

const LibFullPageSpinner = () => {
  const theme = useTheme();

  return (
    <Flex h="100vh">
      <Center w="full">
        <Spinner
          thickness="6px"
          size="xl"
          speed="1s"
          color={theme.bg.base2}
          emptyColor={theme.color.accent}
        />
      </Center>
    </Flex>
  );
};

const LibFullWidthSpinner = (props: SpinnerProps) => {
  const { size = 'xl', thickness = '6px', ...rest } = props;
  const theme = useTheme();

  return (
    <Flex>
      <Center h="full" w="full">
        <Spinner
          {...rest}
          size={size}
          thickness={thickness}
          speed="1s"
          color={theme.bg.base2}
          emptyColor={theme.color.accent}
        />
      </Center>
    </Flex>
  );
};

const LibFullPageErrorFallback = ({ error }: { error: Error }) => {
  const theme = useTheme();
  return (
    <Box h="100vh" w="full">
      <Center flexDirection={'column'} h="full">
        <Circle w={48} h={48} bgGradient={theme.bg.noticeGradient}>
          <Text textStyle={'headline'}>Broken</Text>
        </Circle>

        <Text maxW={'sm'} textAlign="center" color="gray.500" mt={8}>
          If you are seeing this, we are sorry! Let us know and we will fix it.
        </Text>
        <Text mt={4}>
          <a
            href={`mailto:admin@currentclient.com?subject=App Broken&body=Hey, CurrentClient! I found this error:%0A%0A${error}%0A%0ALet me know when you have it fixed. Thanks!%0A`}
          >
            Click Here to Email Us
          </a>
        </Text>

        {error && error.message && (
          <Stack
            width="30rem"
            overflow="auto"
            maxWidth="30rem"
            padding="4"
            textAlign="center"
            background={theme.bg.base1}
            marginTop="1rem"
            borderRadius="4px"
            fontFamily="Inter, sans-serif"
          >
            <Text
              as={'pre'}
              maxW={'lg'}
              textStyle="indicator"
              mt={2}
              id="password-error"
            >
              Error
            </Text>
            <pre
              style={{
                marginTop: '2px',
                color: '#dc2626',
              }}
            >
              {error.message}
            </pre>
          </Stack>
        )}
      </Center>
    </Box>
  );
};

const LibErrorFallback = ({ error }: { error: any }) => {
  return (
    <div
      role="alert"
      className="flex flex-col items-center justify-center w-full h-full px-8"
    >
      <img
        className="w-12 h-12 max-w-full max-h-full"
        src="https://cc-west-prd-bucket-users.s3.us-west-2.amazonaws.com/public/media/cc-error-comon.png"
        alt=""
      />
      <div className="max-w-lg p-4 mt-6 bg-gray-100 rounded-md">
        <pre className="mt-2 text-red-600">Unavailable</pre>
      </div>
    </div>
  );
};

export {
  LibFullPageErrorFallback,
  LibFullPageSpinner,
  LibFullWidthSpinner,
  LibErrorFallback,
};
