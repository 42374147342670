import {
  Icon,
  InputLeftAddon,
  Stack,
  StackDivider,
  useTheme,
} from '@chakra-ui/react';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import { FormikProps } from 'formik';
import React from 'react';
import { FormInputControl } from '../../../../components/forms';
import { FieldGroup } from '../../../../components/forms/FieldGroup';
import { PropertyGroup } from '../../../../components/forms/PropertyGroup';
import { IGovernorProfileWorkingForm } from '../../../../models/GovernorProfile';
import { GovernorProfileImageUpload } from '../../components/GovernorProfileImageUpload';

const ValidateFormLogic = () => {
  // Grab values and submitForm from context
  // const { values, setFieldValue } =
  //   useFormikContext<IGovernorProfileWorkingForm>();

  return null;
};

interface IProps {
  form: FormikProps<IGovernorProfileWorkingForm>;
}

export const GovProfileContactSettingsFormGroup = ({ form }: IProps) => {
  const theme = useTheme();

  const onBrandingUrlChange = async updatedImageUrl => {
    console.log('updatedImageUrl', updatedImageUrl);
    form.setFieldValue('BrandImageUrl', updatedImageUrl);
  };

  return (
    <>
      <ValidateFormLogic />
      <Stack spacing={4}>
        <FieldGroup title="Profile Details" description="Core profile settings">
          <Stack spacing="6" divider={<StackDivider />}>
            <PropertyGroup label="Profile avatar">
              <GovernorProfileImageUpload
                currentBrandUrl={form.values.BrandImageUrl}
                onChange={onBrandingUrlChange}
              ></GovernorProfileImageUpload>
            </PropertyGroup>
            <PropertyGroup label="First Name">
              <FormInputControl
                name="FirstName"
                formControlProps={{
                  maxW: '24rem',
                }}
              />
            </PropertyGroup>
            <PropertyGroup label="Last Name">
              <FormInputControl
                name="LastName"
                formControlProps={{
                  maxW: '24rem',
                }}
              />
            </PropertyGroup>
            <PropertyGroup label="Email">
              <FormInputControl
                name="Email"
                isDisabled={true}
                inputLeftAddon={
                  <InputLeftAddon
                    children={<Icon as={MailIcon} color={theme.colors.base3} />}
                  />
                }
                formControlProps={{
                  maxW: '20rem',
                }}
              />
            </PropertyGroup>
            <PropertyGroup label="Phone">
              <FormInputControl
                name="Phone"
                inputLeftAddon={
                  <InputLeftAddon
                    children={
                      <Icon as={PhoneIcon} color={theme.colors.base3} />
                    }
                  />
                }
                formControlProps={{
                  maxW: '16rem',
                }}
              />
            </PropertyGroup>
          </Stack>
        </FieldGroup>
      </Stack>
    </>
  );
};
