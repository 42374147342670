import { Stack } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { GovernanceSmudge } from '../../../../../components/shared/GovernanceSmudge';
import { useAuth } from '../../../../../providers/AuthProvider';
import { IMessage, ITemplateMessageGovernance } from '../../../models/Message';

export const CellMessageGovernance = ({ row }: CellProps<IMessage>) => {
  const { userProfile } = useAuth();

  const governorsList = React.useMemo(() => {
    let governorsList: ITemplateMessageGovernance[] = [];

    if (row?.original?.Governance) {
      governorsList = Object.entries(row.original?.Governance)
        .sort((orgIdA, orgIdB) => {
          if (orgIdA[0] === userProfile.OrganizationId) {
            return -1;
          } else if (orgIdB[0] === userProfile.OrganizationId) {
            return 1;
          }
          return 0;
        })
        .map(orgId => orgId[1]);
    }

    return governorsList;
  }, [userProfile, row]);

  return (
    <Stack direction="row" spacing={4} align="center" h="full">
      {governorsList &&
        governorsList.map(goveranceObj => {
          return (
            <GovernanceSmudge
              key={goveranceObj.OrganizationProfile?.OrganizationId}
              brandImageUrl={goveranceObj.OrganizationProfile?.BrandImageUrl}
              isApproved={goveranceObj.IsApproved}
              name={goveranceObj.OrganizationProfile?.OrganizationName}
            ></GovernanceSmudge>
          );
        })}
    </Stack>
  );
};
