import { GridItem, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { PageContainer } from '../../../layouts/PageContainer';
import { PageContent } from '../../../layouts/PageContent';
import { DashboardCard } from './DashboardCard';
import { DashboardStats } from './DashboardStats';
import { InviteRep } from './InviteRep';
import { OrganizationCard } from './OrganizationCard';
import { WelcomeCard } from './WelcomeCard';

const Dashboard = () => {
  return (
    <PageContainer>
      <PageContent>
        <SimpleGrid
          w="full"
          maxW="6xl"
          mx="auto"
          columns={{ base: 6, lg: 12 }}
          columnGap={{ base: 0, lg: 12 }}
          rowGap={{ base: 4, lg: 12 }}
        >
          {/* Stat row */}
          <GridItem colSpan={{ base: 6, lg: 12 }}>
            <DashboardCard>
              <WelcomeCard></WelcomeCard>
            </DashboardCard>
          </GridItem>
          <GridItem colSpan={{ base: 6, lg: 12 }}>
            <DashboardCard>
              <DashboardStats></DashboardStats>
            </DashboardCard>
          </GridItem>
          <GridItem colSpan={{ base: 6, lg: 8 }}>
            <DashboardCard>
              <InviteRep></InviteRep>
            </DashboardCard>
          </GridItem>
          <GridItem colSpan={{ base: 6, lg: 4 }}>
            <DashboardCard>
              <OrganizationCard></OrganizationCard>
            </DashboardCard>
          </GridItem>
        </SimpleGrid>
      </PageContent>
    </PageContainer>
  );
};

export default Dashboard;
