import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { CellProps } from 'react-table';
import { useTheme } from '../../../../../providers/ThemeProvider';
import { IQuestion } from '../../../models/Question';

export const CellQuestionTitle = ({ row }: CellProps<IQuestion>) => {
  const theme = useTheme();

  return (
    <Stack spacing={0} h="full" whiteSpace={'pre-wrap'}>
      <Text noOfLines={2} color={theme.color.base} textStyle={'formLabel'}>
        {row.original.QuestionTitle}
      </Text>
      {row.original.IsIncludeQuestionDescription && (
        <Text noOfLines={3} color={theme.color.base3} textStyle={'description'}>
          {row.original.QuestionDescription}
        </Text>
      )}
    </Stack>
  );
};
