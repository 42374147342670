/*
TextMessageBubble
*/
import { Box, BoxProps, Text, TextProps } from '@chakra-ui/layout';
import React from 'react';
import { useTheme } from '../../providers/ThemeProvider';

interface IProps extends BoxProps {
  message?: string;
  textProps?: TextProps;
}

const TextMessageBubble = (props: IProps) => {
  const theme = useTheme();

  const { message = '', textProps, ...rest } = props;
  return (
    <Box p={2} rounded="md" bg={theme.bg.textBubble} {...rest}>
      <Text fontSize="sm" {...textProps}>
        {message}
      </Text>
    </Box>
  );
};

export default TextMessageBubble;
