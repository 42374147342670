import { Box, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import DoodleSVGDark from '../../../assets/art/dark/ZombieingDoodle.svg';
import DoodleSVGLight from '../../../assets/art/light/ZombieingDoodle.svg';
import { PageHeader } from '../../../layouts/PageHeader';
import { useAuth } from '../../../providers/AuthProvider';
import { useTheme } from '../../../providers/ThemeProvider';
import { useReadGuardrails } from '../../../services/api/guardrails/queries/useReadGuardrails';

const SettingsMFA = () => {
  const theme = useTheme();
  const { userProfile } = useAuth();
  const { data: dataGuardrails, isLoading: isLoadingGuardrails } =
    useReadGuardrails();

  const doodleSvg = theme.useColorModeValue(DoodleSVGLight, DoodleSVGDark);

  return (
    <>
      <PageHeader title="MFA"></PageHeader>
      <Box py={8}>
        <Stack
          position="relative"
          alignItems="center"
          bg={theme.bg.base}
          cursor="pointer"
          role="group"
          spacing={8}
          h={'full'}
          overflow={'hidden'}
        >
          {/* Image */}
          <Box
            bg={theme.bg.base}
            rounded={'sm'}
            className="group"
            overflow="hidden"
          >
            <Image
              w="full"
              objectFit="contain"
              src={doodleSvg}
              transition="all 0.2s"
              _groupHover={{ transform: 'scale(1.01)' }}
            />
          </Box>

          <Stack padding="12" flex="1">
            <Stack spacing="0">
              <Text textStyle={'headline'}>Coming Soon</Text>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default SettingsMFA;
