/*
AlertContent
*/

import { Box, Flex, HStack, Icon, Link, Text } from '@chakra-ui/react';
import { Link as ReachLink } from '@reach/router';
import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { useTheme } from '../../providers/ThemeProvider';

export interface AlertContentProps {
  title?: string;
  type?: 'error' | 'warning' | 'info' | 'notice';
  icon?: any;
  isPulse?: boolean;
  action?: string;
  actionTo?: string;
  children?: React.ReactElement;
  isExternal?: boolean;
}

export const AlertContent = ({
  title,
  action,
  actionTo,
  isPulse,
  icon,
  children,
  type = 'info',
  isExternal = false,
}: AlertContentProps) => {
  const theme = useTheme();

  const [iconColor, set_iconColor] = React.useState<string>(
    'linear(to-r, green.200, primary.500)'
  );

  const stylesMapping = React.useMemo(() => {
    return {
      error: {
        iconColor: theme.bg.dangerGradient,
      },
      warning: {
        iconColor: 'linear(to-r, orange.400, orange.500)',
      },
      info: {
        iconColor: 'linear(to-r, primary.400, primary.500)',
      },
      notice: {
        iconColor: theme.bg.noticeGradient,
      },
    };
  }, [theme]);

  React.useEffect(() => {
    let iconColorUpdate = 'linear(to-r, green.200, primary.500)';

    const targetStyle = stylesMapping[type];
    iconColorUpdate = targetStyle.iconColor;

    set_iconColor(iconColorUpdate);
  }, [type]);

  return (
    <HStack alignItems={'center'} w="full" h="full" spacing={4}>
      {/* Icon */}
      <Flex
        alignItems={'center'}
        w="8"
        h="8"
        justify={'center'}
        p={4}
        rounded="full"
        shadow={'2xl'}
        bgGradient={iconColor}

        // isPulse && 'animate-pulse'
      >
        <Icon h={4} w={4} color="white" as={icon ? icon : FiSearch}></Icon>
      </Flex>

      {/* Body */}
      <Box flex={1}>
        {/* Title */}
        {title && (
          <Text textTransform={'capitalize'} fontWeight={'bold'}>
            {title}
          </Text>
        )}
        {/* Body */}
        <Text>{children}</Text>
      </Box>

      {/* Action */}
      {actionTo && (
        <Box>
          {isExternal ? (
            <Link
              isExternal={true}
              href={actionTo}
              fontWeight="bold"
              whiteSpace="nowrap"
              color={theme.color.base}
            >
              {action} <span aria-hidden="true">→</span>
            </Link>
          ) : (
            <Link
              as={ReachLink}
              to={actionTo}
              fontWeight="bold"
              whiteSpace="nowrap"
              color={theme.color.base}
            >
              {action} <span aria-hidden="true">→</span>
            </Link>
          )}
        </Box>
      )}
    </HStack>
  );
};
