import {
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import React from 'react';
import {
  AppTrackEventsEnum,
  useAppTrack,
} from '../../../../../providers/AppTrackProvider';
import { useTheme } from '../../../../../providers/ThemeProvider';
import useCreateTemplateRoutingRule from '../../../../../services/api/templateRoutingRules/mutations/useCreateTemplateRoutingRule';
import { RoutingRuleForm } from '../../../forms/routingRules/RoutingRulesForm';
import {
  IRoutingRule,
  IRoutingRuleMold,
  marshallRoutingMoldToCreateApi,
} from '../../../models/RoutingRule';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TemplateRoutingRuleModalAdd = ({ isOpen, onClose }: IProps) => {
  const theme = useTheme();

  const { mutateAsync: mutateAsyncCreate } = useCreateTemplateRoutingRule();

  const { logEvent: appTrackLogEvent } = useAppTrack();

  const handleSuccess = (dataIn: IRoutingRule) => {
    // Close modal when mutate is successful
    appTrackLogEvent(AppTrackEventsEnum.ROUTINGRULE_CREATED, {
      routingrule_id: dataIn.TemplateRoutingRuleId,
    });
    handleFormClose();
  };

  const handleFormSubmit = async (data: IRoutingRuleMold) => {
    // Post data to api

    try {
      const routingRuleUpdate = marshallRoutingMoldToCreateApi(data);

      console.log('routingRuleUpdate', routingRuleUpdate);

      await mutateAsyncCreate(routingRuleUpdate, {
        onSuccess: handleSuccess,
      });
    } catch (err) {
      console.log(err);
      // Throw error for downstream form to handle
      throw err;
    }
  };

  const handleFormClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      size="4xl"
      colorScheme="primary"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack spacing={0}>
            <Text>Add routing rule</Text>
            <Text textStyle="description" color={theme.color.base3}>
              Create new routing rule that will be available for the users in
              your organization to use in their discovery forms.
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={8}>
          <Stack spacing={4}>
            <RoutingRuleForm
              onSubmit={handleFormSubmit}
              onCancel={handleFormClose}
              actionText={'Add routing rule'}
            ></RoutingRuleForm>

            <HStack>
              <Icon
                color={theme.color.base3}
                fontSize="lg"
                as={InformationCircleIcon}
              ></Icon>

              <Text textStyle="description" color={theme.color.base3}>
                After you create the rule you will be able to publish it to your
                users, and then it will take effect.
              </Text>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
