import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTheme } from '../../../../../providers/ThemeProvider';
import useUpdateTemplateQuestion from '../../../../../services/api/templateQuestions/mutations/useUpdateTemplateQuestion';
import { QuestionForm } from '../../../forms/questions/QuestionForm';
import { IQuestion, IQuestionWorkingForm } from '../../../models/Question';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  question: IQuestion | null;
}

export const TemplateQuestionModalEdit = ({
  isOpen,
  onClose,
  question,
}: IProps) => {
  const theme = useTheme();

  const { mutateAsync: mutateAsyncUpdate } = useUpdateTemplateQuestion();

  const handleSuccess = (dataIn: IQuestion) => {
    // Close modal when mutate is successful
    handleFormClose();
  };

  const handleFormSubmit = async (data: IQuestionWorkingForm) => {
    // Post data to api
    if (question) {
      try {
        await mutateAsyncUpdate(
          {
            templateId: question.TemplateQuestionId,
            updateData: data,
          },
          {
            onSuccess: handleSuccess,
          }
        );
      } catch (err) {
        console.log(err);
        // Throw error for downstream form to handle
        throw err;
      }
    }
  };

  const handleFormClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={true}
      motionPreset="slideInBottom"
      size="4xl"
      colorScheme="primary"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack spacing={0}>
            <Text>Edit question</Text>
            <Text textStyle="description" color={theme.color.base3}>
              Make changes to this question and it will be updated from this
              point on, if a rep already used this question it will not be
              updated retroactively.
            </Text>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={8}>
          <QuestionForm
            question={question}
            onSubmit={handleFormSubmit}
            onCancel={handleFormClose}
            actionText={'Update question'}
          ></QuestionForm>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
