import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  ArrowNarrowRightIcon,
  ClockIcon,
  DuplicateIcon,
  LinkIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { navigate } from '@reach/router';
import React from 'react';
import { useTheme } from '../../../../../providers/ThemeProvider';
import { useListOrganizationLinks } from '../../../../../services/api/organization/queries/useListOrganizationLinks';
import { IOrganizationLink } from '../../../../settings/partnerships/models/OrganizationLink';
import { IQuestion } from '../../../models/Question';

interface IProps {
  question: IQuestion;
  onEditQuestion: () => void;
  onDeleteQuestion: () => void;
  onViewHistory: () => void;
  onCopyQuestion: () => void;
  onLinkQuestion: (organizationLink: IOrganizationLink) => void;
}

export const CellQuestionAction = ({
  question,
  onEditQuestion,
  onDeleteQuestion,
  onViewHistory,
  onCopyQuestion,
  onLinkQuestion,
}: IProps) => {
  const theme = useTheme();

  const { data: dataOrganizationLinks } = useListOrganizationLinks();

  const handleLinkPartner = async (organizationLink: IOrganizationLink) => {
    onLinkQuestion(organizationLink);
  };

  const handleNewPartnership = async values => {
    navigate('/app/settings/partnerships');
  };

  return (
    <ButtonGroup
      size="sm"
      alignItems={'center'}
      h="full"
      isDisabled={question.IsDeleting || question.IsProcessing}
    >
      <Wrap spacing={4}>
        <WrapItem>
          <Button onClick={onEditQuestion}>Edit</Button>
        </WrapItem>
        <WrapItem>
          <Tooltip label="Delete question" fontSize="xs">
            <IconButton
              aria-label="delete question"
              onClick={onDeleteQuestion}
              icon={<Icon as={TrashIcon} />}
            ></IconButton>
          </Tooltip>
        </WrapItem>
        <WrapItem>
          <Tooltip label="View history" fontSize="xs">
            <IconButton
              aria-label="view history"
              onClick={onViewHistory}
              icon={<Icon as={ClockIcon} />}
            ></IconButton>
          </Tooltip>
        </WrapItem>
        <WrapItem>
          <Tooltip label="Duplicate question" fontSize="xs">
            <IconButton
              aria-label="duplicate question"
              onClick={onCopyQuestion}
              icon={<Icon as={DuplicateIcon} />}
            ></IconButton>
          </Tooltip>
        </WrapItem>
        <WrapItem>
          <Menu>
            <Tooltip
              label={
                question.IsManaged
                  ? "You can't link a managed template"
                  : question.IsLinked
                  ? 'Already linked'
                  : 'Link to another organization'
              }
              fontSize="xs"
              shouldWrapChildren
            >
              <MenuButton
                as={IconButton}
                isDisabled={
                  question.IsManaged ||
                  question.IsDeleting ||
                  question.IsProcessing ||
                  question.IsLinked
                }
                size="sm"
                icon={<Icon as={LinkIcon} />}
                aria-label="Link organization"
              ></MenuButton>
            </Tooltip>
            <MenuList
              rounded="sm"
              shadow="lg"
              py="1"
              bg={theme.bg.base}
              color={theme.color.base}
              fontSize="sm"
            >
              {!dataOrganizationLinks?.length && (
                <MenuItem
                  onClick={handleNewPartnership}
                  textStyle={'navLink'}
                  color={theme.color.link}
                >
                  <HStack w="full" spacing="4" justifyContent={'space-between'}>
                    <Text textStyle={'navLink'} as="span">
                      Create new partnership
                    </Text>
                    <Icon
                      as={ArrowNarrowRightIcon}
                      fontSize="lg"
                      opacity={0.64}
                    />
                  </HStack>
                </MenuItem>
              )}

              {dataOrganizationLinks?.map(
                (organizationLink: IOrganizationLink) => {
                  return (
                    <MenuItem
                      key={organizationLink.OrganizationId}
                      textStyle={'navLink'}
                      onClick={() => handleLinkPartner(organizationLink)}
                    >
                      <HStack px="3" py="2">
                        <Box h={8} rounded={'sm'} overflow={'hidden'}>
                          <Image
                            objectFit={'contain'}
                            h="full"
                            src={organizationLink.BrandImageUrl}
                          ></Image>
                        </Box>
                        <Box lineHeight="1">
                          <Text>{organizationLink.OrganizationName}</Text>
                        </Box>
                      </HStack>
                    </MenuItem>
                  );
                }
              )}
            </MenuList>
          </Menu>
        </WrapItem>
      </Wrap>
    </ButtonGroup>
  );
};
