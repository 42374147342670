import { Heading, HStack, Stack, StackProps, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTheme } from '../providers/ThemeProvider';

interface IProps extends StackProps {
  title: string;
  description?: string;
}

export const PageHeader = (props: IProps) => {
  const { title, description, ...rest } = props;
  const theme = useTheme();

  return (
    <Stack
      justify={'space-between'}
      direction={{ base: 'column', lg: 'row' }}
      spacing={{ base: '4', lg: '24' }}
      {...rest}
    >
      <Stack spacing={0}>
        {/* <Breadcrumb color={theme.color.base3} mb={2}>
          <BreadcrumbItem>
            <BreadcrumbLink href="#">Settings</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">{title}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb> */}
        <Heading size="lg">{title}</Heading>
        <Text color={theme.color.base3}>{description}</Text>
      </Stack>
      {props.children && (
        <HStack direction="row" spacing="4">
          {props.children}
        </HStack>
      )}
    </Stack>
  );
};
