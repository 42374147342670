import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { IQuestion } from '../../../../routes/guardrails/models/Question';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (templateQuestionId): Promise<IQuestion> => {
  return API.post(
    'users',
    `/api/v1/templatequestions/${templateQuestionId}/remove`,
    {}
  );
};

const useDeleteTemplateQuestion = () => {
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation<IQuestion, any, { templateQuestionId: string }, any>(
    ({ templateQuestionId }) => makeApiRequest(templateQuestionId),
    {
      retry: 0,
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.templateQuestions], {
          exact: false,
        });
      },
    }
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

export default useDeleteTemplateQuestion;
