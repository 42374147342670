import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import {
  IOrganization,
  IOrganizationUpdate,
} from '../../../../routes/organization/models/Organization';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (updateData: IOrganizationUpdate) => {
  const payload = {
    body: updateData,
  };

  return API.put('users', '/api/v1/organizations/', payload);
};

const useUpdateOrganization = () => {
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation<
    IOrganization,
    any,
    {
      updateData: IOrganizationUpdate;
    },
    {
      previousRecord: IOrganization | any;
      updateData: IOrganizationUpdate;
    }
  >(
    ({ updateData }) => {
      return makeApiRequest(updateData);
    },
    {
      retry: 0,

      onMutate: async ({ updateData }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([QueryKeys.organizations]);

        // Snapshot the previous value
        const previousRecord = queryClient.getQueryData<IOrganization>([
          QueryKeys.organizations,
        ]);

        // Optimistically update to the new value
        queryClient.setQueryData([QueryKeys.organizations], {
          ...previousRecord,
          ...updateData,
        });

        // Return a context object with the snapshotted value
        return { previousRecord, updateData };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, newTodo, context) => {
        if (context) {
          queryClient.setQueryData(
            [QueryKeys.organizations],
            context.previousRecord
          );
        }
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.organizations]);
      },
    }
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

export default useUpdateOrganization;
