import { Auth } from "aws-amplify";

const appConfig = {
  FlatFile: {
    licenseKey: process.env.REACT_APP_FLATFILE_ID  || "",
    envMode: process.env.REACT_APP_FLATFILE_ENV,
  },
  Shortener: {
    // basePartnerShareNew: "http://0.0.0.0:8000/hello/",
    shortUrl: process.env.REACT_APP_SHORT_URL,
  },
  Organizations: {
    // basePartnerShareNew: "http://0.0.0.0:8000/hello/",
    baseInvite: process.env.REACT_APP_INVITE_BASE_LINK,
    basePartnerInvite: process.env.REACT_APP_PARTNER_BASE_LINK
  },
  Algolia: {
    AppId: process.env.REACT_APP_ALGOLIA_APP_ID  || "",
    LeadIndex: process.env.REACT_APP_ALGOLIA_APP_INDEX
  },
  Intercom: {
    AppId: process.env.REACT_APP_INTERCOM_APP_ID,
  },
  Amplitude: {
    ApiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
  },
  GoogleAnalytics: {
    TrackingId: process.env.REACT_APP_GA_TRACKING_ID,
  },
  //Auth is the same as before
  Auth: {
    region: process.env.REACT_APP_USERPOOL_REGION,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENT,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_SRP_AUTH",
    // // OPTIONAL - Hosted UI configuration
    // oauth: {
    //   domain: process.env.REACT_APP_USERPOOL_DOMAIN,
    //   scope: [
    //     "email",
    //     "profile",
    //     "openid",
    //     "aws.cognito.signin.user.admin",
    //   ],
    //   // redirectSignIn: "http://localhost:8000/app/login",
    //   // redirectSignOut: "http://localhost:8000/app/login",
    //   responsseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
    // },
  },
  // Add in our new API, "name" can be whatever we want
  API: {
    endpoints: [
      {
        name: "contacts",
        // endpoint: "http://0.0.0.0:8001",
        endpoint: process.env.REACT_APP_API_CONTACTS_HOST,
        custom_header: async () => {
          // return { Authorization : 'token' }
          // Alternatively, with Cognito User Pools use this:
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
      {
        name: "campaigns",
        // endpoint: "http://0.0.0.0:8001",
        endpoint: process.env.REACT_APP_API_CAMPAIGNS_HOST,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "outbounds",
        // endpoint: "http://0.0.0.0:8001",
        endpoint: process.env.REACT_APP_API_OUTBOUNDS_HOST,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "users",
        // endpoint: "http://0.0.0.0:8001",
        endpoint: process.env.REACT_APP_API_USERS_HOST,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "usersPublic",
        // endpoint: "http://0.0.0.0:8001",
        endpoint: process.env.REACT_APP_API_USERS_PUBLIC_HOST,
      },
    ],
  },
};

export default appConfig;
