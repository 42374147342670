import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Icon,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { ChatAltIcon } from '@heroicons/react/outline';
import React from 'react';
import { FiTrash } from 'react-icons/fi';
import DialogContent from '../../../../../components/shared/DialogContent';
import useDeleteTemplateMessage from '../../../../../services/api/templateMessages/mutations/useDeleteTemplateMessage';
import { IMessage } from '../../../models/Message';

const TemplateMessageDialogDelete = ({
  onClose,
  message,
  isOpen = false,
}: {
  onClose: () => void;
  message?: IMessage | null;
  isOpen: boolean;
}) => {
  const cancelRef = React.useRef(null);

  const toast = useToast();
  const { mutateAsync: mutateAsyncDelete, isLoading } =
    useDeleteTemplateMessage();

  const handleOnConfirm = async () => {
    if (message) {
      try {
        await mutateAsyncDelete({
          templateMessageId: message.TemplateMessageId,
        });
        toast({
          title: 'Message is removed',
          status: 'success',
          variant: 'subtle',
          duration: 2000,
          isClosable: true,
        });
        onClose();
      } catch (err) {
        console.error(err);
        toast({
          title: 'Failed to deleted',
          status: 'error',
          variant: 'subtle',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size={'lg'}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody>
              <DialogContent
                title="Remove Message"
                description={'Please confirm you want to remove message:'}
                type="danger"
                icon={FiTrash}
              >
                <VStack>
                  <Box maxW={8}>
                    <Icon as={ChatAltIcon}></Icon>
                  </Box>
                  <Text>{message?.MessageTitle}</Text>
                </VStack>
              </DialogContent>
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup size="sm" spacing={4}>
                <Button ref={cancelRef} onClick={onClose}>
                  Keep
                </Button>
                <Button
                  colorScheme="red"
                  isLoading={isLoading}
                  loadingText="Removing"
                  onClick={handleOnConfirm}
                >
                  Remove
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default TemplateMessageDialogDelete;
