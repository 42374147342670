import { Box, Button, ButtonGroup, Stack } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import AlertCard from '../../../../components/shared/AlertCard';
import {
  initialRoutingRuleMoldForm,
  IRoutingRuleMold,
  RoutingRuleConnectorTypeEnum,
  RoutingRuleEventNameEnum,
  RoutingRuleFrequencyEnum,
} from '../../models/RoutingRule';
import { RoutingRuleFormGroup } from './RoutingRulesFormGroup';

interface IProps {
  routingRuleMold?: IRoutingRuleMold | null;
  actionText?: 'Add routing rule' | 'Update routing rule';
  onSubmit?: (data: IRoutingRuleMold) => Promise<void>;
  onCancel: () => void;
  isReadOnly?: boolean;
}

export const RoutingRuleForm = ({
  routingRuleMold,
  onSubmit,
  onCancel,
  actionText,
  isReadOnly,
}: IProps) => {
  const [workingFormValues, set_workingFormValues] =
    React.useState<IRoutingRuleMold>(
      routingRuleMold || initialRoutingRuleMoldForm
    );

  const RoutingRuleFormsSchema = Yup.object().shape({
    routingRuleName: Yup.string().required('Required'),
    eventName: Yup.string()
      .oneOf([
        RoutingRuleEventNameEnum.MESSAGE_ALL,
        RoutingRuleEventNameEnum.NEW_SUBMISSION,
        RoutingRuleEventNameEnum.NEW_AUDIT,
      ])
      .required('Required'),
    connectorType: Yup.string()
      .oneOf([
        RoutingRuleConnectorTypeEnum.EMAIL,
        RoutingRuleConnectorTypeEnum.SMARSH,
        RoutingRuleConnectorTypeEnum.INTRADYN,
        RoutingRuleConnectorTypeEnum.GLOBALRELAY,
        RoutingRuleConnectorTypeEnum.PROOFPOINT,
      ])
      .required('Required'),
    smarshChannelToken: Yup.string().when('connectorType', {
      is: val => val === RoutingRuleConnectorTypeEnum.SMARSH,
      then: Yup.string().trim().required('Required'),
    }),
    emailsTo: Yup.array()
      .of(Yup.string().email('Invalid Email'))
      .when('connectorType', {
        is: val => val === RoutingRuleConnectorTypeEnum.EMAIL,
        then: Yup.array()
          .min(1, 'At least 1 email is required')
          .of(Yup.string().email('Invalid Email').required('Required'))
          .required('Required'),
      }),
    emailsCc: Yup.array()
      .of(Yup.string().email('Invalid Email'))
      .when('connectorType', {
        is: val => val === RoutingRuleConnectorTypeEnum.EMAIL,
        then: Yup.array()
          .of(Yup.string().email('Invalid Email').required('Required'))
          .nullable(),
      }),

    frequency: Yup.string()
      .oneOf([
        RoutingRuleFrequencyEnum.STREAM,
        RoutingRuleFrequencyEnum.HOURLY,
        RoutingRuleFrequencyEnum.DAILY,
      ])
      .required('Required'),
    isEnabled: Yup.boolean().required('Required'),
  });

  const handleOnSubmit = async (
    values: IRoutingRuleMold,
    actions: FormikHelpers<IRoutingRuleMold>
  ) => {
    actions.setStatus();
    actions.setSubmitting(true);

    try {
      // Pass up logic
      if (onSubmit) {
        await onSubmit(values);
      }
    } catch (error) {
      // Error submitting form, set status for dispaly
      actions.setStatus('Failed to update RoutingRule. Please try again.');
    }

    actions.setSubmitting(false);
  };

  React.useEffect(() => {
    if (routingRuleMold) {
      // Moving naming keys to match form
      set_workingFormValues(routingRuleMold);
    }
  }, [routingRuleMold]);

  return (
    <Formik
      initialValues={workingFormValues}
      validationSchema={RoutingRuleFormsSchema}
      onSubmit={(values, actions) => {
        handleOnSubmit(values, actions);
      }}
      enableReinitialize={true}
    >
      {props => {
        return (
          <FormikForm>
            <Stack spacing="8">
              <RoutingRuleFormGroup form={props} />

              {!isReadOnly && (
                <Stack spacing={4}>
                  <ButtonGroup>
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={props.isSubmitting}
                      isDisabled={
                        !props.dirty ||
                        [
                          RoutingRuleConnectorTypeEnum.INTRADYN,
                          RoutingRuleConnectorTypeEnum.GLOBALRELAY,
                          RoutingRuleConnectorTypeEnum.PROOFPOINT,
                        ].indexOf(props.values.connectorType) >= 0
                      }
                    >
                      {actionText}
                    </Button>
                    <Button
                      variant="outline"
                      onClick={onCancel}
                      isDisabled={props.isSubmitting}
                    >
                      Close
                    </Button>
                  </ButtonGroup>
                  {/* Status of form (shows API errors) */}
                  {props.status && (
                    <Box>
                      <AlertCard
                        title="Failed to save"
                        type="error"
                        variant="fill"
                        //   action="Contact support"
                        //   actionTo="/app/support"
                        icon={ExclamationCircleIcon}
                      >
                        <span>
                          Not working as it should! If you keep seeing this,
                          please message support.
                        </span>
                      </AlertCard>
                    </Box>
                  )}
                </Stack>
              )}
            </Stack>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
