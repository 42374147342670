import {
  Box,
  HStack,
  Text,
  useRadio,
  UseRadioProps,
  WrapItem,
  WrapItemProps,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTheme } from '../../../../providers/ThemeProvider';

export interface IItemTextOption {
  label: string;
  value: string;
  item: React.ReactNode;
}

type ItemTextOptionProps = WrapItemProps & {
  label: string;
  item: React.ReactNode;
  radio: UseRadioProps;
};

export const ItemTextOption = (props: ItemTextOptionProps) => {
  const theme = useTheme();
  const { label, item, radio, ...rest } = props;
  const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(radio);

  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();
  const labelProps = getLabelProps();

  return (
    <WrapItem flexGrow={1} {...rest}>
      <Box flexGrow={1} as="label" {...labelProps}>
        <input {...inputProps} />
        <HStack
          {...checkboxProps}
          borderWidth="1px"
          px="6"
          spacing={4}
          py="4"
          rounded={'sm'}
          cursor="pointer"
          transition="all 0.2s"
          _focus={{ shadow: 'outline' }}
          _checked={{
            bg: theme.bg.accentSoft,
            borderColor: theme.bg.accentLoud,
          }}
          bg={theme.bg.base1}
        >
          {item}
          <Text fontWeight="bold" fontSize="sm" textAlign="center">
            {label}
          </Text>
        </HStack>
      </Box>
    </WrapItem>
  );
};
