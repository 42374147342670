import { Stack, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import useRoutingRulesTable from '../../hooks/useRoutingRulesTable';
import TemplateRoutingRuleDialogDelete from './modals/TemplateRoutingRuleDialogDelete';
import { TemplateRoutingRuleModalView } from './modals/TemplateRoutingRuleModalView';
import { RoutingRulesTableActions } from './RoutingRulesTableActions';
import { RoutingRulesTableContent } from './RoutingRulesTableContent';
import { RoutingRulesTablePagination } from './RoutingRulesTablePagination';

export const RoutingRulesTable = () => {
  const {
    isOpen: isOpenModal,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteDialog,
    onOpen: onOpenDeleteDialog,
    onClose: onCloseDeleteDialog,
  } = useDisclosure();

  const table = useRoutingRulesTable();

  const handleOnClose = () => {
    table.setViewRoutingRule(null);
    onCloseModal();
  };

  const handleOnCloseDelete = () => {
    table.setDeleteRoutingRule(null);
    onCloseDeleteDialog();
  };

  React.useEffect(() => {
    if (table.viewRoutingRule && !isOpenModal) {
      onOpenModal();
    }

    if (table.deleteRoutingRule && !isOpenDeleteDialog) {
      onOpenDeleteDialog();
    }
  }, [table, isOpenModal, onOpenModal, isOpenDeleteDialog, onOpenDeleteDialog]);

  return (
    <>
      <Stack as="section" minW={'5xl'} spacing={6}>
        <RoutingRulesTableActions table={table} />
        <RoutingRulesTableContent table={table} />
        <RoutingRulesTablePagination table={table} />
      </Stack>

      <TemplateRoutingRuleModalView
        isOpen={isOpenModal}
        onClose={handleOnClose}
        routingRule={table.viewRoutingRule}
      ></TemplateRoutingRuleModalView>

      <TemplateRoutingRuleDialogDelete
        routingrule={table.deleteRoutingRule}
        isOpen={isOpenDeleteDialog}
        onClose={handleOnCloseDelete}
      ></TemplateRoutingRuleDialogDelete>
    </>
  );
};
