import { API } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { ITemplateQuestionApprovalWorkingForm } from '../../../../routes/guardrails/models/Question';
import { QueryKeys } from '../../QueryKeys';

const makeApiRequest = (
  templateId: string,
  updateData: ITemplateQuestionApprovalWorkingForm
) => {
  const payload = {
    body: updateData,
  };

  return API.post(
    'users',
    `/api/v1/templatequestions/${templateId}/approved`,
    payload
  );
};

const useUpdateTemplateQuestionApproval = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ITemplateQuestionApprovalWorkingForm,
    any,
    {
      templateId: string;
      updateData: ITemplateQuestionApprovalWorkingForm;
    }
  >(
    ({ templateId, updateData }) => {
      return makeApiRequest(templateId, updateData);
    },
    {
      retry: 0,
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.templateQuestions], {
          exact: false,
        });
      },
    }
  );
};

export default useUpdateTemplateQuestionApproval;
