import React, { useMemo } from 'react';
import {
  Row,
  TableInstance,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { PAGE_SIZE_OPTIONS } from '../../../constants';
import { useAuth } from '../../../providers/AuthProvider';
import useListTemplateQuestions from '../../../services/api/templateQuestions/queries/useListTemplateQuestions';
import { IOrganizationLink } from '../../settings/partnerships/models/OrganizationLink';
import { QuestionsColumns } from '../components/questions/QuestionsColumns';
import { IQuestion } from '../models/Question';

interface ILinkQuestionAction {
  question: IQuestion;
  organizationLink: IOrganizationLink;
}

export interface IQuestionsTableResource extends TableInstance<IQuestion> {
  isErrorData: boolean;
  isLoadingData: boolean;
  editQuestion: IQuestion | null;
  setEditQuestion: React.Dispatch<React.SetStateAction<IQuestion | null>>;
  deleteQuestion: IQuestion | null;
  setDeleteQuestion: React.Dispatch<React.SetStateAction<IQuestion | null>>;
  duplicateQuestion: IQuestion | null;
  setDuplicateQuestion: React.Dispatch<React.SetStateAction<IQuestion | null>>;
  linkQuestion: ILinkQuestionAction | null;
  setLinkQuestion: React.Dispatch<
    React.SetStateAction<ILinkQuestionAction | null>
  >;
  viewHistoryQuestion: IQuestion | null;
  setViewHistoryQuestion: React.Dispatch<
    React.SetStateAction<IQuestion | null>
  >;
}

export const useQuestionsTable = (): IQuestionsTableResource => {
  const [editQuestion, set_editQuestion] = React.useState<IQuestion | null>(
    null
  );
  const [deleteQuestion, set_deleteQuestion] = React.useState<IQuestion | null>(
    null
  );
  const [duplicateQuestion, set_duplicateQuestion] =
    React.useState<IQuestion | null>(null);

  const [viewHistoryQuestion, set_viewHistoryQuestion] =
    React.useState<IQuestion | null>(null);

  const [linkQuestion, set_linkQuestion] =
    React.useState<ILinkQuestionAction | null>(null);

  const { userProfile } = useAuth();

  const filterTypes = React.useMemo(
    () => ({
      globalFilter: (
        rows: Row<IQuestion>[],
        columnIds: string[],
        filterValue: string
      ) => {
        return rows.filter(row => {
          const rowValue = `${row.original.QuestionTitle} ${row.original.QuestionDescription}`;

          return rowValue
            .toLowerCase()
            .includes(String(filterValue).toLowerCase());
        });
      },
      statusApprovedFilter: (
        rows: Row<IQuestion>[],
        columnIds: string[],
        filterValue: boolean
      ) => {
        return rows.filter(row => {
          let isRowApproved;

          if (
            row.original.Governance &&
            row.original.Governance[userProfile.OrganizationId]
          ) {
            isRowApproved =
              row.original.Governance[userProfile.OrganizationId].IsApproved;
          }

          return filterValue !== null ? isRowApproved === filterValue : true;
        });
      },
    }),
    [userProfile]
  );

  const {
    data: tableData,
    isError: isErrorData,
    isLoading: isLoadingData,
  } = useListTemplateQuestions();

  let data = useMemo(() => tableData ?? [], [tableData]);

  const handleOnEditQuestion = (question: IQuestion) => {
    set_editQuestion(question);
  };
  const handleOnDeleteQuestion = (question: IQuestion) => {
    set_deleteQuestion(question);
  };
  const handleOnDuplicateQuestion = (question: IQuestion) => {
    set_duplicateQuestion(question);
  };
  const handleOnViewHistoryQuestion = (question: IQuestion) => {
    set_viewHistoryQuestion(question);
  };

  const handleOnLinkQuestion = (
    question: IQuestion,
    organizationLink: IOrganizationLink
  ) => {
    set_linkQuestion({ question, organizationLink });
  };

  const columns = React.useMemo(
    () =>
      QuestionsColumns({
        onEditQuestion: (row: Row<IQuestion>) => {
          handleOnEditQuestion(row.original);
        },
        onDeleteQuestion: (row: Row<IQuestion>) => {
          handleOnDeleteQuestion(row.original);
        },
        onViewHistory: (row: Row<IQuestion>) => {
          handleOnViewHistoryQuestion(row.original);
        },
        onCopyQuestion: (row: Row<IQuestion>) => {
          handleOnDuplicateQuestion(row.original);
        },
        onLinkQuestion: (
          row: Row<IQuestion>,
          organizationLink: IOrganizationLink
        ) => {
          handleOnLinkQuestion(row.original, organizationLink);
        },
      }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:

      width: 40, // width is used for both the flex-basis and flex-grow
    }),
    []
  );

  const table = useTable<IQuestion>(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageSize: PAGE_SIZE_OPTIONS[0] },
      globalFilter: 'globalFilter',
      filterTypes: filterTypes,
    },
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination

    // useRowSelect,
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  return {
    ...table,
    isLoadingData,
    isErrorData,
    editQuestion,
    setEditQuestion: set_editQuestion,
    deleteQuestion,
    setDeleteQuestion: set_deleteQuestion,
    duplicateQuestion,
    setDuplicateQuestion: set_duplicateQuestion,
    linkQuestion,
    setLinkQuestion: set_linkQuestion,
    viewHistoryQuestion,
    setViewHistoryQuestion: set_viewHistoryQuestion,
  };
};

export default useQuestionsTable;
