import { Stack, StackProps, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTheme } from '../../providers/ThemeProvider';

interface DescriptionProps extends StackProps {
  title: string;
  children: React.ReactNode;
}

export const DescriptionLabel = (props: DescriptionProps) => {
  const { title, children, ...rest } = props;
  const theme = useTheme();

  return (
    <Stack as="dl" spacing="1" {...rest}>
      <Text as="dt" textStyle={'descriptionLabel'} color={theme.color.base3}>
        {title}
      </Text>
      <Text textStyle={'descriptionValue'}>{children}</Text>
    </Stack>
  );
};
