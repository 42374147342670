import { UseRadioGroupProps } from '@chakra-ui/react';
import React from 'react';
import { IItemTextOption, ItemTextOption } from '../optionTemplates';
import { FormRadioCardGroup, RadioCardGroup } from '../RadioCardGroup';

interface IFormRadioItemTextListProps extends UseRadioGroupProps {
  name: string;
  choices: IItemTextOption[];
}

export const FormRadioItemTextList = (props: IFormRadioItemTextListProps) => {
  const { choices, name, ...rest } = props;
  return (
    <FormRadioCardGroup wrapProps={{ direction: 'row' }} name={name} {...rest}>
      {getRadioProps => (
        <>
          {choices.map(option => (
            <ItemTextOption
              key={option.value}
              label={option.label}
              item={option.item}
              radio={getRadioProps({
                value: option.value,
                enterKeyHint: option,
              })}
            />
          ))}
        </>
      )}
    </FormRadioCardGroup>
  );
};

interface IRadioChoiceListProps extends UseRadioGroupProps {
  name: string;
  choices: IItemTextOption[];
}

export const RadioItemTextList = (props: IRadioChoiceListProps) => {
  const { choices, name, ...rest } = props;
  return (
    <RadioCardGroup wrapProps={{ direction: 'row' }} name={name} {...rest}>
      {getRadioProps => (
        <>
          {choices.map(option => (
            <ItemTextOption
              key={option.value}
              label={option.label}
              item={option.item}
              radio={getRadioProps({
                value: option.value,
                enterKeyHint: option,
              })}
            />
          ))}
        </>
      )}
    </RadioCardGroup>
  );
};
