import { HStack, Stack } from '@chakra-ui/react';
import {
  AdjustmentsIcon,
  ClipboardListIcon,
  ClockIcon,
  UsersIcon,
  ViewGridIcon,
} from '@heroicons/react/outline';
import * as React from 'react';
import { useTheme } from '../providers/ThemeProvider';
import { NavItem } from './NavItem';

const MobileNavMenu = (props: { isOpen?: boolean; toggle: () => void }) => {
  const theme = useTheme();
  const { isOpen, toggle } = props;

  return (
    <Stack
      hidden={!isOpen}
      bg={theme.bg.base}
      as="nav"
      position="fixed"
      height="calc(100vh - 4rem)"
      top="16"
      py={8}
      insetX="0"
      zIndex={10}
      w="full"
    >
      <Stack>
        <NavItem.Mobile to="/app/dashboard" label="Dashboard" toggle={toggle} />
        <NavItem.Mobile
          to="/app/organization"
          label="Organization"
          toggle={toggle}
        />
        <NavItem.Mobile
          to="/app/guardrails"
          label="Guardrails"
          toggle={toggle}
        />
        <NavItem.Mobile to="/app/settings" label="Settings" toggle={toggle} />
      </Stack>
    </Stack>
  );
};

const DesktopNavMenu = () => {
  const theme = useTheme();

  return (
    <HStack spacing="3" flex="1" display={{ base: 'none', md: 'flex' }}>
      <NavItem.Desktop
        to="/app/dashboard"
        icon={ViewGridIcon}
        label="Dashboard"
      />
      <NavItem.Desktop
        to="/app/organization/users"
        icon={UsersIcon}
        label="Users"
      />

      <NavItem.Desktop
        to="/app/guardrails"
        icon={ClipboardListIcon}
        label="Guardrails"
      />
      <NavItem.Desktop
        to="/app/settings"
        icon={AdjustmentsIcon}
        label="Settings"
      />
      <NavItem.Desktop
        to="/app/organization/events"
        icon={ClockIcon}
        label="Events"
      />
    </HStack>
  );
};

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu,
};
