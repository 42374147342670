import {
  Icon as ChakraIcon,
  InputRightElement as ChakraInputRightElement,
} from "@chakra-ui/react";
import React from "react";
import { FiCheck, FiFlag } from "react-icons/fi";

export const ChakraInputStatusIcon = ({
  error,
  touched,
  isRequired = false,
}) => {
  // If no error and its touched then show pass icon

  if (!error && touched) {
    return (
      <ChakraInputRightElement
        children={
          <ChakraIcon
            as={FiCheck}
            color={isRequired ? "green.500" : "gray.500"}
          ></ChakraIcon>
        }
      />
    );
  }
  // If error and its touched then show error icon
  else if (error && touched) {
    return (
      <ChakraInputRightElement
        children={<ChakraIcon as={FiFlag} color="red.500"></ChakraIcon>}
      />
    );
  }
  // If its not touched, show no icon
  else {
    return <></>;
  }
};
